import React, { useEffect, useState } from "react";

import {
    Breadcrumbs,
    Icon,
    LABEL_POSITION_TOP,
    ProgressBar,
    TYPE_GLYPH,
} from "@mds/mds-reactjs-library";
import classNames from "classnames";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import SlateEditor from "../../../../components/Editor/Editor";
import Header from "../../../../components/Header";
import ShouldRender from "../../../../components/ShouldRender";
import { removeExtension } from "../../../../utils/fileUtils";
import { ProjectState } from "../../../../utils/types";
import useApi from "../../../../utils/useApi";
import DocumentViewer from "./DocumentViewer2";
import styles from "./ExtractedFIle.module.scss";

interface PDFRendererProps {
    type: string;
    pdf: any;
}
export const PDFRenderer: React.FC<PDFRendererProps> = ({ type, pdf }) => (
    <div className={classNames(styles.inputFile)}>
        <ShouldRender condition={!!pdf}>
            <DocumentViewer src={pdf} type={type} />
        </ShouldRender>
    </div>
);

const getOutputDocumentName = (documentName: string) =>
    `${removeExtension(documentName)}.pdf`;

const ExtractedFile = () => {
    const params = useParams();
    const { state } = useLocation();
    const { id } = params;
    const { projectName, inputFileName } = state as ProjectState;
    const [inputProgress, setInputProgress] = useState(0);
    const [outputProgress, setOutputProgress] = useState(0);

    const navigate = useNavigate();

    const onInputProgressChange = (val: number) => {
        setInputProgress(val);
    };

    const onOutputProgressChange = (val: number) => {
        setOutputProgress(val);
    };

    const [file, fetchFile] = useApi({
        url: `/api/project/${id}/files/download/${inputFileName}`,
        method: "get",
        data: null,
        responseType: "blob",
        onProgressChange: onInputProgressChange,
    });

    const [outputFile, fetchOutputFile] = useApi({
        url: `/api/project/${id}/files/download/type/rendered_files/${getOutputDocumentName(
            inputFileName,
        )}`,
        method: "get",
        data: null,
        responseType: "blob",
        onProgressChange: onOutputProgressChange,
    });

    useEffect(() => {
        fetchOutputFile();
        fetchFile();
    }, []);

    const [pdf, setPdf] = useState<any>();
    const [type, setType] = useState<string>("");
    const [outputPDF, setOutputPDF] = useState<any>();

    useEffect(() => {
        if (outputFile.data) {
            (async () => {
                const outputFileData = await outputFile?.data?.arrayBuffer();
                setOutputPDF(outputFileData);
            })();
        }
    }, [outputFile]);

    useEffect(() => {
        if (file.data) {
            (async () => {
                setType(file.data.type);
                if (file.data.type === "application/pdf") {
                    const fileData = await file.data.arrayBuffer();
                    setPdf(fileData);
                } else {
                    const imgData = await URL.createObjectURL(file.data);
                    setPdf(imgData);
                }
            })();
        }
    }, [file]);

    return (
        <div className={styles.container}>
            <Header
                heading={
                    <Breadcrumbs>
                        <Link to="/projects">Projects</Link>
                        <Link
                            to={`/projects/${id}/documents/processed`}
                            state={state}
                        >
                            {projectName}
                        </Link>
                        <Link to=".">{inputFileName}</Link>
                    </Breadcrumbs>
                }
            />

            <div className={styles.pdfProgress}>
                <ShouldRender condition={inputProgress < 100}>
                    <ProgressBar
                        progress={inputProgress}
                        label={{
                            text: "Loading Input File",
                            position: LABEL_POSITION_TOP,
                        }}
                    />
                </ShouldRender>
                <ShouldRender condition={outputProgress < 100}>
                    <ProgressBar
                        progress={outputProgress}
                        label={{
                            text: "Loading Output File",
                            position: LABEL_POSITION_TOP,
                        }}
                    />
                </ShouldRender>
            </div>

            <div className={styles.output}>
                <div
                    className={styles.backToExtractedFiles}
                    onClick={() => navigate(-1)}
                >
                    <Icon
                        fill="#2251FF"
                        size={12}
                        type={TYPE_GLYPH}
                        name="arrow-left"
                    />
                    <span>Back to Extracted Files</span>
                </div>
                <SlateEditor outputFile={outputPDF} type={type} pdf={pdf} />
            </div>
        </div>
    );
};
export default ExtractedFile;
