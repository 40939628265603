import React from "react";

import classNames from "classnames";

import ShouldRender from "../ShouldRender";
import styles from "./Header.module.scss";

interface Props {
    heading: string | JSX.Element;
}

const Header: React.FC<Props> = ({ heading, children }) => (
    <section className={styles.container}>
        <div className={classNames(styles.headerText, "breadcrumb")}>
            {heading}
        </div>
        <ShouldRender condition={!!children}>
            {children as JSX.Element}
        </ShouldRender>
    </section>
);

export default Header;
