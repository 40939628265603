import React from "react";

import { Button } from "@mds/mds-reactjs-library";

import { ButtonConfig } from "../../utils/types";
import styles from "./ModalWrapper.module.scss";

interface Props {
    headerText: string;
    actionItems?: ButtonConfig[];
}

const ModalWrapper: React.FC<Props> = ({
    headerText,
    children,
    actionItems,
}) => (
    <div className={styles.container}>
        <h3 className={styles.heading}>{headerText}</h3>
        <section className={styles.body}>{children}</section>
        {actionItems && (
            <section className={styles.footer}>
                {actionItems.map(({ label, onClick, disabled, appearance }) => (
                    <div key={label} className={styles.action}>
                        <Button
                            appearance={appearance}
                            disabled={disabled}
                            onClick={onClick}
                        >
                            {label}
                        </Button>
                    </div>
                ))}
            </section>
        )}
    </div>
);

ModalWrapper.defaultProps = {
    actionItems: [],
};
export default ModalWrapper;
