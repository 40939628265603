import React, { useContext, useEffect } from "react";

import Glyph12Warning from "@mds/mds-icons/icons/svg/glyph-12-warning.svg";
import {
    ERROR_ALERT_CONTEXTUAL,
    hideModal,
    Icon,
    showModal,
    SUCCESS_ALERT_CONTEXTUAL,
    TERTIARY_BUTTON,
    TYPE_OUTLINE,
} from "@mds/mds-reactjs-library";
import axios from "axios";

import { ApplicationContext } from "../../App";
import Header from "../../components/Header";
import ModalWrapper from "../../components/ModalWrapper";
import ShouldRender from "../../components/ShouldRender";
import {
    HIDE_LOADER,
    SHOW_LOADER,
    SHOW_NOTIFICATION,
} from "../../data/appConstants";
import { ButtonConfig, RowEntity, UserType } from "../../utils/types";
import useApi from "../../utils/useApi";
import ActionItems from "../Project/ActionItems";
import CreateForm from "./CreateProject";
import ProjectList from "./ProjectList";
import styles from "./Projects.module.scss";

const DELETE_ID = "delete-project";

const Projects = () => {
    const [response, callToApi] = useApi({
        url: "/api/project",
        method: "get",
    });

    const { dispatch } = useContext(ApplicationContext);

    useEffect(() => {
        callToApi();
    }, []);

    const showDocModal = (rowData?: RowEntity, mode?: string) => {
        if (rowData && mode !== "delete") {
            const id = "create-project";
            const transformedRowData = {
                ...rowData,
                users: rowData?.users
                    ?.filter((user) => user.type === UserType.User)
                    .map(({ assignedTo }) => assignedTo),
                admins: rowData?.users
                    ?.filter((user) => user.type === UserType.Admin)
                    .map(({ assignedTo }) => assignedTo),
            };

            showModal({
                id,
                children: (
                    <CreateForm
                        onClose={(fetch) => {
                            if (fetch) {
                                callToApi();
                            }
                            hideModal(id);
                        }}
                        mode={mode || ""}
                        data={transformedRowData}
                    />
                ),
            });
        }
        if (mode === "delete") {
            const btnConfig: ButtonConfig[] = [
                {
                    label: "Cancel",
                    appearance: TERTIARY_BUTTON,
                    onClick: () => {
                        hideModal(DELETE_ID);
                    },
                },
                {
                    label: "Confirm",
                    onClick: async () => {
                        dispatch({ type: SHOW_LOADER });

                        const token =
                            sessionStorage.getItem("_mid-access-token");
                        const headers = {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        };

                        axios({
                            method: "delete",
                            url: `/api/project/${rowData?.id}`,
                            headers,
                        })
                            .then(() => {
                                dispatch({
                                    type: SHOW_NOTIFICATION,
                                    payload: [
                                        {
                                            message: `Project '${rowData?.name}' has been deleted successfully.`,
                                            type: SUCCESS_ALERT_CONTEXTUAL,
                                        },
                                    ],
                                });
                            })
                            .catch(() => {
                                dispatch({
                                    type: SHOW_NOTIFICATION,
                                    payload: [
                                        {
                                            message: `Project '${rowData?.name}' could not be deleted, Please Try again !.`,
                                            type: ERROR_ALERT_CONTEXTUAL,
                                        },
                                    ],
                                });
                            })
                            .finally(() => {
                                hideModal(DELETE_ID);
                                dispatch({ type: HIDE_LOADER });
                                callToApi();
                            });
                    },
                },
            ];

            showModal({
                id: DELETE_ID,
                children: (
                    <ModalWrapper
                        headerText={`Delete Project - ${rowData?.name}`}
                        actionItems={btnConfig}
                    >
                        <div className={styles.deleteProject}>
                            <span className={styles.icon}>
                                <Icon
                                    size={8}
                                    type={TYPE_OUTLINE}
                                    src={Glyph12Warning}
                                />
                            </span>

                            <span className={styles.message}>
                                Project data will be deleted completely and
                                cannot be retrieved back.
                            </span>
                        </div>
                    </ModalWrapper>
                ),
            });
        }
    };

    const btnConfig = [{ label: "Create Project", onClick: showDocModal }];
    const { data, err } = response;

    return (
        <div className={styles.container}>
            <Header heading="Projects">
                <ActionItems config={btnConfig} />
            </Header>
            <ShouldRender
                condition={data !== null || err !== null}
                hasError={!!err}
                errorMessage="Failed to load Project details , Please try again"
            >
                <ProjectList
                    btnConfig={btnConfig}
                    data={response.data || []}
                    showDocModal={showDocModal}
                />
            </ShouldRender>
        </div>
    );
};
export default Projects;
