import React from "react";

import { Icon, TYPE_OUTLINE } from "@mds/mds-reactjs-library";

import styles from "./Error.module.scss";

interface Props {
    fieldErr: string;
}

const Error: React.FC<Props> = ({ fieldErr }) => (
    <div>
        <p className={styles.error}>
            <Icon name="c-remove" type={TYPE_OUTLINE} size={16} />
            <span className={styles.errorIcon}>{fieldErr}</span>
        </p>
    </div>
);

export default Error;
