import React from "react";

import styles from "./KeyValue.module.scss";

interface Props {
    label: string;
    value: string | number;
}

const KeyValue = ({ label, value }: Props) => (
    <div className={styles.container}>
        <span className={styles.key}>{label}</span>
        <span>{`: ${value}`}</span>
    </div>
);

export default KeyValue;
