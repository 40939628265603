import React, { useContext, useState } from "react";

import {
    Button,
    SECONDARY_BUTTON,
    PRIMARY_BUTTON,
    TERTIARY_BUTTON,
    ButtonAppearanceType,
    SUCCESS_ALERT_CONTEXTUAL,
    ERROR_ALERT_CONTEXTUAL,
} from "@mds/mds-reactjs-library";
import axios from "axios";
import classNames from "classnames";
import { FileRejection, useDropzone } from "react-dropzone";

import { ApplicationContext } from "../../../App";
import {
    ALLOWED_EXTRACTION_FILE_TYPE,
    MAX_EXTRACT_MODEL_FILE_LIMIT,
} from "../../../appConfig";
import ShouldRender from "../../../components/ShouldRender";
import SelectedDocList from "../DocumentUploadModal/SelectedDocList";
import UploadProgress from "../DocumentUploadModal/UploadProgress";
import { getErrorCodes, getValidationMessage, labelWithCount } from "../helper";
import "./ExtractionModelUploadModal.scss";

export interface FilesEntity extends FileRejection {
    id: number;
}
interface Props {
    projectName: string;
    projectId: string;
    onClose: (isSuccess?: boolean) => void;
}

const transformData = (files: File[]) =>
    files.map((item) => ({ file: item, errors: [] }));

export default function ExtractionModelUploadModal({
    projectName,
    projectId,
    onClose,
}: Props) {
    const [filesData, setFilesData] = useState<FilesEntity[]>([]);
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [currentFile, setCurrentFile] = useState("");
    const [isChecked, setIsChecked] = useState(false);

    const { dispatch } = useContext(ApplicationContext);

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: ALLOWED_EXTRACTION_FILE_TYPE,
        maxFiles: MAX_EXTRACT_MODEL_FILE_LIMIT,
        noClick: true,
        onDrop: (accepted: File[], rejected: FileRejection[]) => {
            setFilesData((prev) => {
                const transformedData = transformData(accepted);
                const final = [...prev, ...transformedData, ...rejected].map(
                    (item, index) => ({ ...item, id: index + 1 }),
                );
                return final;
            });
        },
    });

    const { validData, invalidData } = filesData.reduce(
        (acc, { file, errors, id }) => {
            const prev = { ...acc };
            if (
                prev.index < MAX_EXTRACT_MODEL_FILE_LIMIT &&
                (!errors.length ||
                    (errors.length === 1 &&
                        errors[0].code === "too-many-files"))
            ) {
                prev.validData.push({ file, errors, id });
                prev.index += 1;
            } else {
                prev.invalidData.push({ file, errors, id });
            }
            return prev;
        },
        { validData: [], invalidData: [], index: 0 } as {
            validData: FilesEntity[];
            invalidData: FilesEntity[];
            index: number;
        },
    );

    const removeClicked = (id: number) => {
        const filteredData = filesData.filter(({ id: uid }) => id !== uid);
        setFilesData(filteredData);
    };
    const handleCheck = () => {
        setIsChecked(!isChecked);
    };
    const hasData = !!(validData.length || invalidData.length);

    const dispatchSuccessNotification = (count: number) => {
        dispatch({
            type: "SHOW_NOTIFICATION",
            payload: [
                {
                    message:
                        "Entity Extraction Model as been updated successfully.",
                    type: SUCCESS_ALERT_CONTEXTUAL,
                },
            ],
        });
    };

    const dispatchFailureNotification = () => {
        dispatch({
            type: "SHOW_NOTIFICATION",
            payload: [
                {
                    message:
                        "1 or more documents failed to upload. Please try again.",
                    type: ERROR_ALERT_CONTEXTUAL,
                },
            ],
        });
    };

    const onUploadProgress = (progressEvent: ProgressEvent) => {
        setProgress(
            Math.round((100 * progressEvent.loaded) / progressEvent.total),
        );
    };

    const uploadFiles = async () => {
        setIsLoading(true);
        const apiState = {
            success: 0,
            failed: 0,
            shouldCall: true,
        };
        for (let i = 0; i < validData.length; i += 1) {
            const { file } = validData[i];
            const formData = new FormData();
            const pos = i;
            formData.append("model", file);
            setCurrent(i + 1);
            setCurrentFile(file.name);
            if (apiState.shouldCall) {
                const token = sessionStorage.getItem("_mid-access-token");
                // eslint-disable-next-line no-await-in-loop
                await axios({
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                    },
                    url: `/api/project/${projectId}/models/upload`,
                    method: "post",
                    data: formData,
                    onUploadProgress,
                })
                    .then(() => {
                        apiState.success += 1;
                    })
                    .catch(() => {
                        apiState.shouldCall = false;
                        apiState.failed = validData.length - pos;
                    });
            } else {
                break;
            }
        }
        setIsLoading(false);
        if (apiState.success) {
            dispatchSuccessNotification(apiState.success);
        }
        if (apiState.failed) {
            dispatchFailureNotification();
        }
        onClose(true);
    };

    const isUploadDisabled =
        validData.length === 0 ||
        (invalidData.length > 0 && validData.length > 0 && !isChecked);

    const btnConfig: {
        label: string;
        appearance: ButtonAppearanceType;
        disabled: boolean;
        onClick: React.MouseEventHandler;
    }[] = [
        {
            label: "Cancel",
            appearance: TERTIARY_BUTTON,
            disabled: false,
            onClick: () => {
                onClose();
            },
        },
        {
            label: "Upload",
            appearance: PRIMARY_BUTTON,
            disabled: isUploadDisabled,
            onClick: uploadFiles,
        },
    ];

    const fileErrorCodes = getErrorCodes(invalidData);

    return (
        <>
            <ShouldRender condition={isLoading}>
                <UploadProgress
                    current={current}
                    total={validData.length}
                    currentFile={currentFile}
                    progress={progress}
                    modal="entity-upload"
                />
            </ShouldRender>
            <div className="doc-upload-modal">
                <h3 className="modal-heading">
                    {labelWithCount(
                        "Upload Entity Extraction Model",
                        filesData.length,
                    )}
                </h3>
                <section className="body">
                    <div
                        {...getRootProps({
                            className: classNames("drag-drop-container", {
                                "no-files": !hasData,
                            }),
                        })}
                    >
                        <input {...getInputProps()} />
                        {hasData ? (
                            <SelectedDocList
                                validFiles={validData}
                                invalidFiles={invalidData}
                                onRemove={(id) => removeClicked(id)}
                            />
                        ) : (
                            <p>Drag and drop zip file</p>
                        )}
                    </div>

                    <div>
                        <Button
                            appearance={SECONDARY_BUTTON}
                            className="btn-upload"
                            onClick={open}
                        >
                            Select files
                        </Button>
                    </div>

                    <div className="validation-info">
                        <p className="message">
                            {`Max no. of files per upload
                            : ${MAX_EXTRACT_MODEL_FILE_LIMIT} | File types allowed : zip`}
                        </p>
                        <p className="message">
                            The new Uploaded model will overwrite the existing
                            model.
                        </p>
                    </div>

                    <ShouldRender condition={!!invalidData.length}>
                        <p className="error-message">
                            {getValidationMessage(
                                fileErrorCodes,
                                "model-upload",
                            )}
                        </p>
                    </ShouldRender>
                </section>
                <section className="footer">
                    {btnConfig.map(
                        ({ label, onClick, disabled, appearance }) => (
                            <div key={label} className="btn-wrapper">
                                <Button
                                    appearance={appearance}
                                    disabled={disabled}
                                    onClick={onClick}
                                >
                                    {label}
                                </Button>
                            </div>
                        ),
                    )}
                </section>

                <section />
            </div>
        </>
    );
}
