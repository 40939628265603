import React, { useContext, useEffect } from "react";

import {
    AlertFactory,
    getAlertUniqueId,
    showAlert,
} from "@mds/mds-reactjs-library";

import { ApplicationContext } from "../../App";
import { NotificationEntity } from "../../data/appReducer";

const Notification = () => {
    const { state } = useContext(ApplicationContext);

    const { notifications } = state;

    useEffect(() => {
        if (notifications.length) {
            notifications.forEach(
                ({ message, type, closeBtn }: NotificationEntity) => {
                    const id = getAlertUniqueId();
                    const config = {
                        id,
                        factoryId: "notification",
                        type,
                        lifetime: closeBtn ? -1 : 5000,
                        children: <div> {message} </div>,
                        showCloseIcon: !!closeBtn,
                    };

                    showAlert(config);
                },
            );
        }
    }, [notifications]);

    return (
        <div className="notification">
            <AlertFactory id="notification" />
        </div>
    );
};

export default Notification;
