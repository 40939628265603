import React from "react";

import { Button, Grid, TERTIARY_BUTTON } from "@mds/mds-reactjs-library";

import styles from "./CautionModal.module.scss";

interface Props {
    projectSummary: {
        langMismatchDocCount: number;
        lowConfidenceDocCount: number;
        docsWithErrors: number;
    };
    onClose: (res: boolean) => void;
}

function CautionModal({ projectSummary, onClose }: Props) {
    const { langMismatchDocCount, lowConfidenceDocCount, docsWithErrors } =
        projectSummary;
    let errors = "";
    errors += lowConfidenceDocCount ? "Low confidence percentage, " : "";
    errors += langMismatchDocCount ? "Language mismatch, " : "";
    errors = errors.replace(/,\s*$/, ""); // remove last comma character
    return (
        <div className={styles.container}>
            <Grid container>
                <Grid item span={12}>
                    <h3 className={styles.heading}>Caution</h3>
                </Grid>
                <Grid item span={12} />
                <Grid item span={12} className={styles.text}>
                    <span>
                        {docsWithErrors} file(s) contain errors and may not be
                        analysed.
                    </span>
                </Grid>
                <Grid item span={12} className={styles.errorText}>
                    <span>Errors: {errors}</span>
                </Grid>
                <Grid item span={12} className={styles.confirmMsg}>
                    <strong>Do you still want to proceed with analysis?</strong>
                </Grid>
                <div className={styles.actionsContainer}>
                    <Button
                        onClick={() => onClose(false)}
                        appearance={TERTIARY_BUTTON}
                    >
                        Cancel
                    </Button>

                    <Button
                        onClick={() => onClose(true)}
                        className={styles.proceedBtn}
                    >
                        Proceed
                    </Button>
                </div>
            </Grid>
        </div>
    );
}

export default CautionModal;
