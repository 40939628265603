import React from "react";

import {
    ALIGN_RIGHT,
    Grid,
    Pagination,
    SIZE_SMALL,
    Table,
    Tooltip,
} from "@mds/mds-reactjs-library";
import { useLocation, useNavigate } from "react-router-dom";

import ShouldRender from "../../../components/ShouldRender";
import { DocumentType, ProjectState } from "../../../utils/types";
import utils from "../../../utils/utils";
import styles from "./KPI.module.scss";

interface KPIs {
    documentId: number;
    projectId: number;
    kpi: {
        kpis: [];
    };
    documentName: string;
}

interface KPIProps {
    projectName: string;
    projectId: number;
    results: {
        data: KPIs[];
        metadata: {
            totalRecords: number;
            totalPages: number;
        };
    };
    page: number;
    limit: number;
    onChange: (page: number) => void;
}

const TextRenderWithTooltip = ({
    cellData,
    value,
}: {
    cellData: string;
    value: string;
}) => {
    const text = cellData || value;
    return (
        <div className={styles.textContainer}>
            <Tooltip content={text} dark={undefined}>
                <div className={styles.content}>{text}</div>
            </Tooltip>
        </div>
    );
};

const KpiBadge = ({
    data,
    showColors,
}: {
    data: string;
    showColors: boolean;
}) => {
    const badgeStyles = {
        backgroundColor: showColors ? utils.getColorFromString(data) : "white",
        padding: "3px 8px 3px 8px",
        margin: "5px",
        borderRadius: "8px",
        opacity: 0.8,
        border: showColors ? "" : "1px solid black",
        fontSize: showColors ? "" : "12px",
    };

    return <span style={badgeStyles}>{data}</span>;
};

export const groupData = (data: string[][]) =>
    data?.reduce((prev: any, item: any) => {
        /* eslint-disable no-param-reassign */
        if (item in prev) prev[item] += 1;
        else prev[item] = 1;
        return prev;
    }, {});

export const KpiRenderer = ({
    value,
    showAll,
    showColors,
}: {
    value: string[][];
    showAll?: boolean;
    showColors: boolean;
}) => {
    const itemsToShow = showAll ? Infinity : 7;
    const groupedData = groupData(value);
    const keys = Object.keys(groupedData || {});
    keys.sort();
    const keysToDisplay = keys.slice(0, itemsToShow);
    const hiddenKeys = keys.slice(itemsToShow);
    return (
        <div>
            {keysToDisplay.map((val: string) => (
                <KpiBadge
                    key={`key-${Math.random()}`}
                    data={val}
                    showColors={showColors === undefined ? true : showColors}
                />
            ))}

            <ShouldRender condition={keys.length > itemsToShow}>
                <Tooltip dark={undefined} content={hiddenKeys.join(", ")}>
                    <span>+{keys.length - itemsToShow} more</span>
                </Tooltip>
            </ShouldRender>
        </div>
    );
};

KpiRenderer.defaultProps = {
    showAll: 7,
};

const CountRenderer = ({ value }: { value: string[][] }) => {
    const groupedData = groupData(value);
    const count = Object.keys(groupedData || {}).length;
    return <span className={styles.kpiCount}>{count}</span>;
};

const KPI = ({
    projectName,
    projectId,
    results,
    page,
    limit,
    onChange,
}: KPIProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { totalExtractedFiles, users, documentType } =
        location.state as ProjectState;

    const rows =
        results.data?.map((item: KPIs, index: number) => {
            const { kpi, documentId, documentName } = item;
            const { kpis } = kpi || [];
            const kpiList = kpis?.map(
                (kpiUnit: { name: string }) => kpiUnit.name,
            );
            return {
                serialNo: (page - 1) * limit + index + 1,
                name: documentName,
                kpi: kpiList,
                count: kpiList?.length || 0,
                documentId,
            };
        }) || [];

    const columns = [
        {
            dataKey: "serialNo",
            label: "S. no",
            flexGrow: true,
            width: 85,
        },
        {
            dataKey: "name",
            label: "Document name",
            flexGrow: true,
            width: 600,
            CellRenderer: TextRenderWithTooltip,
        },
        {
            dataKey: "kpi",
            textWrapping: true,
            label: "KPI's",
            width: 850,
            flexGrow: true,
            CellRenderer: KpiRenderer,
        },
        {
            dataKey: "kpi",
            textWrapping: true,
            label: "No.of KPI's",
            width: 220,
            flexGrow: true,
            align: ALIGN_RIGHT,
            CellRenderer: CountRenderer,
        },
    ];

    return (
        <Grid container className={styles.container}>
            <Grid item span={12}>
                <ShouldRender
                    condition={results?.metadata?.totalRecords > 0}
                    fallback={
                        <Table
                            dark={false}
                            striped
                            rowHeight={20}
                            rows={[]}
                            columns={columns}
                        />
                    }
                >
                    <Table
                        dark={false}
                        striped
                        rowHeight={40}
                        rows={rows}
                        columns={columns}
                        height="auto"
                        onRowClick={({ rowData }: any) => {
                            if (
                                documentType?.toLowerCase() ===
                                DocumentType.INVOICE
                            ) {
                                navigate(`kpi/${rowData.documentId}`, {
                                    state: {
                                        ...(location.state as ProjectState),
                                        projectId,
                                        projectName,
                                        totalExtractedFiles,
                                        documentName: rowData.name,
                                    },
                                });
                            } else {
                                navigate(`${rowData.documentId}`, {
                                    state: {
                                        ...(location.state as ProjectState),
                                        projectId,
                                        projectName,
                                        totalExtractedFiles,
                                        documentName: rowData.name,
                                    },
                                });
                            }
                        }}
                    />
                </ShouldRender>
            </Grid>
            <Grid item span={12}>
                <ShouldRender condition={results?.metadata?.totalRecords === 0}>
                    <div className={styles.noResults}>No KPI found.</div>
                </ShouldRender>
            </Grid>
            <Grid item span={12}>
                <ShouldRender condition={results?.metadata?.totalPages > 1}>
                    <div className={styles.pagination}>
                        <Pagination
                            size={SIZE_SMALL}
                            selected={page}
                            total={results?.metadata?.totalPages}
                            onChange={onChange}
                        />
                    </div>
                </ShouldRender>
            </Grid>
        </Grid>
    );
};

export default KPI;
