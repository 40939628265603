const getColorFromString = function (str: string) {
    /* eslint-disable no-bitwise */
    let hash = 0;
    for (let i = 0; i < str.length; i += 1) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash &= hash;
    }
    const rgb = [0, 0, 0];
    for (let i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 5)) & 255;
        rgb[i] = value;
    }
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.6)`;
};

export default { getColorFromString };
