import React, { useCallback, useContext, useEffect, useState } from "react";

import {
    hideModal,
    ModalFactory,
    showModal,
    Tabs,
    Tab,
    Breadcrumbs,
    ERROR_ALERT_GLOBAL,
    TERTIARY_BUTTON,
    SUCCESS_ALERT_CONTEXTUAL,
    ERROR_ALERT_CONTEXTUAL,
    SECONDARY_BUTTON,
    Tooltip,
    DropdownButton,
    DropdownItem,
} from "@mds/mds-reactjs-library";
import axios from "axios";
import debounce from "lodash.debounce";
import {
    Link,
    Route,
    Routes,
    useLocation,
    useNavigate,
    useParams,
} from "react-router-dom";

import { ApplicationContext } from "../../App";
import Header from "../../components/Header";
import ModalWrapper from "../../components/ModalWrapper";
import ShouldRender from "../../components/ShouldRender";
import {
    HIDE_LOADER,
    SHOW_LOADER,
    SHOW_NOTIFICATION,
} from "../../data/appConstants";
import authUtils from "../../utils/authUtils";
import callDownloadAPI, { downloadAttachment } from "../../utils/fileUtils";
import httpUtils from "../../utils/httpUtils";
import {
    ButtonConfig,
    DocumentStatus,
    ProjectState,
    SortDirection,
    SortEntity,
    Users,
} from "../../utils/types";
import useApi from "../../utils/useApi";
import CautionModal from "../Analysis/CautionModal";
import ActionItems from "./ActionItems";
import DeleteDocModal from "./DeleteDocModal";
import DocumentList from "./DocumentList";
import DocumentUploadModal from "./DocumentUploadModal";
import ExtractedDocumentList from "./ExtractedDocumentList";
import ExtractionModelUploadModal from "./ExtractionModelUploadModal";
import ExtractionSummary from "./ExtractionSummary";
import { labelWithCount, transformParams } from "./helper";
import styles from "./Project.module.scss";

const initialSortState: SortEntity = {
    sort: "",
    sortDir: "",
};

export interface FilterEntity {
    [key: string]: number[];
}

const { ASC, DESC } = SortDirection;

export default function Project() {
    const initialFilterData: FilterEntity = {
        table: [],
        errors: [],
    };

    const initialDocFilterOpt: FilterEntity = {
        status: [],
    };

    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { id: projectId } = params;
    const { state } = location;
    const { projectName, users } = state as ProjectState;

    const [pageDetails, setPageDetails] = useState({
        selected: 1,
        limit: 12,
    });

    const [sortData, setSortData] = useState<SortEntity>(initialSortState);
    const [filterData, setFilterData] = useState(initialFilterData);
    const [searchQuery, setSearchQuery] = useState("");

    const [docSortOpt, setDocSortOpt] = useState<SortEntity>(initialSortState);
    const [docFilterOpt, setDocFilterOpt] =
        useState<FilterEntity>(initialDocFilterOpt);
    const [docSearchQuery, setDocSearchQuery] = useState("");
    const { dispatch } = useContext(ApplicationContext);
    const [extractedPageOptions, setExtractedPageOptions] = useState({
        current: 1,
        limit: 12,
    });

    const [selectedDocIds, setSelectedDocIds] = useState<number[]>([]);

    const [selectedTab, setSelectedTab] = useState("");

    const { selected, limit } = pageDetails;

    const [summaryResponse, getSummary] = useApi({
        url: `/api/project/${projectId}/documents/summary`,
        method: "get",
    });

    const { data: summaryResponseData } = summaryResponse;

    const getQueryParams = () =>
        transformParams({
            ...sortData,
            ...filterData,
            name: searchQuery.length > 2 ? searchQuery : "",
        });

    const getDocListQueryParams = () =>
        transformParams({
            ...docFilterOpt,
            ...docSortOpt,
            name: docSearchQuery.length > 2 ? docSearchQuery : "",
        });

    const [docListResponse, callDocListApi] = useApi({
        url: `/api/project/${projectId}/documents/page/${selected}/limit/${limit}${getDocListQueryParams()}`,
        method: "get",
    });
    const [extractedDocResponse, callExtractedDocApi] = useApi({
        url: `/api/project/${projectId}/documents/processed/page/${
            extractedPageOptions.current
        }/limit/${extractedPageOptions.limit}${getQueryParams()}`,
        method: "get",
    });

    const onClearFilter = () => {
        setFilterData(initialFilterData);
        setSearchQuery("");
    };

    const clearDocFilter = () => {
        setDocFilterOpt(initialDocFilterOpt);
        setDocSearchQuery("");
    };

    useEffect(() => {
        getSummary();
    }, []);

    useEffect(() => {
        const getParams = transformParams({
            ...sortData,
            ...filterData,
            name: searchQuery.length > 2 ? searchQuery : "",
        });

        callExtractedDocApi({
            url: `/api/project/${projectId}/documents/processed/page/${extractedPageOptions.current}/limit/${extractedPageOptions.limit}${getParams}`,
        });
    }, [sortData, extractedPageOptions, filterData]);

    useEffect(() => {
        const getParams = transformParams({
            ...docFilterOpt,
            ...docSortOpt,
            name: docSearchQuery.length > 2 ? docSearchQuery : "",
        });

        callDocListApi({
            url: `/api/project/${projectId}/documents/page/${pageDetails.selected}/limit/${pageDetails.limit}${getParams}`,
        });
    }, [docSortOpt, docFilterOpt, pageDetails]);

    const showDocModal = () => {
        const id = "doc-modal";

        showModal({
            isModal: true,
            id,
            children: (
                <DocumentUploadModal
                    docSummary={summaryResponseData?.projectMetaData}
                    projectName={projectName}
                    projectId={projectId || ""}
                    onClose={(isSuccess) => {
                        hideModal(id);
                        if (isSuccess) {
                            getSummary();
                            callDocListApi({
                                url: `/api/project/${projectId}/documents/page/${selected}/limit/${limit}`,
                            });
                        }
                    }}
                />
            ),
        });
    };

    const showEntityUploadModal = () => {
        const id = "entity-modal";

        showModal({
            id,
            children: (
                <ExtractionModelUploadModal
                    projectName={projectName}
                    projectId={projectId || ""}
                    onClose={(isSuccess) => {
                        hideModal(id);
                        if (isSuccess) {
                            getSummary();
                            callDocListApi({
                                url: `/api/project/${projectId}/documents/page/${selected}/limit/${limit}`,
                            });
                        }
                    }}
                />
            ),
        });
    };

    const showDeleteModal = () => {
        const id = "delete";

        const btnConfig: ButtonConfig[] = [
            {
                label: "Cancel",
                appearance: TERTIARY_BUTTON,
                onClick: () => hideModal(id),
            },
            {
                label: "Confirm",
                onClick: async () => {
                    const ids = encodeURIComponent(
                        JSON.stringify(selectedDocIds),
                    );

                    dispatch({ type: SHOW_LOADER });

                    const token = sessionStorage.getItem("_mid-access-token");
                    const headers = {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    };

                    axios({
                        method: "delete",
                        url: `/api/project/${projectId}/documents?id=${ids}`,
                        headers,
                    })
                        .then(() => {
                            dispatch({
                                type: SHOW_NOTIFICATION,
                                payload: [
                                    {
                                        message: `${selectedDocIds.length} documents have been deleted successfully.`,
                                        type: SUCCESS_ALERT_CONTEXTUAL,
                                    },
                                ],
                            });
                            callDocListApi({
                                url: `/api/project/${projectId}/documents/page/${selected}/limit/${limit}`,
                            });
                            const getParams = transformParams({
                                ...sortData,
                                ...filterData,
                                name: searchQuery.length > 2 ? searchQuery : "",
                            });
                            callExtractedDocApi({
                                url: `/api/project/${projectId}/documents/processed/page/${extractedPageOptions.current}/limit/${extractedPageOptions.limit}${getParams}`,
                            });
                            setSelectedDocIds([]);
                            onClearFilter();
                        })
                        .catch(() => {
                            dispatch({
                                type: SHOW_NOTIFICATION,
                                payload: [
                                    {
                                        message:
                                            "Could not delete documents. Please try again.",
                                        type: ERROR_ALERT_GLOBAL,
                                    },
                                ],
                            });
                        })
                        .finally(() => {
                            hideModal(id);
                            getSummary();
                            dispatch({ type: HIDE_LOADER });
                        });
                },
            },
        ];

        showModal({
            id,
            children: (
                <ModalWrapper
                    headerText={`Delete Documents (${selectedDocIds.length})`}
                    actionItems={btnConfig}
                >
                    <DeleteDocModal />
                </ModalWrapper>
            ),
        });
    };

    const { data: docListData, err: docListError } = docListResponse;
    const { data: extractedDocData, err: extractedDocError } =
        extractedDocResponse;

    const hasErrors = () =>
        summaryResponseData.langMismatchDocCount ||
        summaryResponseData.lowConfidenceDocCount;

    const onAnalyzeClick = () => {
        if (hasErrors()) {
            const id = "analysis-caution";
            showModal({
                id,
                children: (
                    <CautionModal
                        onClose={(response) => {
                            hideModal(id);
                            if (response)
                                navigate(`/projects/${projectId}/analysis`, {
                                    state: {
                                        ...(state as ProjectState),
                                        projectName,
                                        projectId,
                                        totalExtractedFiles:
                                            extractedDocData?.metadata
                                                ?.totalRecords,
                                        projectSummary: summaryResponse?.data,
                                    },
                                });
                        }}
                        projectSummary={summaryResponse.data}
                    />
                ),
            });
        } else {
            navigate(`/projects/${projectId}/analysis`, {
                state: {
                    ...(state as ProjectState),
                    projectName,
                    projectId,
                    totalExtractedFiles:
                        extractedDocData?.metadata?.totalRecords,
                },
            });
        }
    };

    const onDownloadClick = async () => {
        if (projectId) {
            try {
                dispatch({ type: SHOW_LOADER });
                const response = await callDownloadAPI(
                    `/api/project/${projectId}/documents/output/download`,
                );
                const filename = `${new Date()
                    .toISOString()
                    .slice(0, 10)}_${projectName}_ExtractedFiles.zip`;
                downloadAttachment(response, filename);
                dispatch({ type: HIDE_LOADER });
            } catch (err) {
                dispatch({
                    type: "SHOW_NOTIFICATION",
                    payload: [
                        {
                            message: "Something went wrong, please try again.",
                            type: ERROR_ALERT_CONTEXTUAL,
                        },
                    ],
                });
                dispatch({ type: HIDE_LOADER });
            }
        }
    };

    const isAdmin = (userId: Users[]) => {
        if (!userId) {
            return false;
        }
        const email = sessionStorage.getItem("email");
        const response = userId?.map(
            (user: Users) => user.type === 3 && user.assignedTo === email,
        );
        const isAdminPresent = !!response?.includes(true);
        return isAdminPresent;
    };
    const btnConfig: ButtonConfig[] = [
        {
            label: "Configure",
            appearance: SECONDARY_BUTTON,
            type: "dropdown",
            dropdownListItem: [
                {
                    dropdownLabel: "Entity Extraction Model",
                    onClick: showEntityUploadModal,
                },
            ],
            isVisible: authUtils.isSuperAdmin() || isAdmin(users),
        },
        { label: "Upload", onClick: showDocModal },
        {
            label: "Delete",
            appearance: TERTIARY_BUTTON,
            disabled: !selectedDocIds.length,
            onClick: showDeleteModal,
        },
    ];

    const extractBtnConfig: ButtonConfig[] = [
        {
            label: "Analyze",
            onClick: onAnalyzeClick,
            tooltip: "Analyze all documents",
        },
        {
            label: "Download",
            appearance: SECONDARY_BUTTON,
            onClick: onDownloadClick,
            disabled: !extractedDocData?.data?.length,
            tooltip:
                "Click here to download all text and table extracted files in a zip.",
        },
        {
            label: "Delete",
            appearance: TERTIARY_BUTTON,
            disabled: !selectedDocIds.length,
            onClick: showDeleteModal,
        },
    ];

    const buttonConfig =
        selectedTab === "upload" ? btnConfig : extractBtnConfig;

    useEffect(() => {
        const getParams = transformParams({
            ...sortData,
            ...filterData,
            name: searchQuery.length > 2 ? searchQuery : "",
        });
        const isFilterApplied = !!getParams || !!extractedDocData?.data?.length;
        const route = location.pathname.substring(
            location.pathname.lastIndexOf("/") + 1,
        );
        if (
            route === "processed" &&
            extractedDocData !== null &&
            isFilterApplied
        ) {
            setSelectedTab("processed");
            navigate("processed", {
                state: {
                    ...(state as ProjectState),
                    projectName,
                    users,
                },
            });
        } else if (extractedDocData !== null) {
            setSelectedTab("upload");
            navigate("", {
                state: {
                    ...(state as ProjectState),
                    projectName,
                    users,
                },
            });
        }
    }, [location.pathname, extractedDocData, docListData]);

    const hasParams = transformParams({
        ...sortData,
        ...filterData,
        name: searchQuery,
    });

    const isDisabled = hasParams
        ? false
        : !extractedDocData?.metadata?.totalRecords &&
          (extractedDocData !== null || extractedDocError !== null);
    const onSortClick = (label: string, direction: string) => {
        if (label === sortData.sort) {
            setSortData((prev) => ({
                ...prev,
                sortDir: direction === ASC ? DESC : ASC,
            }));
        } else {
            setSortData({
                sort: label,
                sortDir: ASC,
            });
        }
    };

    const onDocSortClick = (label: string, direction: string) => {
        if (label === docSortOpt.sort) {
            setDocSortOpt((prev) => ({
                ...prev,
                sortDir: direction === ASC ? DESC : ASC,
            }));
        } else {
            setDocSortOpt({
                sort: label,
                sortDir: ASC,
            });
        }
    };

    const delayedQuery = useCallback(
        debounce((val) => {
            if (val.length > 2 || val === "") {
                setExtractedPageOptions((prev) => ({
                    ...prev,
                    current: 1,
                }));
            }
        }, 500),
        [filterData, sortData, extractedPageOptions],
    );

    const delayedDocQuery = useCallback(
        debounce((val) => {
            if (val.length > 2 || val === "") {
                setPageDetails((prev) => ({
                    ...prev,
                    selected: 1,
                }));
            }
        }, 500),
        [docFilterOpt, docSortOpt, pageDetails],
    );

    const onFilterData = (field: string, val: string | number) => {
        const updatedData = { ...filterData };

        const updateArray = (key: "table" | "errors", value: number) => {
            const index = updatedData[key].findIndex((el) => el === val);
            if (index !== -1) {
                updatedData[key] = updatedData[key].filter(
                    (_, ind) => ind !== index,
                );
            } else {
                updatedData[key].push(value);
            }
        };

        if (field === "name") {
            delayedQuery(val);
            setSearchQuery(val as string);
        } else if (field === "table") {
            updateArray("table", val as number);
            setFilterData(updatedData);
        } else if (field === "errors") {
            updateArray("errors", val as number);
            setFilterData(updatedData);
        }
    };

    const onDocFilterChange = (field: string, val: string | number) => {
        const updatedData = { ...docFilterOpt };

        const updateArray = (key: "status", value: number) => {
            const index = updatedData[key].findIndex((el) => el === val);
            if (index !== -1) {
                updatedData[key] = updatedData[key].filter(
                    (_, ind) => ind !== index,
                );
            } else {
                updatedData[key].push(value);
            }
        };

        if (field === "name") {
            delayedDocQuery(val);
            setDocSearchQuery(val as string);
        } else if (field === "status") {
            updateArray("status", val as number);
            setDocFilterOpt(updatedData);
        }
    };

    useEffect(() => {
        setSelectedDocIds([]);
    }, [selectedTab]);

    const shouldRefreshData = (
        summary: [{ status: number; count: number }],
    ) => {
        const items = summary || [];
        const hasPendingItems = items.some(
            (item: { status: number }) =>
                item.status === DocumentStatus.Pending ||
                item.status === DocumentStatus["In Progress"],
        );
        return hasPendingItems;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (shouldRefreshData(summaryResponseData.summary)) {
                callDocListApi({ isBackgroundOp: true });
                callExtractedDocApi({ isBackgroundOp: true });
                getSummary({ isBackgroundOp: true });
            } else {
                clearInterval(interval);
            }
        }, 10000);

        return () => {
            clearInterval(interval);
        };
    });

    const onSelectChange = useCallback(
        (docId: number | "all") => {
            if (docId === "all") {
                const rowData =
                    selectedTab === "upload"
                        ? docListData?.data
                        : extractedDocData?.data;

                const ids = rowData.map(
                    ({ id, documentId }: { id: number; documentId: number }) =>
                        selectedTab === "upload" ? id : documentId,
                );
                const allChecked = ids.every((item: number) =>
                    selectedDocIds.includes(item),
                );

                setSelectedDocIds(allChecked ? [] : ids);
            } else {
                let selectedDocIdsCopy = [...selectedDocIds];
                const index = selectedDocIdsCopy.findIndex(
                    (id) => id === docId,
                );

                if (index !== -1) {
                    selectedDocIdsCopy = selectedDocIdsCopy.filter(
                        (_, ind) => ind !== index,
                    );
                } else {
                    selectedDocIdsCopy.push(docId);
                }
                setSelectedDocIds(selectedDocIdsCopy);
            }
        },
        [docListData, extractedDocData, selectedDocIds, selectedTab],
    );

    return (
        <div className={styles.container}>
            <section className={styles.projectName}>
                <Header
                    heading={
                        <Breadcrumbs>
                            <Link to="/projects">Projects</Link>
                            <Link to=".">{projectName}</Link>
                        </Breadcrumbs>
                    }
                >
                    <ExtractionSummary summary={summaryResponseData?.summary} />
                </Header>
            </section>

            <div className={styles.tabs}>
                <Tabs
                    selectedValue={selectedTab}
                    onChange={(value) => {
                        navigate(value === "upload" ? "" : "processed", {
                            state: {
                                ...(state as ProjectState),
                                projectName,
                                users,
                            },
                        });

                        setSelectedTab(value);
                    }}
                >
                    <Tab
                        label={
                            <Tooltip
                                content="View successfully uploaded Documents here."
                                dark={undefined}
                                trigger="mouseenter"
                                placement="top-end"
                                maxWidth={200}
                            >
                                <div>
                                    <div>
                                        {labelWithCount(
                                            "Documents",
                                            docListData?.metadata?.totalRecords,
                                        )}
                                    </div>
                                </div>
                            </Tooltip>
                        }
                        value="upload"
                        className={styles.heading}
                    />

                    <Tab
                        label={
                            <Tooltip
                                content="View OCR extracted files here."
                                dark={undefined}
                                trigger="mouseenter"
                                placement="top"
                                maxWidth={200}
                            >
                                <div>
                                    {labelWithCount(
                                        "Extracted files",
                                        extractedDocData?.metadata
                                            ?.totalRecords,
                                    )}
                                </div>
                            </Tooltip>
                        }
                        value="processed"
                        disabled={isDisabled}
                        className={styles.heading}
                    />
                </Tabs>

                <ActionItems config={buttonConfig} />
            </div>
            <div className={styles.documentList}>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <ShouldRender
                                condition={
                                    docListData !== null ||
                                    docListError !== null
                                }
                                hasError={!!docListError}
                            >
                                <DocumentList
                                    onSelectChange={onSelectChange}
                                    searchQuery={docSearchQuery}
                                    sortData={docSortOpt}
                                    onSortClick={onDocSortClick}
                                    filterData={docFilterOpt}
                                    onFilterChange={onDocFilterChange}
                                    clearFilter={clearDocFilter}
                                    selectedDocs={selectedDocIds}
                                    documents={docListData?.data || []}
                                    uploadBtnConfig={btnConfig[1]}
                                    selectedPage={selected}
                                    totalPages={
                                        docListData?.metadata?.totalPages || 1
                                    }
                                    limit={limit}
                                    onChange={(page) => {
                                        setPageDetails((prev) => ({
                                            ...prev,
                                            selected: page,
                                        }));
                                    }}
                                />
                            </ShouldRender>
                        }
                    />
                    <Route
                        path="processed"
                        element={
                            <ShouldRender
                                condition={
                                    extractedDocData !== null ||
                                    extractedDocError !== null
                                }
                                hasError={!!extractedDocError}
                            >
                                <ExtractedDocumentList
                                    selectedDocs={selectedDocIds}
                                    onSelectChange={onSelectChange}
                                    searchQuery={searchQuery}
                                    sortData={sortData}
                                    onSortClick={onSortClick}
                                    filterData={filterData}
                                    onFilterChange={onFilterData}
                                    clearFilter={onClearFilter}
                                    documents={extractedDocData?.data}
                                    selectedPage={extractedPageOptions.current}
                                    totalPages={
                                        extractedDocData?.metadata?.totalPages
                                    }
                                    limit={extractedPageOptions.limit}
                                    onChange={(page) => {
                                        setExtractedPageOptions((prev) => ({
                                            ...prev,
                                            current: page,
                                        }));
                                    }}
                                />
                            </ShouldRender>
                        }
                    />
                </Routes>
            </div>
            <ModalFactory />
        </div>
    );
}
