import React, { useState, useEffect, useContext } from "react";

import styled from "@emotion/styled";
import Outline24CInfo from "@mds/mds-icons/icons/svg/outline-24-c-info.svg";
import {
    FormElementWrapper,
    SIZE_MEDIUM,
    SIZE_LARGE,
    Input,
    Select,
    Grid,
    ButtonAppearanceType,
    TERTIARY_BUTTON,
    PRIMARY_BUTTON,
    Button,
    TextArea,
    SUCCESS_ALERT_CONTEXTUAL,
    ERROR_ALERT_CONTEXTUAL,
    Tooltip,
    Checkbox,
} from "@mds/mds-reactjs-library";
import classNames from "classnames";

import { ApplicationContext } from "../../../App";
import Error from "../../../components/Error";
import ShouldRender from "../../../components/ShouldRender";
import authUtils from "../../../utils/authUtils";
import useApi from "../../../utils/useApi";
import styles from "./CreateProject.module.scss";
import InputWithChips from "./InputWithChips";
import {
    initialValues,
    lengthValidation,
    FormFieldEntity,
    FormStateEntity,
    areEqual,
    FieldName,
} from "./types";

interface Props {
    onClose: (isSuccess: boolean) => void;
    mode: string;
    data: FormStateEntity;
}

const TextAreaStyled = styled(TextArea)`
    min-height: 100px;
`;

const CreateForm: React.FC<Props> = ({
    onClose,
    mode,
    data: selectedRowData,
}) => {
    const [formState, setFormState] = useState(
        mode === "edit" ? selectedRowData : initialValues,
    );

    const [emailErr, setEmailErr] = useState({
        users: "",
        admins: "",
    });
    const [confirmedClicked, setConfirmedClick] = useState(false);
    const [response, callToApi] = useApi({
        url: "/api/project",
        method: "post",
        data: null,
    });

    const {
        chargeCode,
        description,
        documentType,
        edName,
        emName,
        id,
        language,
        name,
        practice,
        users = selectedRowData.users,
        admins = selectedRowData.admins,
        uploadThreshold,
        allScanned,
        highlightKPILabels,
    } = formState || {};

    const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({
            ...prev,
            [event?.target?.name]: event?.target?.value,
        }));
    };

    const [fieldErr, setFieldErr] = useState("");

    const onSelectChange = (val: string, key: string) => {
        setFormState((prev) => ({
            ...prev,
            [key]: val,
        }));
    };

    const onCheckHandler = (val: boolean, key: string) => {
        setFormState((prev) => ({
            ...prev,
            [key]: !val,
        }));
    };

    const isAdmin = (adminsList: any[]): boolean => {
        const email = sessionStorage.getItem("email")?.toLowerCase() || "";
        return !!adminsList?.find(
            (adminEmail: any) => adminEmail.toLowerCase() === email,
        );
    };

    const { dispatch } = useContext(ApplicationContext);
    const isSuperAdmin = authUtils.isSuperAdmin();

    const uploadThresholdField = isSuperAdmin
        ? [
              {
                  size: SIZE_MEDIUM,
                  component: Input,
                  label: "Document Upload Threshold",
                  inputProps: {
                      name: "uploadThreshold" as FieldName,
                      value: uploadThreshold,
                      placeholder: "",
                      onChange: onInputChange,
                  },
                  span: 6,
                  required: false,
                  disabled: false,
              },
          ]
        : [];

    const formConfig: FormFieldEntity[][] = [
        [
            {
                size: SIZE_MEDIUM,
                component: Input,
                label: "Project Name",
                inputProps: {
                    placeholder: "Enter Project Name",
                    name: "name",
                    onChange: onInputChange,
                    value: name,
                },
                required: true,
                span: 6,
                validationFunc: lengthValidation,
                isError: !!(confirmedClicked && !name.trim()),
                disabled: false,
            },
            {
                size: SIZE_MEDIUM,
                component: Input,
                label: "Charge Code",
                inputProps: {
                    name: "chargeCode",
                    placeholder: "Enter Charge Code",
                    onChange: onInputChange,
                    value: chargeCode,
                },
                required: true,
                span: 6,
                isError: !!(confirmedClicked && !chargeCode && mode !== "edit"),
                disabled: mode === "edit",
            },
        ],
        [
            {
                size: SIZE_MEDIUM,
                component: Select,
                label: "Document Type",
                inputProps: {
                    name: "documentType",
                    placeholder: "Enter Document Type",
                    custom: true,
                    value: documentType,
                    onChange: (val: string) =>
                        onSelectChange(val, "documentType"),
                    options: [
                        {
                            label: "Contract",
                            value: "Contract",
                        },
                        { label: "Invoice", value: "Invoice" },
                        { label: "Others", value: "Others" },
                    ],
                },
                required: true,
                span: 6,
                isError: !!(confirmedClicked && !documentType),
                disabled: false,
            },
            {
                size: SIZE_MEDIUM,
                component: Select,
                label: "Language",
                inputProps: {
                    name: "language",
                    placeholder: "Enter Language",
                    custom: true,
                    value: language,
                    onChange: (val: string) => onSelectChange(val, "language"),
                    options: [
                        { label: "English", value: "en" },
                        { label: "French", value: "fr" },
                        { label: "German", value: "de" },
                        { label: "Latin", value: "la" },
                        { label: "Portuguese", value: "pt" },
                        { label: "Spanish", value: "es" },
                    ],
                },
                required: true,
                span: 6,
                isError: !!(confirmedClicked && !language),
                disabled: mode === "edit",
            },
        ],
        [
            {
                size: SIZE_MEDIUM,
                component: Input,
                label: "Engagement Director",
                inputProps: {
                    name: "edName",
                    value: edName,
                    placeholder: "Enter Engagement Director Name",
                    onChange: onInputChange,
                },
                required: true,
                span: 6,
                validationFunc: lengthValidation,
                isError: !!(confirmedClicked && !edName),
                disabled: false,
            },

            {
                size: SIZE_MEDIUM,
                component: Input,
                label: "Engagement Manager",
                inputProps: {
                    name: "emName",
                    value: emName,
                    placeholder: "Enter Engagement Manager Name",
                    onChange: onInputChange,
                },
                required: true,
                span: 6,
                validationFunc: lengthValidation,
                isError: !!(confirmedClicked && !emName),
                disabled: false,
            },
        ],
        [
            {
                size: SIZE_MEDIUM,
                component: Input,
                label: "Practice-Service Lines",
                inputProps: {
                    name: "practice",
                    value: practice,
                    placeholder: "ex Manufacturing, Supply Chain",
                    onChange: onInputChange,
                },
                required: true,
                span: 6,
                validationFunc: lengthValidation,
                isError: !!(confirmedClicked && !practice),
                disabled: false,
            },
            ...uploadThresholdField,
        ],
    ];

    const btnConfig: {
        label: string;
        appearance: ButtonAppearanceType;
        disabled?: boolean;
        onClick: React.MouseEventHandler;
    }[] = [
        {
            label: "Cancel",
            appearance: TERTIARY_BUTTON,
            disabled: false,
            onClick: () => {
                setConfirmedClick(false);
                onClose(false);
            },
        },
        {
            label: mode === "edit" ? "Update" : "Submit",
            disabled: areEqual(selectedRowData, formState),
            appearance: PRIMARY_BUTTON,
            onClick: () => {
                const requestData: {
                    name: string;
                    edName: string;
                    emName: string;
                    chargeCode: string;
                    practice: string;
                    language: string;
                    documentType: string;
                    description: string;
                    users: string[];
                    admins: string[];
                    uploadThreshold?: number;
                    allScanned?: boolean;
                    highlightKPILabels?: boolean;
                } = {
                    name,
                    edName,
                    emName,
                    chargeCode,
                    practice,
                    language,
                    documentType,
                    description,
                    users,
                    admins,
                    uploadThreshold,
                    allScanned,
                    highlightKPILabels,
                };
                if (!isSuperAdmin) {
                    delete requestData.uploadThreshold;
                }
                if (!isSuperAdmin && !isAdmin) {
                    delete requestData.highlightKPILabels;
                    delete requestData.allScanned;
                }
                const isMandatoryFieldEmpty = [
                    chargeCode.trim(),
                    name.trim(),
                    documentType.trim(),
                    edName.trim(),
                    emName.trim(),
                    language.trim(),
                    practice.trim(),
                ].includes("");
                setConfirmedClick(true);
                if (isMandatoryFieldEmpty) {
                    setFieldErr("Please fill in all the mandatory(*) details!");
                } else if (emailErr.admins || emailErr.users) {
                    setFieldErr("Please resolve errors to proceed !");
                } else {
                    setFieldErr("");
                    if (mode === "edit") {
                        callToApi({
                            url: `/api/project/${id}`,
                            method: "put",
                            data: requestData,
                        });
                    } else {
                        callToApi({
                            data: requestData,
                        });
                    }
                }
            },
        },
    ];

    const { isSuccessful, err, data } = response;
    useEffect(() => {
        const hasAPICalled = isSuccessful !== null;

        if (hasAPICalled) {
            const isSuccess = isSuccessful && data;
            const isFailure = !isSuccessful && err;

            if (isSuccess) {
                dispatch({
                    type: "SHOW_NOTIFICATION",
                    payload: [
                        {
                            message:
                                mode === "edit"
                                    ? `Project '${selectedRowData.name}' has been updated successfully.`
                                    : `Project '${name}' has been created successfully.`,
                            type: SUCCESS_ALERT_CONTEXTUAL,
                        },
                    ],
                });
                onClose(true);
            }

            if (isFailure) {
                const msg =
                    err.response.status === 409
                        ? `Could not create Project '${name}' as this name is already taken.`
                        : "Something went wrong.";
                const updtmsg = `Project '${name}' could not be updated, Please Try again !.`;

                dispatch({
                    type: "SHOW_NOTIFICATION",
                    payload: [
                        {
                            message: mode === "edit" ? updtmsg : msg,
                            type: ERROR_ALERT_CONTEXTUAL,
                        },
                    ],
                });
                onClose(false);
            }
        }
    }, [isSuccessful, err, data]);

    return (
        <div className={styles.container}>
            <h3 className={styles.heading}>
                {mode === "edit"
                    ? `Edit Project - ${selectedRowData.name}`
                    : "New Project"}
            </h3>
            <section className={styles.form}>
                {formConfig.map((row) => (
                    <Grid
                        container
                        key={row[0].label}
                        className={styles.row}
                        spacing={[24]}
                    >
                        {row.map(
                            ({
                                size,
                                component,
                                label,
                                inputProps,
                                required,
                                span,
                                validationFunc,
                                isError,
                                disabled,
                            }) => (
                                <Grid item span={span} key={label}>
                                    <div>
                                        <div
                                            className={classNames({
                                                required,
                                            })}
                                        >
                                            <FormElementWrapper
                                                size={size}
                                                component={component}
                                                label={label}
                                                inputProps={inputProps}
                                                validationFunc={validationFunc}
                                                error={{ isError }}
                                                disabled={disabled}
                                            />
                                        </div>
                                    </div>
                                </Grid>
                            ),
                        )}
                    </Grid>
                ))}
                <ShouldRender
                    condition={
                        !!isSuperAdmin || !!isAdmin(selectedRowData.admins)
                    }
                >
                    <Grid container className={styles.row} alignItems="center">
                        <Grid item className={styles.highlightKpis}>
                            <FormElementWrapper
                                size={SIZE_MEDIUM}
                                component={Checkbox}
                                inputProps={{
                                    name: "highlightKPILabels",
                                    placeholder: "",
                                    label: "Highlight KPI",
                                    checked: highlightKPILabels,
                                    value: highlightKPILabels,
                                    onChange: (val: boolean) =>
                                        onCheckHandler(
                                            val,
                                            "highlightKPILabels",
                                        ),
                                }}
                            />
                            <Tooltip
                                content="Highlight both Key & Values of KPI's"
                                dark
                            >
                                <img
                                    width={16}
                                    height={16}
                                    alt="info"
                                    src={Outline24CInfo}
                                    className={styles.tooltip}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item className={styles.allScannedDocs}>
                            <FormElementWrapper
                                size={SIZE_MEDIUM}
                                component={Checkbox}
                                inputProps={{
                                    name: "allScanned",
                                    placeholder: "",
                                    label: "All Scanned Documents",
                                    checked: allScanned,
                                    value: allScanned,
                                    onChange: (val: boolean) =>
                                        onCheckHandler(val, "allScanned"),
                                }}
                            />
                            <Tooltip
                                content="Treats all documents as scanned documents when extracting"
                                dark
                            >
                                <img
                                    width={16}
                                    height={16}
                                    alt="info"
                                    src={Outline24CInfo}
                                    className={styles.tooltip}
                                />
                            </Tooltip>
                        </Grid>
                    </Grid>
                </ShouldRender>
                <Grid
                    container
                    key="Project Members"
                    className={styles.row}
                    spacing={[24]}
                >
                    <Grid item span={12} key="Project Members">
                        <p className={styles.label}>Project members</p>
                        <InputWithChips
                            onInputChange={(txt: string) => {
                                setEmailErr((prev) => ({
                                    ...prev,
                                    users: txt,
                                }));
                            }}
                            defaultValues={selectedRowData.users}
                            onChange={(val) => {
                                setFormState((prev) => ({
                                    ...prev,
                                    users: val,
                                }));
                            }}
                        />
                    </Grid>
                    <ShouldRender condition={isSuperAdmin}>
                        <Grid item span={12} key="Project Admins">
                            <p className={styles.label}>Project Admins</p>
                            <InputWithChips
                                onInputChange={(txt: string) => {
                                    setEmailErr((prev) => ({
                                        ...prev,
                                        admins: txt,
                                    }));
                                }}
                                defaultValues={selectedRowData.admins}
                                onChange={(val) => {
                                    setFormState((prev) => ({
                                        ...prev,
                                        admins: val,
                                    }));
                                }}
                            />
                        </Grid>
                    </ShouldRender>
                </Grid>
                <Grid container className={styles.row} spacing={[24]}>
                    <Grid item span={12}>
                        <FormElementWrapper
                            size={SIZE_LARGE}
                            component={TextAreaStyled}
                            label="Description"
                            inputProps={{
                                name: "description",
                                placeholder: "Enter Project Description",
                                value: description,
                                onChange: onInputChange,
                            }}
                            validationFunc={(val: string) =>
                                val.trim().length > 250
                                    ? "Max 250 chars allowed"
                                    : ""
                            }
                        />
                    </Grid>
                </Grid>
            </section>
            <ShouldRender condition={!!fieldErr}>
                <div className={styles.errorMsgSpace}>
                    <Error fieldErr={fieldErr} />
                </div>
            </ShouldRender>

            <section className={styles.footer}>
                <div className={styles.note}>- Mandatory Fields</div>
                <div className={styles.actions}>
                    {btnConfig.map(
                        ({ label, onClick, disabled, appearance }) => (
                            <div key={label} className={styles.btnWrapper}>
                                <Button
                                    appearance={appearance}
                                    disabled={disabled}
                                    onClick={onClick}
                                >
                                    {label}
                                </Button>
                            </div>
                        ),
                    )}
                </div>
            </section>
        </div>
    );
};
export default CreateForm;
