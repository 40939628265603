export interface FormStateEntity {
    name: string;
    chargeCode: string;
    documentType: string;
    language: string;
    edName: string;
    emName: string;
    practice: string;
    users: string[];
    description: string;
    id: number;
    comments: string;
    createdDate: string;
    status: string;
    admins: string[];
    uploadThreshold: number;
    allScanned: boolean;
    highlightKPILabels: boolean;
}

export const initialValues: FormStateEntity = {
    name: "",
    chargeCode: "",
    documentType: "",
    language: "",
    edName: "",
    emName: "",
    practice: "",
    users: [""],
    description: "",
    id: 0,
    comments: "",
    createdDate: "",
    status: "",
    admins: [],
    uploadThreshold: 10000,
    allScanned: false,
    highlightKPILabels: false,
};

export type FieldName =
    | "name"
    | "chargeCode"
    | "documentType"
    | "language"
    | "edName"
    | "emName"
    | "practice"
    | "users"
    | "description"
    | "uploadThreshold"
    | "allScanned"
    | "highlightKPILabels";

export const lengthValidation = (val: string) =>
    val.trim().length > 35 ? "Max 35 chars allowed" : "";

export interface FormFieldEntity {
    size: string;
    component: React.ReactNode;
    label: string;
    inputProps: {
        name: FieldName;
        placeholder?: string;
        custom?: boolean;
        options?: {
            label: string;
            value: string;
        }[];
        value: string | number | boolean;
        onChange: (event: any) => void;
    };
    required: boolean;
    span: number;
    validationFunc?: (val: string) => void;
    isError?: boolean;
    disabled: boolean;
}

export const areEqual = (obj1: any, obj2: any) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    return (
        keys1.length === keys2.length &&
        Object.keys(obj1).every((key) => obj1[key] === obj2[key])
    );
};
