import React from "react";

import { Avatar, PopupMenu } from "@mds/mds-reactjs-library";

import styles from "./PageHeader.module.scss";

const PageHeader = () => {
    const name = sessionStorage.getItem("name") || "";

    return (
        <header className={styles.header}>
            <h1>
                <span className={styles.assetName}>DocIQ</span>
                <span className={styles.brand}>McKinsey & Company</span>
            </h1>
            <PopupMenu
                items={[
                    {
                        label: "Logout",
                        onClick: () => {
                            window.location.href = "/logout";
                        },
                    },
                ]}
            >
                <div data-testid="avatar">
                    <Avatar alt={name} />
                </div>
            </PopupMenu>
        </header>
    );
};
export default PageHeader;
