import React from "react";

import "./SelectedDocList.scss";
import { FilesEntity } from "..";
import DocumentDetailRow from "../DocumentDetailRow";

export interface Props {
    validFiles: FilesEntity[];
    invalidFiles: FilesEntity[];
    onRemove: (id: number) => void;
}

function DocumentList(props: Props): JSX.Element {
    const { validFiles, invalidFiles, onRemove } = props;

    return (
        <div className="doc-list">
            <div className="list-header">
                <div className="name">Name</div>
                <div className="size">Size</div>
            </div>
            <div className="list-body">
                <div className="rejected-files">
                    {invalidFiles.map((item) => (
                        <React.Fragment key={item.id}>
                            <DocumentDetailRow
                                data={item}
                                onRemove={onRemove}
                            />
                        </React.Fragment>
                    ))}
                </div>
                <div className="accepted-files">
                    {validFiles.map((item) => (
                        <React.Fragment key={item.id}>
                            <DocumentDetailRow
                                data={item}
                                onRemove={onRemove}
                            />
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default React.memo(DocumentList);
