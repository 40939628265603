import { useState, useCallback, useContext } from "react";

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

import { ApplicationContext } from "../App";
import { HIDE_LOADER, SHOW_LOADER } from "../data/appConstants";

interface Props {
    url: string;
    method: "get" | "post" | "put" | "delete";
    data?: any;
    headers?: any;
    responseType?: any;
    onProgressChange?: (progress: number) => void;
}

interface State {
    data: any;
    err: any;
    isSuccessful: null | boolean;
}

interface RequestConfig extends AxiosRequestConfig {
    isBackgroundOp: boolean;
}

const token = window?.sessionStorage.getItem("_mid-access-token");
const useApi = ({
    url,
    method,
    data = null,
    headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    },
    responseType,
    onProgressChange,
}: Props): [State, any] => {
    const [response, setResponse] = useState<State>({
        data: null,
        err: null,
        isSuccessful: null,
    });
    const { dispatch } = useContext(ApplicationContext);

    const callToApi = useCallback(
        (reqData?: RequestConfig) => {
            setResponse({
                data: null,
                err: null,
                isSuccessful: null,
            });
            if (!reqData?.isBackgroundOp) {
                dispatch({ type: SHOW_LOADER });
            }

            const params = {
                method,
                url,
                data,
                headers,
                ...reqData,
                responseType,
            };

            const onDownloadProgress = (progressEvent: any) => {
                const totalLength = progressEvent.lengthComputable
                    ? progressEvent.total
                    : progressEvent.target.getResponseHeader(
                          "content-length",
                      ) ||
                      progressEvent.target.getResponseHeader(
                          "x-decompressed-content-length",
                      );
                if (totalLength !== null) {
                    const progress = Math.round(
                        (progressEvent.loaded * 100) / totalLength,
                    );
                    if (onProgressChange) onProgressChange(progress);
                }
            };

            if (onProgressChange)
                params.onDownloadProgress = onDownloadProgress;

            axios(params)
                .then((res: AxiosResponse) => {
                    setResponse({
                        data: res.data || "Success",
                        err: null,
                        isSuccessful: true,
                    });
                })
                .catch((err: AxiosError) => {
                    setResponse({
                        data: null,
                        err,
                        isSuccessful: false,
                    });
                })
                .finally(() => {
                    if (!reqData?.isBackgroundOp) {
                        dispatch({ type: HIDE_LOADER });
                    }
                });
        },
        [url, method, data, headers],
    );
    return [response, callToApi];
};

export default useApi;
