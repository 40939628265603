import React from "react";

import { LABEL_POSITION_TOP, ProgressBar } from "@mds/mds-reactjs-library";

import ShouldRender from "../../../../components/ShouldRender";

interface Props {
    current: number;
    total: number;
    progress: number;
    currentFile: string;
    modal?: string;
}
const UploadProgress = ({
    current,
    total,
    progress,
    currentFile,
    modal,
}: Props) => (
    <>
        <div className="loader-cover" />
        <div className="loader">
            <div className="progress-bar">
                <div className="progress-message">
                    File upload is in progress...
                </div>
                <ShouldRender condition={modal !== "entity-upload"}>
                    <ProgressBar
                        steps={{
                            current,
                            total,
                        }}
                        label={{
                            text: "Total",
                            position: LABEL_POSITION_TOP,
                        }}
                    />
                </ShouldRender>
                <ProgressBar
                    progress={progress}
                    label={{
                        text: currentFile,
                        position: LABEL_POSITION_TOP,
                    }}
                />
            </div>
        </div>
    </>
);
UploadProgress.defaultProps = {
    modal: "",
};

export default UploadProgress;
