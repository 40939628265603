import React, {
    useContext,
    useEffect,
    useMemo,
    useState,
    useImperativeHandle,
    forwardRef,
    useRef,
} from "react";

import styled from "@emotion/styled";
import Glyph16ZoomIn from "@mds/mds-icons/icons/svg/glyph-16-zoom-in.svg";
import Glyph16ZoomOut from "@mds/mds-icons/icons/svg/glyph-16-zoom-out.svg";
import Outline16CheckSingle from "@mds/mds-icons/icons/svg/outline-16-check-single.svg";
import Outline16ERemove from "@mds/mds-icons/icons/svg/outline-16-e-remove.svg";
import Outline16Pencil from "@mds/mds-icons/icons/svg/outline-16-pencil.svg";
import {
    Button,
    Icon,
    Input,
    SECONDARY_BUTTON,
    SIZE_SMALL,
    TERTIARY_BUTTON,
    TYPE_GLYPH,
    TYPE_OUTLINE,
} from "@mds/mds-reactjs-library";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ShouldRender from "../../../../components/ShouldRender";
import { removeExtension } from "../../../../utils/fileUtils";
import textHighlighter from "../../../../utils/textHighlighter";
import useApi from "../../../../utils/useApi";
import utils from "../../../../utils/utils";
import styles from "./KPIDetail.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const defaultValue = {
    onNavigate: (page: number) => console.log("default value"),
};
const DataContext = React.createContext(defaultValue);

const ButtonStyled = styled(Button)`
    margin: 10px;
    width: 32px;
    height: 32px;
    min-height: 32px !important;
    min-width: 32px !important;
`;

const ButtonCircle = styled(Button)`
    margin: 10px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    min-height: 32px !important;
    min-width: 32px !important;
`;

type PropType = {
    src: any;
    kpis: any[];
    customRenderer: (str: string, patterns: any[]) => any;
};

const PDFRenderer = forwardRef(
    ({ src, kpis, customRenderer }: PropType, ref: any) => {
        const [pages, setPages] = useState(0);
        const [scale, setScale] = useState(1.25);
        const [page, setPage] = useState(1);

        useImperativeHandle(ref, () => ({
            changePage(pageNumber: number) {
                setPage(pageNumber);
            },
        }));

        const onDocLoaded = (evt: any) => {
            setPages(evt.numPages);
        };

        const next = () => {
            setPage(page + 1);
        };

        const prev = () => {
            setPage(page - 1);
        };

        const onZoom = () => {
            if (scale < 2) setScale(scale + 0.25);
        };

        const onZoomout = () => {
            if (scale > 0.25) setScale(scale - 0.25);
        };

        const textRenderer = (textItem: any) => {
            const patterns = kpis.map((kpi: any) => ({
                value: kpi.extracted_data,
                key: kpi.type,
                start: kpi.page_index[0],
                end: kpi.page_index[1],
            }));

            return customRenderer(textItem.str, patterns);
        };

        return (
            <div>
                <div className={`pagination ${styles.documentTools}`}>
                    <ButtonStyled
                        size={SIZE_SMALL}
                        disabled={page === 1}
                        onClick={prev}
                        appearance={TERTIARY_BUTTON}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                name="left-arrow"
                            />
                        }
                    />

                    <b style={{ marginTop: "15px", marginLeft: "5px" }}>
                        {page} of {pages}
                    </b>

                    <ButtonStyled
                        size={SIZE_SMALL}
                        disabled={page === pages}
                        onClick={next}
                        appearance={TERTIARY_BUTTON}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                name="right-arrow"
                            />
                        }
                    />

                    <ButtonStyled
                        className="btn"
                        size={SIZE_SMALL}
                        appearance={TERTIARY_BUTTON}
                        onClick={onZoomout}
                        disabled={scale === 0.25}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                name="search-zoom-out"
                                src={Glyph16ZoomOut}
                            />
                        }
                    />

                    <b style={{ marginTop: "15px", marginLeft: "5px" }}>
                        {scale * 100} %
                    </b>

                    <ButtonStyled
                        size={SIZE_SMALL}
                        appearance={TERTIARY_BUTTON}
                        onClick={onZoom}
                        disabled={scale === 2}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                src={Glyph16ZoomIn}
                            />
                        }
                    />
                </div>

                <Document onLoadSuccess={onDocLoaded} file={src}>
                    <Page
                        scale={scale}
                        pageNumber={page}
                        customTextRenderer={textRenderer}
                    />
                </Document>
            </div>
        );
    },
);

/* eslint-disable camelcase */
const KPIDetailRow = ({
    kpiItem,
}: {
    kpiItem: {
        extracted_data: string;
        name: string;
        modified?: boolean;
        type: string;
        page_index: number;
        page_no: number;
    };
}) => {
    const { name, extracted_data, type } = kpiItem;
    const { onNavigate } = useContext(DataContext);

    const [editMode, setEditMode] = useState(false);
    const [kpiValue, setKPIValue] = useState(kpiItem.extracted_data);

    const onModeChange = () => setEditMode(!editMode);

    /* eslint-disable no-param-reassign */
    const onValueChange = (evt: any) => {
        setKPIValue(evt.target.value);
    };

    const onEdit = () => {
        setEditMode(false);
        kpiItem.modified = true;
        kpiItem.extracted_data = kpiValue;
    };

    const onCancel = () => {
        setEditMode(false);
        setKPIValue(kpiItem.extracted_data);
    };

    const onPageChange = (page: number) => {
        onNavigate(page);
    };

    return (
        <div className={styles.kpiContainer}>
            <div className={styles.kpiRow}>
                <span
                    className={styles.legend}
                    style={{
                        backgroundColor: utils.getColorFromString(type),
                    }}
                >
                    &nbsp;&nbsp;&nbsp;
                </span>
                <span className={styles.text}>{name}</span>
            </div>
            <div>
                <ShouldRender condition={!editMode}>
                    <div className={styles.kpiRow}>
                        <span className={styles.text}> {extracted_data} </span>
                        <ButtonCircle
                            size={SIZE_SMALL}
                            appearance={TERTIARY_BUTTON}
                            startIcon={
                                <Icon
                                    size={16}
                                    type={TYPE_OUTLINE}
                                    src={Outline16Pencil}
                                />
                            }
                            onClick={onModeChange}
                        />
                        <ButtonCircle
                            size={SIZE_SMALL}
                            appearance={TERTIARY_BUTTON}
                            startIcon={
                                <Icon
                                    size={12}
                                    type={TYPE_OUTLINE}
                                    name="arrow-right"
                                />
                            }
                            onClick={() => onPageChange(kpiItem.page_no)}
                        />
                    </div>
                </ShouldRender>
                <ShouldRender condition={editMode}>
                    <div className={styles.kpiForm}>
                        <div className={styles.kpiInput}>
                            <Input
                                name="name"
                                value={kpiValue}
                                className={styles.input}
                                onChange={onValueChange}
                            />
                        </div>
                        <div className={styles.kpiSave}>
                            <ButtonCircle
                                size={SIZE_SMALL}
                                appearance={TERTIARY_BUTTON}
                                startIcon={
                                    <Icon
                                        size={16}
                                        type={TYPE_OUTLINE}
                                        src={Outline16CheckSingle}
                                    />
                                }
                                onClick={onEdit}
                            />
                            <ButtonCircle
                                size={SIZE_SMALL}
                                appearance={TERTIARY_BUTTON}
                                startIcon={
                                    <Icon
                                        size={16}
                                        type={TYPE_OUTLINE}
                                        src={Outline16ERemove}
                                    />
                                }
                                onClick={onCancel}
                            />
                        </div>
                    </div>
                </ShouldRender>
            </div>
        </div>
    );
};

const KPIDetails = ({ response }: { response: any }) => {
    const extractedKPIs = response?.data.kpi?.kpis || [];
    const kpiToShow = extractedKPIs.filter((kpi: any) => kpi.visible);

    return (
        <div className={styles.kpiDetail}>
            <div className={styles.header}>
                <b className={styles.heading}>Extracted KPI</b>
            </div>
            <hr />
            {kpiToShow.map((kpi: any) => (
                <>
                    <KPIDetailRow kpiItem={kpi} />
                    <hr />
                </>
            ))}
        </div>
    );
};
const KPI = () => {
    const [file, setFile] = useState({});
    const { state } = useLocation();
    const { documentName } = state;
    const outputDocumentName = `${removeExtension(documentName)}.pdf`;

    const { id: projectId, documentId } = useParams();
    const childRef: any = useRef();

    const navigate = useNavigate();

    const [inputFile, fetchInputFile] = useApi({
        url: `/api/project/${projectId}/files/download/type/rendered_files/${outputDocumentName}`,
        method: "get",
        data: null,
        responseType: "blob",
    });

    const [response, getKPIData] = useApi({
        url: `/api/project/${projectId}/documents/kpi/${documentId}`,
        method: "get",
    });

    const onNavigate = (pageNumber: number) => {
        childRef?.current?.changePage(pageNumber);
    };

    const contextValue = useMemo(() => ({ onNavigate }), []);

    useEffect(() => {
        getKPIData();
        fetchInputFile();
    }, []);

    useEffect(() => {
        if (inputFile.data) {
            (async () => {
                const fileData = await inputFile.data.arrayBuffer();
                setFile(fileData);
            })();
        }
    }, [inputFile]);

    const [, updateKPI] = useApi({
        url: `/api/project/${projectId}/documents/kpi/${documentId}`,
        method: "put",
        data: response.data,
    });

    const onSave = () => {
        updateKPI();
    };

    return (
        <>
            <div className={styles.saveKPIChanges}>
                <div
                    className={styles.backToSummary}
                    onClick={() => navigate(-1)}
                >
                    <Icon
                        fill="#2251FF"
                        size={12}
                        type={TYPE_GLYPH}
                        name="arrow-left"
                    />
                    <span>Back to Summary</span>
                </div>
                <Button
                    className={styles.saveBtn}
                    appearance={SECONDARY_BUTTON}
                    onClick={onSave}
                    size="sm"
                >
                    Save Changes
                </Button>
            </div>
            <div className={styles.container}>
                <ShouldRender condition={response.data}>
                    <div className={styles.pdf}>
                        <PDFRenderer
                            ref={childRef}
                            src={file}
                            kpis={response?.data?.kpi?.kpis || []}
                            customRenderer={textHighlighter}
                        />
                    </div>
                </ShouldRender>
                <div className={styles.kpis}>
                    <ShouldRender condition={response && response.data}>
                        <DataContext.Provider value={contextValue}>
                            <KPIDetails response={response} />
                        </DataContext.Provider>
                    </ShouldRender>
                </div>
            </div>
        </>
    );
};

export default KPI;
