import React from "react";

import KeyValue from "../../../components/KeyValue";
import { DocumentStatus } from "../../../utils/types";
import styles from "./ExtractionSummary.module.scss";

interface SummaryItem {
    status: number;
    count: number;
}

interface Props {
    summary: SummaryItem[];
}

const ExtractionSummary = ({ summary }: Props) => {
    const transformDataIntoColumns = (data: SummaryItem[]) => {
        const chunkSize = 2;
        return data
            ? data
                  .map((e: SummaryItem, i: number) =>
                      i % chunkSize === 0 ? data.slice(i, i + chunkSize) : null,
                  )
                  .filter((e) => e)
            : [];
    };

    const prepareData = (): (SummaryItem[] | null)[] => {
        if (!summary) return [];
        const keys = [
            DocumentStatus.Completed,
            DocumentStatus.Pending,
            DocumentStatus["Partially Completed"],
            DocumentStatus.Failed,
            DocumentStatus["In Progress"],
        ];
        const intialData: SummaryItem[] = [];
        keys.forEach((key) => intialData.push({ status: +key, count: 0 }));

        const data = intialData.map((item: SummaryItem) => {
            const dbValue = summary.find(
                (elem) => +elem.status === item.status,
            );
            return {
                status: item.status,
                count: dbValue?.count || 0,
            };
        });

        return transformDataIntoColumns(data);
    };

    const dataRows: (SummaryItem[] | null)[] = prepareData();

    return (
        <div className={styles.container}>
            {dataRows.map((data: SummaryItem[] | null) => (
                <div key={Math.random()} className={styles.col}>
                    {data?.map((item: SummaryItem) => (
                        <div className={styles.item} key={item.status}>
                            <KeyValue
                                label={DocumentStatus[item.status]}
                                value={item.count}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ExtractionSummary;
