import React, { useContext, useEffect, useState } from "react";

import {
    Breadcrumbs,
    Tabs,
    Tab,
    Tooltip,
    SUCCESS_ALERT_CONTEXTUAL,
    ERROR_ALERT_CONTEXTUAL,
} from "@mds/mds-reactjs-library";
import {
    Link,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";

import { ApplicationContext } from "../../App";
import Header from "../../components/Header";
import ShouldRender from "../../components/ShouldRender";
import { HIDE_LOADER } from "../../data/appConstants";
import { ProcessType, ProjectState, DocumentType } from "../../utils/types";
import useApi from "../../utils/useApi";
import styles from "./Analysis.module.scss";
import DocumentAnalysis from "./DocumentAnalysis";
import Entities from "./Entities";
import KPI from "./KPI";
import KPIDetail from "./KPI/Detail";
import QA from "./QA";
import Search from "./Search";
import SearchDetail from "./Search/Detail";

export default function Analysis() {
    const location = useLocation();
    const {
        projectName,
        projectId,
        totalExtractedFiles,
        projectSummary,
        documentType,
    } = location.state as ProjectState;

    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState("");
    const [pageSize] = useState(12);
    const [entityPageNumber, seEntityPageNumber] = useState(1);
    const [kpiPageNumber, setKpiPageNumber] = useState(1);
    const { dispatch } = useContext(ApplicationContext);
    const [resetCount, setResetCount] = useState(false);

    const [response, fetchEntities] = useApi({
        url: `/api/project/${projectId}/documents/entities/page/${entityPageNumber}/limit/${pageSize}`,
        method: "get",
        data: null,
    });

    const [kpiResponse, fetchKPIs] = useApi({
        url: `/api/project/${projectId}/documents/kpi/page/${kpiPageNumber}/limit/${pageSize}`,
        method: "get",
        data: null,
    });

    useEffect(() => {
        const path = location.pathname.split("/");
        const lastFrag = path[path.length - 1];
        if (lastFrag === "qa") {
            setSelectedTab("qa");
            return;
        }
        const lastSecFrag = path[path.length - 2];
        if ([lastFrag, lastSecFrag].includes("entity-extraction")) {
            setSelectedTab("entity-extraction");
            return;
        }
        if ([lastFrag, lastSecFrag].includes("kpi")) {
            setSelectedTab("kpi");
        }
    }, []);

    useEffect(() => {
        setResetCount(false);
        if (selectedTab === "entity-extraction") {
            fetchEntities();
        }
    }, [entityPageNumber, selectedTab, location.pathname]);

    useEffect(() => {
        if (selectedTab === "kpi") {
            fetchKPIs();
        }
    }, [kpiPageNumber, selectedTab, location.pathname]);

    const [disable, setDisable] = useState(false);
    const [processApiResponse, callToProcessApi] = useApi({
        url: `/api/project/${projectId}/process/${ProcessType.EntityExtraction}/status`,
        method: "get",
    });

    const { isSuccessful, err, data } = processApiResponse;
    useEffect(() => {
        callToProcessApi();
        if (isSuccessful && data.length > 0) {
            setDisable(true);
        } else if (err?.response?.status === 404) {
            setDisable(false);
        }
    }, []);

    const [entityExtractionApiResponse, callToEntityExtractionApi] = useApi({
        url: `/api/project/${projectId}/documents/entityExtraction/run`,
        method: "post",
        headers: {
            Authorization: `Bearer ${sessionStorage.getItem(
                "_mid-access-token",
            )}`,
        },
    });

    const extractEntity = () => {
        setResetCount(true);
        try {
            callToEntityExtractionApi();

            dispatch({
                type: "SHOW_NOTIFICATION",
                payload: [
                    {
                        message:
                            "Entity Extraction has been triggered and will run on the latest model.",
                        type: SUCCESS_ALERT_CONTEXTUAL,
                    },
                ],
            });

            dispatch({ type: HIDE_LOADER });
        } catch (error) {
            dispatch({ type: HIDE_LOADER });
            dispatch({
                type: "SHOW_NOTIFICATION",
                payload: [
                    {
                        message: "Something went wrong, please try again.",
                        type: ERROR_ALERT_CONTEXTUAL,
                    },
                ],
            });
        }
    };

    const getHeaderTextForEntityExtraction = () => {
        if (resetCount) {
            return "Entity Extraction";
        }
        return response.data?.metadata?.totalRecords
            ? `Entity Extraction (${response.data.metadata.totalRecords})`
            : "Entity Extraction";
    };

    const getHeaderTextForKPIs = () =>
        kpiResponse.data?.metadata?.totalRecords
            ? `KPI (${kpiResponse.data.metadata.totalRecords})`
            : "KPI";

    const getBreadcrumbText = () => {
        const errorDocCount = projectSummary?.docsWithErrors;
        let validDocs = errorDocCount
            ? totalExtractedFiles - errorDocCount
            : totalExtractedFiles;
        validDocs = validDocs < 0 ? 0 : validDocs;

        if (documentType?.toLowerCase() === DocumentType.INVOICE) {
            return getHeaderTextForKPIs();
        }
        if (selectedTab === "") {
            return validDocs ? `Search (${validDocs})` : "Search";
        }
        if (selectedTab === "entity-extraction") {
            return getHeaderTextForEntityExtraction();
        }
        if (selectedTab === "kpi") {
            return getHeaderTextForKPIs();
        }
        return "Q&A";
    };

    const { data: kpiData } = kpiResponse;

    useEffect(() => {
        if (documentType?.toLowerCase() === DocumentType.INVOICE) {
            setSelectedTab("kpi");
        }
    }, [documentType]);

    return (
        <div className={styles.analysisPage}>
            <section>
                <Header
                    heading={
                        <Breadcrumbs>
                            <Link to="/projects">Projects</Link>
                            <Link
                                to={`/projects/${projectId}/documents/processed`}
                                state={{
                                    ...(location.state as object),
                                    projectId,
                                    projectName,
                                }}
                            >
                                {projectName}
                            </Link>
                            <Link to=".">{getBreadcrumbText()}</Link>
                        </Breadcrumbs>
                    }
                />
            </section>
            <ShouldRender condition={!!documentType}>
                <>
                    <div className={styles.tabStyling}>
                        <Tabs
                            selectedValue={selectedTab}
                            onChange={(val: string) => {
                                navigate(val, {
                                    state: {
                                        ...(location.state as object),
                                        projectName,
                                        projectId,
                                        totalExtractedFiles,
                                    },
                                });
                                setSelectedTab(val);
                            }}
                        >
                            <Tab
                                label="Search"
                                value=""
                                className={styles.heading}
                                disabled={
                                    documentType?.toLowerCase() ===
                                    DocumentType.INVOICE
                                }
                            />
                            <Tab
                                label={
                                    <Tooltip
                                        content="Entity Extraction identifies key elements in the file
                        and classifies them into predefined categories."
                                        dark={undefined}
                                        trigger="mouseenter"
                                        placement="top"
                                        maxWidth={200}
                                    >
                                        <div>Entity extraction </div>
                                    </Tooltip>
                                }
                                value="entity-extraction"
                                disabled={
                                    documentType?.toLowerCase() ===
                                    DocumentType.INVOICE
                                }
                                className={styles.heading}
                            />
                            <Tab
                                label={
                                    <Tooltip
                                        content="Q & A description"
                                        dark={undefined}
                                        trigger="mouseenter"
                                        placement="top"
                                        maxWidth={200}
                                    >
                                        <div>Q&A</div>
                                    </Tooltip>
                                }
                                value="qa"
                                disabled={
                                    documentType?.toLowerCase() ===
                                    DocumentType.INVOICE
                                }
                                className={styles.heading}
                            />
                            <Tab
                                label={
                                    <Tooltip
                                        content="KPI"
                                        dark={undefined}
                                        trigger="mouseenter"
                                        placement="top"
                                        maxWidth={200}
                                    >
                                        <div>KPI</div>
                                    </Tooltip>
                                }
                                value="kpi"
                                className={styles.heading}
                            />
                        </Tabs>
                    </div>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <>
                                    <ShouldRender
                                        condition={
                                            !!(
                                                documentType?.toLowerCase() ===
                                                DocumentType.INVOICE
                                            ) && !!kpiResponse?.data
                                        }
                                    >
                                        <KPI
                                            projectId={projectId}
                                            projectName={projectName}
                                            results={kpiData || {}}
                                            page={kpiPageNumber}
                                            limit={pageSize}
                                            onChange={(selectedPage: number) =>
                                                setKpiPageNumber(selectedPage)
                                            }
                                        />
                                    </ShouldRender>
                                    <ShouldRender
                                        condition={
                                            !(
                                                documentType?.toLowerCase() ===
                                                DocumentType.INVOICE
                                            )
                                        }
                                    >
                                        <Search
                                            projectName={projectName}
                                            projectId={projectId}
                                        />
                                    </ShouldRender>
                                </>
                            }
                        />
                        <Route
                            path="entity-extraction"
                            element={
                                <ShouldRender condition={!!response?.data}>
                                    <Entities
                                        projectId={projectId}
                                        projectName={projectName}
                                        limit={pageSize}
                                        page={entityPageNumber}
                                        onChange={(selectedPage: number) =>
                                            seEntityPageNumber(selectedPage)
                                        }
                                        dataLength={data?.length}
                                        results={response?.data || {}}
                                        triggerExtraction={extractEntity}
                                    />
                                </ShouldRender>
                            }
                        />
                        <Route path="qa" element={<QA />} />

                        <Route
                            path="entity-extraction/:documentId"
                            element={<DocumentAnalysis />}
                        />
                        <Route
                            path="kpi"
                            element={
                                <ShouldRender condition={!!kpiResponse?.data}>
                                    <KPI
                                        projectId={projectId}
                                        projectName={projectName}
                                        results={kpiData || {}}
                                        page={kpiPageNumber}
                                        limit={pageSize}
                                        onChange={(selectedPage: number) =>
                                            setKpiPageNumber(selectedPage)
                                        }
                                    />
                                </ShouldRender>
                            }
                        />
                        <Route path="kpi/:documentId" element={<KPIDetail />} />
                        <Route
                            path="search/detail"
                            element={<SearchDetail />}
                        />
                    </Routes>
                </>
            </ShouldRender>
        </div>
    );
}
