import React, { createContext, useMemo, useReducer } from "react";

import { ModalFactory } from "@mds/mds-reactjs-library";

import styles from "./App.module.scss";
import AppRoutes from "./AppRoutes";
import PageFooter from "./components/Footer";
import LeftNav from "./components/LeftNav";
import Loader from "./components/Loader";
import Notification from "./components/Notification";
import PageHeader from "./components/PageHeader";
import ShouldRender from "./components/ShouldRender";
import { initialState, reducer } from "./data/appReducer";

export const ApplicationContext = createContext<any>(null);

function App() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = useMemo(
        () => ({
            state,
            dispatch,
        }),
        [state],
    );

    return (
        <ApplicationContext.Provider value={value}>
            <ShouldRender condition={state.showLoader}>
                <Loader />
            </ShouldRender>
            <div className={styles.container}>
                <PageHeader />
                <section className={styles.content}>
                    <div className={styles.main}>
                        <LeftNav />
                        <div className={styles.rightSide}>
                            <AppRoutes />
                        </div>
                    </div>
                </section>
            </div>
            <section>
                <PageFooter />
            </section>
            <ModalFactory />
            <Notification />
        </ApplicationContext.Provider>
    );
}

export default App;
