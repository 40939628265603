import React, { useContext, useState } from "react";

import Outline24CAdd from "@mds/mds-icons/icons/svg/outline-24-c-add.svg";
import Outline24CDelete from "@mds/mds-icons/icons/svg/outline-24-c-delete.svg";
import Outline24CInfo from "@mds/mds-icons/icons/svg/outline-24-c-info.svg";
import {
    ALIGN_RIGHT,
    Button,
    ERROR_ALERT_CONTEXTUAL,
    Grid,
    Icon,
    Pagination,
    PRIMARY_BUTTON,
    SIZE_SMALL,
    Table,
    Tooltip,
    TYPE_OUTLINE,
} from "@mds/mds-reactjs-library";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

import { ApplicationContext } from "../../../App";
import { defaultEntities } from "../../../appConfig";
import Download from "../../../assets/Download.svg";
import ShouldRender from "../../../components/ShouldRender";
import { HIDE_LOADER, SHOW_LOADER } from "../../../data/appConstants";
import authUtils from "../../../utils/authUtils";
import callDownloadAPI, { downloadAttachment } from "../../../utils/fileUtils";
import { ProjectState } from "../../../utils/types";
import utils from "../../../utils/utils";
import styles from "./Entities.module.scss";

interface Entity {
    name: string;
    documentId: number;
    entities: [][];
}

interface EntitiesProps {
    projectId: number;
    projectName: string;
    page: number;
    limit: number;
    results: {
        data: Entity[];
        metadata: {
            totalPages: number;
            totalRecords: number;
        };
    };
    onChange: (page: number) => void;
    dataLength: number;
    triggerExtraction: () => void;
}

const EntityBadge = ({
    data,
    showColors,
}: {
    data: string;
    showColors: boolean;
}) => {
    const badgeStyles = {
        backgroundColor: showColors ? utils.getColorFromString(data) : "white",
        padding: "3px 8px 3px 8px",
        margin: "5px",
        borderRadius: "8px",
        opacity: 0.8,
        border: showColors ? "" : "1px solid black",
        fontSize: showColors ? "" : "12px",
    };

    return <span style={badgeStyles}>{data}</span>;
};

export const groupData = (data: string[][]) =>
    data?.reduce((prev: any, item: any) => {
        /* eslint-disable no-param-reassign */
        if (item in prev) prev[item[3]] += 1;
        else prev[item[3]] = 1;
        return prev;
    }, {});

const CountRenderer = ({ value }: { value: string[][] }) => {
    const groupedData = groupData(value);
    const count = Object.keys(groupedData || {}).length;
    return <span className={styles.entityCount}>{count}</span>;
};

const TextRenderWithTooltip = ({
    cellData,
    value,
}: {
    cellData: string;
    value: string;
}) => {
    const text = cellData || value;
    return (
        <div className={styles.textContainer}>
            <Tooltip content={text} dark={undefined}>
                <div className={styles.content}>{text}</div>
            </Tooltip>
        </div>
    );
};
const allowEntityExtraction = (users: []) =>
    authUtils.isSuperAdmin() || authUtils.isAdmin({ users });

export const EntityRenderer = ({
    value,
    showAll,
    showColors,
}: {
    value: string[][];
    showAll?: boolean;
    showColors: boolean;
}) => {
    const itemsToShow = showAll ? Infinity : 7;
    const groupedData = groupData(value);
    const keys = Object.keys(groupedData || {});
    keys.sort();
    const keysToDisplay = keys.slice(0, itemsToShow);
    const hiddenKeys = keys.slice(itemsToShow);
    return (
        <div>
            {keysToDisplay.map((val: string) => (
                <EntityBadge
                    key={`key-${Math.random()}`}
                    data={val}
                    showColors={showColors === undefined ? true : showColors}
                />
            ))}

            <ShouldRender condition={keys.length > itemsToShow}>
                <Tooltip dark={undefined} content={hiddenKeys.join(", ")}>
                    <span>+{keys.length - itemsToShow} more</span>
                </Tooltip>
            </ShouldRender>
        </div>
    );
};

EntityRenderer.defaultProps = {
    showAll: 7,
};

const Entities = ({
    results,
    page,
    limit,
    projectId,
    projectName,
    onChange,
    dataLength,
    triggerExtraction,
}: EntitiesProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { totalExtractedFiles, users } = location.state as ProjectState;
    const rows =
        results.data?.map((item: Entity, index: number) => {
            const { name, entities, documentId } = item;
            return {
                serialNo: (page - 1) * limit + index + 1,
                name,
                entities,
                count: entities?.length || 0,
                documentId,
            };
        }) || [];
    const [expand, setExpand] = useState(true);
    const [btnDisable, setBtnDisable] = useState(false);

    const columns = [
        {
            dataKey: "serialNo",
            label: "S. no",
            flexGrow: true,
            width: 85,
        },
        {
            dataKey: "name",
            label: "Document name",
            flexGrow: true,
            width: 600,
            CellRenderer: TextRenderWithTooltip,
        },
        {
            dataKey: "entities",
            textWrapping: true,
            label: "Entities",
            width: 850,
            flexGrow: true,
            CellRenderer: EntityRenderer,
        },
        {
            dataKey: "entities",
            textWrapping: true,
            label: "No.of entities",
            width: 220,
            flexGrow: true,
            align: ALIGN_RIGHT,
            CellRenderer: CountRenderer,
        },
    ];

    const { dispatch } = useContext(ApplicationContext);

    const download = async () => {
        try {
            dispatch({ type: SHOW_LOADER });
            const res = await callDownloadAPI(
                `/api/project/${projectId}/documents/entities/download`,
            );
            const filename = `${new Date()
                .toISOString()
                .slice(0, 10)}_${projectName}_EntityExtractionResults.csv`;
            downloadAttachment(res, filename);
            dispatch({ type: HIDE_LOADER });
        } catch (err) {
            dispatch({ type: HIDE_LOADER });
            dispatch({
                type: "SHOW_NOTIFICATION",
                payload: [
                    {
                        message: "Something went wrong, please try again.",
                        type: ERROR_ALERT_CONTEXTUAL,
                    },
                ],
            });
        }
    };

    const isNormalUser = !allowEntityExtraction(users || []);

    return (
        <Grid container className={styles.container}>
            <ShouldRender condition={results?.metadata?.totalRecords > 0}>
                <Grid item span={1} offset={10}>
                    <div
                        className={classNames({
                            [styles.extractBtnDisabled]:
                                !!dataLength &&
                                results?.metadata?.totalRecords === 0,
                            [styles.extractBtn]:
                                !dataLength &&
                                results?.metadata?.totalRecords > 0,
                            [styles.extractBtnPartialExtraction]:
                                dataLength > 0 &&
                                results?.metadata?.totalRecords > 0,
                        })}
                    >
                        <Tooltip
                            content={
                                isNormalUser
                                    ? "Contact DocIQ Team for Custom Entity Extraction."
                                    : "Trigger the entity extraction process if the model has been updated."
                            }
                            dark={undefined}
                            trigger="mouseenter"
                            maxWidth={250}
                        >
                            <div>
                                <Button
                                    appearance={PRIMARY_BUTTON}
                                    onClick={() => {
                                        setBtnDisable(true);
                                        triggerExtraction();
                                    }}
                                    disabled={
                                        isNormalUser ||
                                        !!btnDisable ||
                                        dataLength > 0
                                    }
                                    startIcon={
                                        <Icon
                                            size={24}
                                            type={TYPE_OUTLINE}
                                            src={Outline24CInfo}
                                            strokeWidth={2}
                                        />
                                    }
                                >
                                    <div className={styles.extractBtnText}>
                                        Extract
                                    </div>
                                </Button>
                            </div>
                        </Tooltip>
                    </div>
                </Grid>
            </ShouldRender>

            <ShouldRender condition={results?.metadata?.totalRecords > 0}>
                <Grid item span={1} offset={10}>
                    <Button
                        className={styles.reportBtn}
                        onClick={download}
                        size={SIZE_SMALL}
                        appearance={PRIMARY_BUTTON}
                        disabled={!!btnDisable || dataLength > 0}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_OUTLINE}
                                src={Download}
                            />
                        }
                    >
                        Report
                    </Button>
                </Grid>
            </ShouldRender>

            <Grid item span={12}>
                <ShouldRender
                    condition={
                        results?.metadata?.totalRecords > 0 && !btnDisable
                    }
                    fallback={<div className={styles.entityDisabled} />}
                >
                    <div className={styles.entities}>
                        <div className={styles.entitySet}>
                            <Tooltip
                                content="Default Entities"
                                dark={undefined}
                                trigger="mouseenter"
                                maxWidth={250}
                                placement="top-end"
                            >
                                <div
                                    data-testid="expand"
                                    className={styles.expand}
                                    onClick={() => {
                                        setExpand(!expand);
                                    }}
                                >
                                    <Icon
                                        type={TYPE_OUTLINE}
                                        src={
                                            expand
                                                ? Outline24CDelete
                                                : Outline24CAdd
                                        }
                                    />
                                </div>
                            </Tooltip>
                            <div
                                className={classNames({
                                    [styles.hideEntities]: !expand,
                                })}
                            >
                                <EntityRenderer
                                    value={defaultEntities}
                                    showAll={false}
                                    showColors={false}
                                />
                            </div>
                        </div>
                    </div>
                </ShouldRender>
                <ShouldRender
                    condition={
                        !btnDisable && results?.metadata?.totalRecords > 0
                    }
                    fallback={
                        <Table
                            dark={false}
                            striped
                            rowHeight={20}
                            rows={[]}
                            columns={columns}
                        />
                    }
                >
                    <Table
                        dark={false}
                        striped
                        rowHeight={40}
                        rows={rows}
                        columns={columns}
                        height="auto"
                        onRowClick={({ rowData }: any) => {
                            navigate(`${rowData.documentId}`, {
                                state: {
                                    ...(location.state as ProjectState),
                                    projectId,
                                    projectName,
                                    totalExtractedFiles,
                                    documentName: rowData.name,
                                },
                            });
                        }}
                    />
                </ShouldRender>
            </Grid>
            <Grid item span={12}>
                <ShouldRender
                    condition={
                        results?.metadata?.totalRecords === 0 &&
                        !btnDisable &&
                        !dataLength
                    }
                >
                    <div className={styles.noResults}>No entities found.</div>
                </ShouldRender>
                <ShouldRender condition={dataLength > 0 || !!btnDisable}>
                    <div className={styles.extractInProgressMsg}>
                        Entity extraction is in progress and results will be
                        shown once it is completed.
                    </div>
                </ShouldRender>
            </Grid>
            <Grid item span={12}>
                <ShouldRender
                    condition={results?.metadata?.totalPages > 1 && !btnDisable}
                >
                    <div className={styles.pagination}>
                        <Pagination
                            size={SIZE_SMALL}
                            selected={page}
                            total={results?.metadata?.totalPages}
                            onChange={onChange}
                        />
                    </div>
                </ShouldRender>
            </Grid>
        </Grid>
    );
};

export default Entities;
