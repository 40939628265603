import React, { useContext, useState } from "react";

import Outline16DataDownload from "@mds/mds-icons/icons/svg/outline-16-data-download.svg";
import {
    Button,
    SECONDARY_BUTTON,
    PRIMARY_BUTTON,
    TERTIARY_BUTTON,
    ButtonAppearanceType,
    SUCCESS_ALERT_CONTEXTUAL,
    ERROR_ALERT_CONTEXTUAL,
    Checkbox,
    Icon,
    Tooltip,
    TYPE_OUTLINE,
} from "@mds/mds-reactjs-library";
import axios from "axios";
import classNames from "classnames";
import { FileRejection, useDropzone } from "react-dropzone";

import { ApplicationContext } from "../../../App";
import { ALLOWED_FILE_TYPE, MAX_FILE_SIZE } from "../../../appConfig";
import ShouldRender from "../../../components/ShouldRender";
import { convertToCSV } from "../../../utils/fileUtils";
import formatBytes from "../../../utils/format";
import { getErrorCodes, getValidationMessage, labelWithCount } from "../helper";
import SelectedDocList from "./SelectedDocList";
import "./DocumentUploadModal.scss";
import UploadProgress from "./UploadProgress";

export interface FilesEntity extends FileRejection {
    id: number;
}
export interface ThresholdEntity {
    uploadThreshold: number;
    uploadCount: number;
}
interface Props {
    projectName: string;
    projectId: string;
    onClose: (isSuccess?: boolean) => void;
    docSummary: ThresholdEntity;
}

const transformData = (files: File[]) =>
    files.map((item) => ({ file: item, errors: [] }));

const getRemainingLimit = (uploadCount = 0, uploadThreshold = 0): string => {
    let limit = uploadThreshold - uploadCount;
    limit = limit < 0 ? 0 : limit;
    return `Upload Limit : ${limit} Left`;
};

export default function DocumentUploadModal({
    projectName,
    projectId,
    onClose,
    docSummary,
}: Props) {
    const [filesData, setFilesData] = useState<FilesEntity[]>([]);
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [currentFile, setCurrentFile] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const { uploadCount, uploadThreshold } = docSummary;
    const { dispatch } = useContext(ApplicationContext);

    const uploadLimit = uploadThreshold - uploadCount;
    const maxAllowedFiles = uploadLimit < 0 ? 0 : uploadLimit;
    const { getRootProps, getInputProps, open } = useDropzone({
        accept: ALLOWED_FILE_TYPE,
        maxFiles: maxAllowedFiles,
        maxSize: MAX_FILE_SIZE,
        noClick: true,
        onDrop: (accepted: File[], rejected: FileRejection[]) => {
            setFilesData((prev) => {
                const transformedData = transformData(accepted);
                const final = [...prev, ...transformedData, ...rejected].map(
                    (item, index) => ({ ...item, id: index + 1 }),
                );
                return final;
            });
        },
    });

    const { validData, invalidData } = filesData.reduce(
        (acc, { file, errors, id }) => {
            const prev = { ...acc };
            if (
                prev.index < maxAllowedFiles &&
                (!errors.length ||
                    (errors.length === 1 &&
                        errors[0].code === "too-many-files"))
            ) {
                prev.validData.push({ file, errors, id });
                prev.index += 1;
            } else {
                prev.invalidData.push({ file, errors, id });
            }
            return prev;
        },
        { validData: [], invalidData: [], index: 0 } as {
            validData: FilesEntity[];
            invalidData: FilesEntity[];
            index: number;
        },
    );

    const removeClicked = (id: number) => {
        const filteredData = filesData.filter(({ id: uid }) => id !== uid);
        setFilesData(filteredData);
    };
    const handleCheck = () => {
        setIsChecked(!isChecked);
    };
    const hasData = !!(validData.length || invalidData.length);

    const dispatchSuccessNotification = (count: number) => {
        dispatch({
            type: "SHOW_NOTIFICATION",
            payload: [
                {
                    message: `${count} Document${count > 1 ? "s" : ""} ${
                        count > 1 ? "have" : "has"
                    } been uploaded successfully and will be processed.`,
                    type: SUCCESS_ALERT_CONTEXTUAL,
                },
            ],
        });
    };

    const dispatchFailureNotification = () => {
        dispatch({
            type: "SHOW_NOTIFICATION",
            payload: [
                {
                    message:
                        "1 or more documents failed to upload. Please try again.",
                    type: ERROR_ALERT_CONTEXTUAL,
                },
            ],
        });
    };

    const onUploadProgress = (progressEvent: ProgressEvent) => {
        setProgress(
            Math.round((100 * progressEvent.loaded) / progressEvent.total),
        );
    };

    const uploadFiles = async () => {
        setIsLoading(true);
        const apiState = {
            success: 0,
            failed: 0,
            shouldCall: true,
        };
        for (let i = 0; i < validData.length; i += 1) {
            const { file } = validData[i];
            const formData = new FormData();
            const pos = i;
            const metadata = {
                lastModifiedDate: file.lastModified,
            };
            formData.append("files", file);
            formData.append("metadata", JSON.stringify(metadata));
            setCurrent(i + 1);
            setCurrentFile(file.name);
            if (apiState.shouldCall) {
                const token = sessionStorage.getItem("_mid-access-token");
                // eslint-disable-next-line no-await-in-loop
                await axios({
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Authorization: `Bearer ${token}`,
                    },
                    url: `/api/project/${projectId}/documents/upload`,
                    method: "post",
                    data: formData,
                    onUploadProgress,
                })
                    .then(() => {
                        apiState.success += 1;
                    })
                    .catch(() => {
                        apiState.shouldCall = false;
                        apiState.failed = validData.length - pos;
                    });
            } else {
                break;
            }
        }
        setIsLoading(false);
        if (apiState.success) {
            dispatchSuccessNotification(apiState.success);
        }
        if (apiState.failed) {
            dispatchFailureNotification();
        }
        onClose(true);
    };

    const isUploadDisabled =
        validData.length === 0 ||
        (invalidData.length > 0 && validData.length > 0 && !isChecked);

    const btnConfig: {
        label: string;
        appearance: ButtonAppearanceType;
        disabled: boolean;
        onClick: React.MouseEventHandler;
    }[] = [
        {
            label: "Cancel",
            appearance: TERTIARY_BUTTON,
            disabled: false,
            onClick: () => {
                onClose();
            },
        },
        {
            label: "Upload",
            appearance: PRIMARY_BUTTON,
            disabled: isUploadDisabled,
            onClick: uploadFiles,
        },
    ];

    const fileErrorCodes = getErrorCodes(invalidData);

    return (
        <>
            <ShouldRender condition={isLoading}>
                <UploadProgress
                    current={current}
                    total={validData.length}
                    currentFile={currentFile}
                    progress={progress}
                />
            </ShouldRender>
            <div className="doc-upload-modal">
                <h3 className="modal-heading">
                    {labelWithCount("Upload documents", filesData.length)}
                </h3>
                <section className="body">
                    <div
                        {...getRootProps({
                            className: classNames("drag-drop-container", {
                                "no-files": !hasData,
                            }),
                        })}
                    >
                        <input {...getInputProps()} />
                        {hasData ? (
                            <SelectedDocList
                                validFiles={validData}
                                invalidFiles={invalidData}
                                onRemove={(id) => removeClicked(id)}
                            />
                        ) : (
                            <p>Drag and drop documents here to upload</p>
                        )}
                    </div>

                    <div>
                        <Button
                            appearance={SECONDARY_BUTTON}
                            className="btn-upload"
                            onClick={open}
                        >
                            Select files
                        </Button>
                    </div>

                    <div className="validation-info">
                        <p className="message">
                            {`Max file size : ${formatBytes(
                                MAX_FILE_SIZE,
                                0,
                            )} | ${getRemainingLimit(
                                uploadCount,
                                uploadThreshold,
                            )} | File types allowed : jpg, jpeg, png, pdf`}
                        </p>
                        <p className="message">
                            Multiple files uploaded with the same name will be
                            overwritten with the latest upload
                        </p>
                    </div>

                    <ShouldRender
                        condition={!!invalidData.length || !maxAllowedFiles}
                    >
                        <p className="error-message">
                            {maxAllowedFiles
                                ? getValidationMessage(
                                      fileErrorCodes,
                                      "upload-docs",
                                      docSummary,
                                      filesData.length,
                                  )
                                : "You have reached the max limit of upload, Please reach out to DocIQ Team for further assistance !"}
                        </p>
                    </ShouldRender>

                    <ShouldRender
                        condition={!!validData.length && !!invalidData.length}
                    >
                        <div className="checkbox">
                            <Checkbox
                                value="default"
                                label="Skip error files and proceed"
                                onChange={handleCheck}
                                checked={isChecked}
                            />
                            <Tooltip
                                dark={undefined}
                                enabled={false}
                                content="Download list of invalid files"
                            >
                                <button
                                    type="button"
                                    className="download"
                                    onClick={() => {
                                        convertToCSV(invalidData, projectName);
                                    }}
                                >
                                    <Icon
                                        size={12}
                                        type={TYPE_OUTLINE}
                                        src={Outline16DataDownload}
                                    />
                                </button>
                            </Tooltip>
                        </div>
                    </ShouldRender>
                </section>
                <section className="footer">
                    {btnConfig.map(
                        ({ label, onClick, disabled, appearance }) => (
                            <div key={label} className="btn-wrapper">
                                <Button
                                    appearance={appearance}
                                    disabled={disabled}
                                    onClick={onClick}
                                >
                                    {label}
                                </Button>
                            </div>
                        ),
                    )}
                </section>

                <section />
            </div>
        </>
    );
}
