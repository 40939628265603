import React, { useState } from "react";

import {
    Button,
    FormElementWrapper,
    Grid,
    Input,
    PRIMARY_BUTTON,
    SIZE_MEDIUM,
    SIZE_SMALL,
} from "@mds/mds-reactjs-library";
import { useLocation, useParams } from "react-router-dom";

import { ProjectState } from "../../../utils/types";
import useApi from "../../../utils/useApi";
import styles from "./QA.module.scss";
import QADetails from "./QADetails";

const QA = () => {
    const params = useParams();
    const location = useLocation();
    const { id: projectId } = params;
    const { language } = location.state as ProjectState;

    const [que, setQue] = useState("");

    const [config] = useState({
        ansLimit: 30,
        docLimit: 10,
        confidence: 50,
    });
    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setQue(evt.target.value);
    };

    const [qna, callToQna] = useApi({
        url: "/qa",
        method: "post",
        headers: {
            Authorization: sessionStorage.getItem("_mid-access-token"),
        },
    });

    const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (que) {
            callToQna({
                data: {
                    pipeline: "qa",
                    top_responses: config.ansLimit,
                    top_docs: config.docLimit,
                    query: que,
                    projectid: projectId,
                    confidence: config.confidence,
                    language,
                },
            });
        }
    };

    return (
        <div className={styles.container}>
            <form onSubmit={handleSearch} className={styles.form}>
                <Grid container>
                    <Grid item span={6} className={styles.input}>
                        <FormElementWrapper
                            inputProps={{
                                name: "search",
                                placeholder: "Write your question here.",
                                onChange: handleChange,
                                autoFocus: true,
                            }}
                            size={SIZE_MEDIUM}
                            component={Input}
                            label="Question"
                        />
                    </Grid>
                    <Grid item span={3} className={styles.button}>
                        <Button
                            disabled={!que}
                            type="submit"
                            size={SIZE_SMALL}
                            appearance={PRIMARY_BUTTON}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>

            <div className={styles.results}>
                <QADetails data={qna.data} error={qna.err} />
            </div>
        </div>
    );
};

export default QA;
