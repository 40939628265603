import React, { KeyboardEvent, useEffect, useState } from "react";

import {
    FormElementWrapper,
    Input,
    SIZE_MEDIUM,
} from "@mds/mds-reactjs-library";
import classNames from "classnames";

import Chip from "../../../../components/Chip";
import Error from "../../../../components/Error";
import ShouldRender from "../../../../components/ShouldRender";
import { isValid } from "../../../../utils/format";
import styles from "./InputWithChips.module.scss";

interface Props {
    onChange: (val: string[]) => void;
    onInputChange: (val: string) => void;
    defaultValues?: string[];
}
const InputWithChips: React.FC<Props> = ({
    onChange,
    onInputChange,
    defaultValues,
}) => {
    const [chips, setChips] = useState<string[]>(defaultValues as string[]);
    const [email, setEmail] = useState("");
    const [emailErr, setEmailErr] = useState("");

    const convertToChip = () => {
        const value = email.toLowerCase().trim();

        const error = value ? isValid(value, chips) : "";
        setEmailErr(error);
        if (!error && value) {
            setEmail("");
            setChips((prev) => [...prev, value]);
        }
    };

    const onKeyPress = (evt: KeyboardEvent) => {
        if (["Enter", "Tab", ",", " "].includes(evt.key)) {
            evt.preventDefault();
            convertToChip();
        }
    };

    const onBlur = (evt: any) => {
        evt.preventDefault();
        convertToChip();
    };

    const handleDelete = (emailId: string) => {
        setChips((prev) =>
            prev.filter((value) => value !== emailId.toLowerCase()),
        );
    };

    useEffect(() => {
        onChange(chips);
    }, [chips]);

    useEffect(() => {
        onInputChange(emailErr);
    }, [emailErr]);

    return (
        <div>
            <div className={classNames([styles.container, "email"])}>
                {chips.map((label) => (
                    <Chip
                        key={label}
                        label={label}
                        onRemove={() => {
                            handleDelete(label);
                        }}
                    />
                ))}

                <div>
                    <FormElementWrapper
                        size={SIZE_MEDIUM}
                        component={Input}
                        inputProps={{
                            placeholder: "name@mail.com,name2@mail.com",
                            name: "projectMembers",
                            onKeyPress,
                            value: email,
                            onChange: (evt: any) => {
                                setEmail(evt.target.value.toLowerCase());
                                setEmailErr("");
                            },
                            onBlur,
                        }}
                    />
                </div>
            </div>
            <ShouldRender condition={!!emailErr}>
                <div className={styles.errorMsgSpace}>
                    <Error fieldErr={emailErr} />
                </div>
            </ShouldRender>
        </div>
    );
};
InputWithChips.defaultProps = { defaultValues: [] };
export default InputWithChips;
