import React from "react";

import { Icon, Tooltip, TYPE_OUTLINE } from "@mds/mds-reactjs-library";
import classNames from "classnames";
import { NavigateFunction } from "react-router-dom";

import Delete from "../../../assets/Delete.svg";
import Edit from "../../../assets/Edit.svg";
import ShouldRender from "../../../components/ShouldRender";
import { ProjectStatus, RowEntity, TableEntity } from "../../../utils/types";
import styles from "./ProjectList.module.scss";

export const projectCellRenderer = (
    tableEntity: TableEntity,
    navigate: NavigateFunction,
    text: string,
) => {
    const { id, name, status, users, language, documentType } =
        tableEntity.rowData;

    return (
        <ShouldRender
            condition={+status !== ProjectStatus.Closed}
            fallback={<div className={styles.inactiveProject}>{text}</div>}
        >
            <div
                className={styles.projectNamePointer}
                onClick={() => {
                    navigate(`${id}/documents/processed`, {
                        state: {
                            projectName: name,
                            users,
                            language,
                            documentType,
                        },
                    });
                }}
            >
                <div className={styles.toolTip}>
                    <Tooltip content={text} dark={undefined} placement="top">
                        <div className={styles.tableField}>{text}</div>
                    </Tooltip>
                </div>
            </div>
        </ShouldRender>
    );
};

export const cellRenderer = (
    tableEntity: TableEntity,
    showDocModal: (rowData: RowEntity, mode: string) => void,
    canEditOrDelete: boolean,
) => (
    <ShouldRender
        condition={+tableEntity.rowData.status !== ProjectStatus.Closed}
    >
        <div
            className={classNames("project-list-actions", {
                isAdmin: canEditOrDelete,
            })}
        >
            <button
                type="button"
                className="action"
                onClick={() => {
                    showDocModal(tableEntity.rowData, "edit");
                }}
            >
                <Icon size={12} type={TYPE_OUTLINE} src={Edit} />
            </button>
            <button
                type="button"
                className="action"
                onClick={() => {
                    showDocModal(tableEntity.rowData, "delete");
                }}
            >
                <Icon size={12} type={TYPE_OUTLINE} src={Delete} />
            </button>
        </div>
    </ShouldRender>
);
export default projectCellRenderer;
