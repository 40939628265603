import React from "react";

import Glyph12Warning from "@mds/mds-icons/icons/svg/glyph-12-warning.svg";
import { Icon, TYPE_OUTLINE } from "@mds/mds-reactjs-library";

import styles from "./DeleteDocModal.module.scss";

const DeleteDocModal = () => (
    <div className={styles.container}>
        <p className={styles.msg}>
            Selected documents and their Extracted data will be deleted
            permanently.
        </p>
        <p className={styles.warning}>
            <span className={styles.icon}>
                <Icon size={8} type={TYPE_OUTLINE} src={Glyph12Warning} />
            </span>
            This action cannot be undone.
        </p>
    </div>
);

export default DeleteDocModal;
