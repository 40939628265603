import React from "react";

import { Icon, Table, TYPE_OUTLINE } from "@mds/mds-reactjs-library";
import { useNavigate } from "react-router-dom";

import emptyBox from "../../../assets/emptyBox.svg";
import ShouldRender from "../../../components/ShouldRender";
import { ButtonConfig, RowEntity } from "../../../utils/types";
import ActionItems from "../../Project/ActionItems";
import projectListColumnConfig from "../helper";
import styles from "./ProjectList.module.scss";

const ProjectList = ({
    data,
    btnConfig,
    showDocModal,
}: {
    data: [];
    btnConfig: ButtonConfig[];
    showDocModal: (rowData?: RowEntity, mode?: string) => void;
}) => {
    const navigate = useNavigate();

    return (
        <ShouldRender
            condition={!!data.length}
            fallback={
                <div className={styles.noResult}>
                    <Icon size={12} type={TYPE_OUTLINE} src={emptyBox} />
                    <p className={styles.info}>
                        You don&apos;t have any projects
                    </p>
                    <ActionItems config={btnConfig} />
                </div>
            }
        >
            <Table
                dark={false}
                height="auto"
                rowHeight={40}
                columns={projectListColumnConfig(showDocModal, navigate)}
                rows={data}
            />
        </ShouldRender>
    );
};
export default ProjectList;
