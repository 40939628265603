import React from "react";

import { Icon, TYPE_OUTLINE } from "@mds/mds-reactjs-library";

import ErrorIcon from "../../assets/Error.svg";
import styles from "./ErrorMessage.module.scss";

interface Props {
    message?: string;
}
const ErrorMessage: React.FC<Props> = ({ message }) => (
    <div className={styles.container}>
        <Icon size={8} type={TYPE_OUTLINE} src={ErrorIcon} />
        <span className={styles.message}>{message}</span>
    </div>
);

ErrorMessage.defaultProps = {
    message: "Something went wrong. Please try again.",
};

export default ErrorMessage;
