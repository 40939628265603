import { NavigateFunction } from "react-router-dom";

import authUtils from "../../utils/authUtils";
import { defaultDateFormat } from "../../utils/format";
import { Language, RowEntity, TableEntity } from "../../utils/types";
import projectCellRenderer, { cellRenderer } from "./ProjectList/helper";

const canEditAndDelete = (data: TableEntity) =>
    authUtils.isSuperAdmin() ||
    authUtils.isAdmin(data?.rowData) ||
    authUtils.isOwner(data?.rowData);

const projectListColumnConfig = (
    showDocModal: (rowData?: RowEntity, mode?: string) => void,
    navigate: NavigateFunction,
) => [
    {
        dataKey: "name",
        label: "Project Name",
        width: 250,
        flexGrow: true,
        CellRenderer: (data: TableEntity) =>
            projectCellRenderer(data, navigate, data.rowData.name),
    },
    {
        dataKey: "language",
        label: "Language",
        width: 100,
        flexGrow: true,
        CellRenderer: (data: TableEntity) => {
            const langCode: string = data.rowData.language;
            const lang = Language[langCode as keyof typeof Language] || "-";
            return projectCellRenderer(data, navigate, lang);
        },
    },
    {
        dataKey: "documentType",
        label: "Document Type",
        width: 120,
        flexGrow: true,
        CellRenderer: (data: TableEntity) =>
            projectCellRenderer(data, navigate, data.rowData.documentType),
    },
    {
        dataKey: "createdDate",
        label: "Created On",
        width: 100,
        flexGrow: true,
        CellRenderer: (data: TableEntity) =>
            projectCellRenderer(
                data,
                navigate,
                defaultDateFormat(data.rowData.createdDate),
            ),
    },
    {
        dataKey: "createdBy",
        label: "Created By",
        width: 100,
        flexGrow: true,
        CellRenderer: (data: TableEntity) =>
            projectCellRenderer(data, navigate, data.rowData.createdBy),
    },
    {
        dataKey: "editDelete",
        label: "",
        width: 150,
        CellRenderer: (data: TableEntity) =>
            cellRenderer(data, showDocModal, canEditAndDelete(data)),
    },
];

export default projectListColumnConfig;
