import React from "react";

import { Icon, TYPE_OUTLINE } from "@mds/mds-reactjs-library";

import briefcase from "../../assets/briefcase.svg";
import styles from "./LeftNav.module.scss";

const LeftNav = () => (
    <div className={styles.leftNav}>
        <Icon size={12} type={TYPE_OUTLINE} src={briefcase} />
    </div>
);

export default LeftNav;
