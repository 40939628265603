import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import Glyph16ZoomIn from "@mds/mds-icons/icons/svg/glyph-16-zoom-in.svg";
import Glyph16ZoomOut from "@mds/mds-icons/icons/svg/glyph-16-zoom-out.svg";
import {
    Button,
    Icon,
    SIZE_SMALL,
    TERTIARY_BUTTON,
    TYPE_GLYPH,
} from "@mds/mds-reactjs-library";
import { Document, Page, pdfjs } from "react-pdf";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ShouldRender from "../../../../components/ShouldRender";
import { removeExtension } from "../../../../utils/fileUtils";
import useApi from "../../../../utils/useApi";
import styles from "./SearchDetail.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ButtonStyled = styled(Button)`
    margin: 10px;
    width: 32px;
    height: 32px;
    min-height: 32px !important;
    min-width: 32px !important;
`;

const PDFRenderer = ({ src, pages }: { src: any; pages: number[] }) => {
    const [scale, setScale] = useState(1.25);
    const [page, setPage] = useState(1);
    const [pageIndex, setPageIndex] = useState(0);

    const next = () => {
        setPage(pages[pageIndex + 1]);
        setPageIndex(pageIndex + 1);
    };

    const prev = () => {
        setPage(pages[pageIndex - 1]);
        setPageIndex(pageIndex - 1);
    };

    const onZoom = () => {
        if (scale < 2) setScale(scale + 0.25);
    };

    const onZoomout = () => {
        if (scale > 0.25) setScale(scale - 0.25);
    };

    const onDocLoaded = (evt: any) => {
        console.log(evt.numPages);
    };

    return (
        <div>
            <div>
                <ButtonStyled
                    disabled={pageIndex === 0}
                    size={SIZE_SMALL}
                    onClick={prev}
                    appearance={TERTIARY_BUTTON}
                    startIcon={
                        <Icon size={16} type={TYPE_GLYPH} name="left-arrow" />
                    }
                />

                <b style={{ marginTop: "15px", marginLeft: "5px" }}>{page}</b>

                <ButtonStyled
                    disabled={pageIndex === pages.length - 1}
                    size={SIZE_SMALL}
                    onClick={next}
                    appearance={TERTIARY_BUTTON}
                    startIcon={
                        <Icon size={16} type={TYPE_GLYPH} name="right-arrow" />
                    }
                />

                <ButtonStyled
                    className="btn"
                    size={SIZE_SMALL}
                    appearance={TERTIARY_BUTTON}
                    onClick={onZoomout}
                    disabled={scale === 0.25}
                    startIcon={
                        <Icon
                            size={16}
                            type={TYPE_GLYPH}
                            name="search-zoom-out"
                            src={Glyph16ZoomOut}
                        />
                    }
                />

                <b style={{ marginTop: "15px", marginLeft: "5px" }}>
                    {scale * 100} %
                </b>

                <ButtonStyled
                    size={SIZE_SMALL}
                    appearance={TERTIARY_BUTTON}
                    onClick={onZoom}
                    disabled={scale === 2}
                    startIcon={
                        <Icon size={16} type={TYPE_GLYPH} src={Glyph16ZoomIn} />
                    }
                />
            </div>

            <Document file={src} onLoadSuccess={onDocLoaded}>
                <Page scale={scale} pageNumber={page} />
            </Document>
        </div>
    );
};

const SearchDetail = () => {
    const { state } = useLocation();
    const { id: projectId } = useParams();
    const { documentName, pages } = state;
    const outputDocumentName = `${removeExtension(documentName)}.pdf`;
    const inputPages = pages.split(",");
    const resultPages = inputPages.map((page: string) => +page.trim());

    const [pdf, setPDF] = useState({});
    const navigate = useNavigate();

    const [file, fetchFile] = useApi({
        url: `/api/project/${projectId}/files/download/type/rendered_files/${outputDocumentName}`,
        method: "get",
        data: null,
        responseType: "blob",
    });

    useEffect(() => {
        fetchFile();
    }, []);

    useEffect(() => {
        if (file.data) {
            (async () => {
                const fileData = await file.data.arrayBuffer();
                setPDF(fileData);
            })();
        }
    }, [file]);

    return (
        <div className={styles.container}>
            <div className={styles.backToSummary} onClick={() => navigate(-1)}>
                <Icon
                    fill="#2251FF"
                    size={12}
                    type={TYPE_GLYPH}
                    name="arrow-left"
                />
                <span>Back to Summary</span>
            </div>
            <ShouldRender condition={!!pdf}>
                <PDFRenderer src={pdf} pages={resultPages} />
            </ShouldRender>
        </div>
    );
};

export default SearchDetail;
