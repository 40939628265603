import React, { FC } from "react";

import Glyph12Warning from "@mds/mds-icons/icons/svg/glyph-12-warning.svg";
import Outline12CheckSingle from "@mds/mds-icons/icons/svg/outline-12-check-single.svg";
import Outline16Clock from "@mds/mds-icons/icons/svg/outline-16-clock.svg";
import Outline16Hourglass from "@mds/mds-icons/icons/svg/outline-16-hourglass.svg";
import Outline16Image from "@mds/mds-icons/icons/svg/outline-16-image.svg";
import Outline16Scan from "@mds/mds-icons/icons/svg/outline-16-scan.svg";
import Outline16SmallDown from "@mds/mds-icons/icons/svg/outline-16-small-down.svg";
import Outline16TimeClock from "@mds/mds-icons/icons/svg/outline-16-time-clock.svg";
import Outline24FilterRemove from "@mds/mds-icons/icons/svg/outline-24-filter-remove.svg";
import {
    Icon,
    TYPE_OUTLINE,
    Input,
    PopupMenu,
    Tooltip,
} from "@mds/mds-reactjs-library";
import { ErrorCode } from "react-dropzone";

import Outline16Pdf from "../../assets/pdf.svg";
import ShouldRender from "../../components/ShouldRender";
import FilterIcon from "../../components/SortIcon";
import {
    ColumnConfig,
    FilterEntity,
    HeaderMetaData,
    SortEntity,
} from "../../utils/types";
import { FilesEntity, ThresholdEntity } from "./DocumentUploadModal";
import styles from "./Project.module.scss";
import SelectWithCheckbox from "./SelectWithCheckbox";

export function getValidationMessage(
    errors: {
        type: number;
        limit: number;
        size: number;
    },
    modalType?: string,
    thresholdData?: ThresholdEntity,
    noOfDocs = 0,
) {
    let msg = "";
    let allowedLimit = 0;
    const { type, size, limit } = errors;

    if (type && modalType !== "model-upload")
        msg += "Invalid File types found, ";
    if (size) msg += "Size limit exceeds for few files, ";
    if (limit && modalType === "model-upload")
        msg += `No. of files exceeded, Remove ${limit} file${
            limit > 1 ? "s" : ""
        }. `;

    if (thresholdData) {
        allowedLimit =
            thresholdData.uploadThreshold - thresholdData.uploadCount;
    }
    if (
        modalType === "upload-docs" &&
        thresholdData &&
        noOfDocs > allowedLimit
    ) {
        const exceedLimit = noOfDocs - allowedLimit;
        msg += `No. of files exceeded the upload limit, Remove ${exceedLimit} file${
            exceedLimit > 1 ? "s" : ""
        }. `;
    }
    if (type && modalType === "model-upload") msg += "Invalid File type, ";
    return modalType === "model-upload"
        ? `${msg}Upload Valid file to proceed !`
        : `${msg}Resolve errors to proceed !`;
}

const HeaderWithSortIcon = (
    headerData: HeaderMetaData,
    sortData: SortEntity,
    onChange: (field: string, dir: string) => void,
) => {
    const { label, dataKey } = headerData;
    const { sort, sortDir } = sortData;
    const direction = sort === dataKey ? sortDir : "";

    return (
        <div className={styles.customHeader}>
            <span>{label}</span>
            <FilterIcon
                direction={direction}
                onChange={(data) => {
                    onChange(dataKey, data);
                }}
            />
        </div>
    );
};

const FilterAndSort = (
    headerData: HeaderMetaData,
    element: React.ReactNode,
    sortData: SortEntity,
    onChange: (field: string, value: string) => void,
) => {
    const { dataKey } = headerData;

    const { sort, sortDir } = sortData;
    const direction = sort === dataKey ? sortDir : "";
    return (
        <div className={styles.customHeader}>
            <span>{element}</span>
            <FilterIcon
                direction={direction}
                onChange={(data) => {
                    onChange(dataKey, data);
                }}
            />
        </div>
    );
};

const ExtractionStatus: FC<{ status: string }> = ({ status }) => {
    let icon = null;
    switch (status) {
        case "In Progress":
            icon = (
                <span className={styles.inprogressIcon}>
                    <Icon
                        type={TYPE_OUTLINE}
                        src={Outline16TimeClock}
                        size={16}
                    />
                </span>
            );
            break;
        case "Pending":
            icon = (
                <span className={styles.pendingIcon}>
                    <Icon
                        type={TYPE_OUTLINE}
                        src={Outline16Hourglass}
                        size={16}
                    />
                </span>
            );
            break;
        case "Completed":
            icon = (
                <span className={styles.successIcon}>
                    <Icon
                        type={TYPE_OUTLINE}
                        src={Outline12CheckSingle}
                        size={16}
                    />
                </span>
            );
            break;
        case "Partially Completed":
            icon = (
                <span className={styles.warningIcon}>
                    <Icon size={8} type={TYPE_OUTLINE} src={Glyph12Warning} />
                </span>
            );
            break;

        case "Failed":
            icon = (
                <span className={styles.errorIcon}>
                    <Icon size={8} type={TYPE_OUTLINE} src={Glyph12Warning} />
                </span>
            );
            break;
        default:
            icon = "";
            break;
    }
    return (
        <div className={styles.extractionStatus}>
            {icon}
            {status}
        </div>
    );
};

export const DocumentListColumnConfig = (
    sortData: SortEntity,
    onChange: (label: string, direction: string) => void,
    filterData: FilterEntity,
    onFilterChange: (field: string, value: string | number) => void,
    searchQuery: string,
    HeaderCheckboxCellRenderer: any,
    CheckboxCellRenderer: any,
    clearFilter: () => void,
) => {
    const renderHeader = (data: HeaderMetaData) =>
        HeaderWithSortIcon(data, sortData, onChange);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onFilterChange(event.target.name, event.target.value);
    };
    const StatusSelect = ({ closePopup }: any) => (
        <SelectWithCheckbox
            onFilterChange={onFilterChange}
            filterData={filterData}
            name="status"
            checkboxConfig={[
                { label: "Pending", value: 1 },
                { label: "In Progress", value: 2 },
                { label: "Partially Completed", value: 3 },
                { label: "Completed", value: 4 },
                { label: "Failed", value: 5 },
            ]}
            closePopup={closePopup}
        />
    );

    const showClearOption = !!searchQuery.length || !!filterData?.status.length;
    return [
        {
            dataKey: "checked",
            label: "",
            width: 65,
            HeaderRenderer: HeaderCheckboxCellRenderer,
            CellRenderer: CheckboxCellRenderer,
        },
        {
            dataKey: "serialNo",
            label: "S. no",
            width: 100,
        },
        {
            dataKey: "metadata",
            label: "",
            CellRenderer: ({ rowData }: HeaderMetaData) => (
                <div>
                    <ShouldRender
                        condition={
                            rowData.metadata?.filetype === "application/pdf"
                        }
                    >
                        <ShouldRender
                            fallback={
                                <Icon
                                    size={16}
                                    type={TYPE_OUTLINE}
                                    src={Outline16Pdf}
                                />
                            }
                            condition={rowData.metadata.isScanned}
                        >
                            <Icon
                                width={16}
                                size={8}
                                type={TYPE_OUTLINE}
                                src={Outline16Scan}
                            />
                        </ShouldRender>
                    </ShouldRender>
                    <ShouldRender
                        condition={rowData.metadata?.filetype?.startsWith(
                            "image",
                        )}
                    >
                        <Icon
                            size={16}
                            type={TYPE_OUTLINE}
                            src={Outline16Image}
                        />
                    </ShouldRender>
                </div>
            ),
        },
        {
            dataKey: "name",
            label: "Document name",
            width: 700,
            flexGrow: true,
            HeaderRenderer: (data: any) =>
                FilterAndSort(
                    data,
                    <Input
                        placeholder="Document name"
                        name="name"
                        value={searchQuery}
                        onChange={handleChange}
                    />,
                    sortData,
                    onChange,
                ),
            CellRenderer: ({ rowData, value }: HeaderMetaData) => (
                <div>
                    <b>{value}</b>
                    <ShouldRender condition={rowData.status === 2}>
                        <>
                            <progress value={rowData.currentStep} max={5} />
                            <span
                                style={{
                                    color: "grey",
                                    marginLeft: "5px",
                                    verticalAlign: "middle",
                                    fontSize: "9px",
                                }}
                            >
                                {Math.ceil((rowData.currentStep * 100) / 5)}%
                            </span>
                        </>
                    </ShouldRender>
                </div>
            ),
        },
        {
            dataKey: "createdDate",
            label: "Upload date",
            width: 200,
            HeaderRenderer: renderHeader,
        },
        { dataKey: "empty", label: "", width: 150 },
        {
            dataKey: "size",
            label: "Size",
            width: 180,
            HeaderRenderer: renderHeader,
        },
        { dataKey: "empty", label: "", width: 150 },
        {
            dataKey: "extractionStatus",
            label: "Extraction status",
            width: 400,
            flexGrow: true,
            HeaderRenderer: ({ label }: HeaderMetaData) => (
                <PopupMenu customContent={<StatusSelect />}>
                    <span className={styles.label}>{label}</span>
                    <span className={styles.downArrow}>
                        <Icon type={TYPE_OUTLINE} src={Outline16SmallDown} />
                    </span>
                </PopupMenu>
            ),
            CellRenderer: ({ rowData, value }: HeaderMetaData) => (
                <ShouldRender
                    condition={rowData?.error}
                    fallback={<ExtractionStatus status={value} />}
                >
                    <Tooltip
                        content={rowData?.error}
                        dark={undefined}
                        trigger="mouseenter"
                        placement="top"
                        maxWidth={200}
                    >
                        <div>
                            <ExtractionStatus status={value} />
                        </div>
                    </Tooltip>
                </ShouldRender>
            ),
        },
        {
            dataKey: "elapsed",
            label: "Elapsed",
            width: 120,
            CellRenderer: ({ rowData }: HeaderMetaData) => (
                <ShouldRender condition={rowData.status > 2 && rowData.elapsed}>
                    <div>
                        <Icon
                            size={16}
                            type={TYPE_OUTLINE}
                            src={Outline16Clock}
                        />
                        <span style={{ verticalAlign: "top", margin: "5px" }}>
                            {rowData.elapsed}
                        </span>
                    </div>
                </ShouldRender>
            ),
        },
        {
            dataKey: "clear",
            label: "",
            width: 180,
            HeaderRenderer: () => (
                <ShouldRender condition={showClearOption}>
                    <Tooltip dark={undefined} content="Clear all filters">
                        <div
                            className={styles.clearFilter}
                            onClick={clearFilter}
                            data-testid="clear-filter"
                        >
                            <Icon
                                type={TYPE_OUTLINE}
                                src={Outline24FilterRemove}
                            />
                        </div>
                    </Tooltip>
                </ShouldRender>
            ),
        },
    ];
};

export const ExtractedDocumentListColumnConfig = (
    sortData: SortEntity,
    onChange: (label: string, direction: string) => void,
    filterData: FilterEntity,
    onFilterChange: (field: string, value: string | number) => void,
    searchQuery: string,
    HeaderCheckboxCellRenderer: any,
    CheckboxCellRenderer: any,
    clearFilter: () => void,
    documentType: string,
): ColumnConfig[] => {
    const renderHeader = (data: HeaderMetaData) =>
        HeaderWithSortIcon(data, sortData, onChange);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onFilterChange(event.target.name, event.target.value);
    };

    const SelectExtractedData = ({ closePopup }: any) => (
        <SelectWithCheckbox
            onFilterChange={onFilterChange}
            filterData={filterData}
            name="table"
            checkboxConfig={[
                { label: "With Table", value: 1 },
                { label: "Without Table", value: 0 },
            ]}
            closePopup={closePopup}
        />
    );

    const SelectErrors = ({ closePopup }: any) => (
        <SelectWithCheckbox
            onFilterChange={onFilterChange}
            filterData={filterData}
            name="errors"
            checkboxConfig={[
                { label: "Language Mismatch", value: 1 },
                { label: "Low Confidence", value: 2 },
            ]}
            closePopup={closePopup}
        />
    );

    const hasFilters = !!Object.values(filterData).reduce((acc, item) => {
        if (item?.length) {
            return true;
        }
        return acc;
    }, false);
    const showClearOption = !!searchQuery.length || hasFilters;

    return [
        {
            dataKey: "checked",
            label: "",
            width: 65,
            HeaderRenderer: HeaderCheckboxCellRenderer,
            CellRenderer: CheckboxCellRenderer,
        },
        {
            dataKey: "serialNo",
            label: "S. no",
            width: 75,
        },

        {
            dataKey: "name",
            label: "Document name",
            width: 250,
            flexGrow: true,
            HeaderRenderer: (data: any) =>
                FilterAndSort(
                    data,
                    <Input
                        placeholder="Document name"
                        name="name"
                        value={searchQuery}
                        onChange={handleChange}
                    />,
                    sortData,
                    onChange,
                ),
        },
        { dataKey: "empty", label: "", width: 225 },
        {
            dataKey: "confidence",
            label: "Confidence percentage",
            width: 220,
            HeaderRenderer: renderHeader,
        },
        { dataKey: "empty", label: "", width: 60 },
        {
            dataKey: documentType === "Invoice" ? "" : "extractedData",
            label: documentType === "Invoice" ? "" : "Extracted data",
            width: 220,
            HeaderRenderer:
                documentType === "Invoice"
                    ? () => null
                    : ({ label }: HeaderMetaData) => (
                          <PopupMenu customContent={<SelectExtractedData />}>
                              <span className={styles.label}>{label}</span>
                              <span className={styles.downArrow}>
                                  <Icon
                                      type={TYPE_OUTLINE}
                                      src={Outline16SmallDown}
                                  />
                              </span>
                          </PopupMenu>
                      ),
        },

        { dataKey: "empty", label: "", width: 40 },
        {
            dataKey: "errors",
            label: "Errors",
            width: 180,
            flexGrow: true,
            HeaderRenderer: ({ label }: HeaderMetaData) => (
                <PopupMenu customContent={<SelectErrors />}>
                    <span className={styles.label}>{label}</span>
                    <span className={styles.downArrow}>
                        <Icon type={TYPE_OUTLINE} src={Outline16SmallDown} />
                    </span>
                </PopupMenu>
            ),
        },
        {
            dataKey: "clear",
            label: "",
            width: 180,
            HeaderRenderer: () => (
                <ShouldRender condition={showClearOption}>
                    <Tooltip dark={undefined} content="Clear all filters">
                        <div
                            className={styles.clearFilter}
                            onClick={clearFilter}
                            data-testid="clear-filter"
                        >
                            <Icon
                                type={TYPE_OUTLINE}
                                src={Outline24FilterRemove}
                            />
                        </div>
                    </Tooltip>
                </ShouldRender>
            ),
        },
    ];
};

export const labelWithCount = (label: string, count: number) =>
    count ? `${label} (${count})` : label;

export const getErrorCodes = (data: FilesEntity[]) =>
    data.reduce(
        (acc, { errors }) => {
            if (errors.find(({ code }) => code === ErrorCode.FileInvalidType)) {
                acc.type += 1;
            } else if (
                errors.find(({ code }) => code === ErrorCode.FileTooLarge)
            ) {
                acc.size += 1;
            } else {
                acc.limit += 1;
            }
            return acc;
        },
        { type: 0, limit: 0, size: 0 },
    );

export const transformParams = (data: any) => {
    let params = "";
    let hasParams = false;
    Object.keys(data).forEach((key) => {
        const value = data[key];
        if (Array.isArray(value) && value.length) {
            hasParams = true;
            const updatedValue = encodeURIComponent(JSON.stringify(value));
            params += params
                ? `&${key}=${updatedValue}`
                : `${key}=${updatedValue}`;
        } else if (!Array.isArray(value) && value) {
            hasParams = true;
            params += params ? `&${key}=${value}` : `${key}=${value}`;
        }
    });

    if (hasParams) {
        return `?${params}`;
    }
    return "";
};
