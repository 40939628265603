import React from "react";

import { Icon, TYPE_GLYPH } from "@mds/mds-reactjs-library";

import { FilesEntity } from "..";
import formatBytes from "../../../../utils/format";

interface Props {
    data: FilesEntity;
    onRemove: (uid: number) => void;
}
export default function DocumentDetailRow(props: Props) {
    const {
        data: {
            file: { name, size },
            id,
        },
        onRemove,
    } = props;
    return (
        <div className="row">
            <div className="name">{name}</div>
            <div className="size">
                {formatBytes(size)}
                <button
                    type="button"
                    className="remove"
                    onClick={() => {
                        onRemove(id);
                    }}
                >
                    <Icon size={12} type={TYPE_GLYPH} name="e-remove" />
                </button>
            </div>
        </div>
    );
}
