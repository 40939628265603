import React from "react";

import EmailOutline16 from "@mds/mds-icons/icons/svg/outline-16-email.svg";
import {
    Footer,
    CONDENSED_FOOTER,
    TYPE_OUTLINE,
    Icon,
} from "@mds/mds-reactjs-library";

import styles from "./Footer.module.scss";

const FooterIcon = () => <Icon src={EmailOutline16} type={TYPE_OUTLINE} />;

const PageFooter = () => (
    <div className={styles.container}>
        <Footer
            type={CONDENSED_FOOTER}
            footerLabel=""
            linksConfig={[
                {
                    label: "Send us feedback",
                    href: "mailTo:DocIQ@mckinsey.com",
                    icon: FooterIcon,
                },
            ]}
        />
    </div>
);
export default PageFooter;
