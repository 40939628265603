import React, { useState } from "react";

import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({
    src,
    scale,
    page,
    customRenderer,
    entities,
}: {
    src: any;
    scale: number;
    page: number;
    entities: string[][];
    customRenderer: (str: string, patterns: any[]) => any;
}) => {
    const [pages, setPages] = useState(0);

    const onDocLoaded = (evt: any) => {
        setPages(evt.numPages);
    };

    const textRenderer = (textItem: any) => {
        const patterns = entities.map((entity) => ({
            value: entity[0],
            key: entity[3],
        }));
        return customRenderer(textItem.str, patterns);
    };

    return (
        <div>
            <Document onLoadSuccess={onDocLoaded} file={src}>
                <Page
                    scale={scale}
                    pageNumber={page}
                    customTextRenderer={textRenderer}
                />
            </Document>
        </div>
    );
};

export default PDFViewer;
