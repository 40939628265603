import React from "react";

import Outline12DownArrow from "@mds/mds-icons/icons/svg/outline-12-down-arrow.svg";
import outline12UpArrow from "@mds/mds-icons/icons/svg/outline-12-up-arrow.svg";
import Outline16ArrowDown from "@mds/mds-icons/icons/svg/outline-16-arrow-down.svg";
import Outline16ArrowUp from "@mds/mds-icons/icons/svg/outline-16-arrow-up.svg";
import { Icon, TYPE_OUTLINE } from "@mds/mds-reactjs-library";

import { SortDirection } from "../../utils/types";
import styles from "./SortIcon.module.scss";

interface Props {
    direction: SortDirection | "";
    onChange: (dir: string) => void;
}

const { ASC, DESC } = SortDirection;
const SortIcon: React.FC<Props> = ({ direction, onChange }) => {
    let icon = null;
    switch (direction) {
        case ASC:
            icon = <Icon type={TYPE_OUTLINE} src={Outline16ArrowUp} />;
            break;
        case DESC:
            icon = <Icon type={TYPE_OUTLINE} src={Outline16ArrowDown} />;
            break;
        default:
            icon = (
                <>
                    <Icon type={TYPE_OUTLINE} src={outline12UpArrow} />
                    <Icon type={TYPE_OUTLINE} src={Outline12DownArrow} />
                </>
            );
            break;
    }

    return (
        <div
            role="button"
            data-testid="sort-icon"
            className={styles.container}
            onClick={() => onChange(direction)}
        >
            {icon}
        </div>
    );
};

export default SortIcon;
