import axios, { AxiosResponse } from "axios";
import moment from "moment";

import { MAX_FILE_SIZE } from "../appConfig";
import { FilesEntity } from "../pages/Project/DocumentUploadModal";
import formatBytes from "./format";

const callDownloadAPI = (url: string) => {
    const token = window?.sessionStorage.getItem("_mid-access-token");
    const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
    };

    return axios({
        method: "get",
        url,
        responseType: "blob",
        headers,
    });
};

export const downloadAttachment = (
    response: AxiosResponse<any, any>,
    filename: string,
) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
};

export const convertToCSV = (data: FilesEntity[], projectName: string) => {
    const fileData = data.map(({ file, errors }) => {
        const { name, size } = file;
        const msg = errors.map(({ code }) => {
            if (code === "file-too-large") {
                return `File is larger than ${formatBytes(MAX_FILE_SIZE)}`;
            }
            if (code === "file-invalid-type") {
                return "Invalid file type";
            }
            return "Upload limit exhausted";
        });
        return {
            "File name": name,
            Size: formatBytes(size),
            "Reason of failure": msg.length
                ? msg.join(". ")
                : "Upload limit exhausted",
        };
    });
    const array = [Object.keys(fileData[0])].concat(fileData as any);

    const csv = array.map((it) => Object.values(it).toString()).join("\n");
    const currentDate = moment().format("YYYY-MM-DD");
    const filename = `${currentDate}_${projectName}_InvalidFiles.csv`;
    const element = document.createElement("a");
    element.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
    element.target = "_blank";
    element.download = `${filename}`;
    element.click();
};

export const removeExtension = (filename: string) =>
    filename.substring(0, filename.lastIndexOf(".")) || filename;

export default callDownloadAPI;
