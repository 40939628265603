import React, { Component, ReactNode } from "react";

import { Link } from "react-router-dom";

interface Props {
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    public static getDerivedStateFromError(): State {
        return { hasError: true };
    }

    public componentDidCatch() {
        // log errors
    }

    public render() {
        const { hasError } = this.state;
        const { children } = this.props;

        if (hasError) {
            return (
                <div>
                    <h1>Something went wrong</h1>
                    <a href="/">Go Back</a>
                </div>
            );
        }

        return children;
    }
}

export default ErrorBoundary;
