import React, { useContext } from "react";

import {
    Checkbox,
    Grid,
    SIZE_SMALL,
    ThemeContext,
} from "@mds/mds-reactjs-library";

import styles from "./SelectWithCheckbox.module.scss";

interface Props {
    name: "table" | "errors" | "status";
    checkboxConfig: { value: number; label: string }[];
    closePopup: () => void | undefined;
    onFilterChange: (field: string, value: string | number) => void;
    filterData: { [key: string]: number[] };
}

const SelectWithCheckbox: React.FC<Props> = ({
    name,
    checkboxConfig,
    closePopup,
    onFilterChange,
    filterData,
}: Props) => {
    const theme = useContext(ThemeContext);

    const handleClick = (value: string) => {
        onFilterChange(name, value);
        closePopup();
    };

    return (
        <Grid
            container
            className={styles.container}
            direction="column"
            spacing={[theme.spacing.medium, theme.spacing.small]}
        >
            {checkboxConfig.map(({ label, value }) => (
                <Grid item key={label}>
                    <Checkbox
                        size={SIZE_SMALL}
                        value={value}
                        label={label}
                        checked={filterData[name].includes(value)}
                        onChange={handleClick}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default SelectWithCheckbox;
