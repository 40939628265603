import React, { useState } from "react";

import styled from "@emotion/styled";
import Glyph16ZoomIn from "@mds/mds-icons/icons/svg/glyph-16-zoom-in.svg";
import Glyph16ZoomOut from "@mds/mds-icons/icons/svg/glyph-16-zoom-out.svg";
import {
    Button,
    FormElementWrapper,
    Icon,
    Input,
    SIZE_SMALL,
    TERTIARY_BUTTON,
    TYPE_GLYPH,
} from "@mds/mds-reactjs-library";
import { Document, Page, pdfjs } from "react-pdf";

import ShouldRender from "../../../../../components/ShouldRender";
import styles from "./DocumentViewer.module.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ButtonStyled = styled(Button)`
    margin: 10px;
    width: 32px;
    height: 32px;
    min-height: 32px !important;
    min-width: 32px !important;
`;

const DocumentViewer2 = ({ src, type }: { src: string; type: string }) => {
    const [scale, setScale] = useState(1.0);
    const [current, setCurrent] = useState(1);
    const [pages, setPages] = useState(0);

    const onZoom = () => {
        if (scale < 4) setScale(scale + 0.25);
    };

    const onZoomout = () => {
        if (scale > 0.25) setScale(scale - 0.25);
    };

    const next = () => {
        if (current < pages) setCurrent(current + 1);
    };

    const prev = () => {
        if (current > 1) setCurrent(current - 1);
    };

    const onDocLoaded = (evt: any) => {
        setPages(evt.numPages);
    };

    const onPageChange = (evt: React.ChangeEvent<HTMLInputElement>) =>
        setCurrent(+evt.target.value);

    return (
        <ShouldRender
            condition={type === "application/pdf"}
            fallback={
                <img
                    alt="extracted images"
                    className={styles.imageFile}
                    src={src}
                />
            }
        >
            <>
                <div className={styles.labels}>
                    <ButtonStyled
                        size={SIZE_SMALL}
                        disabled={current === 1}
                        onClick={prev}
                        appearance={TERTIARY_BUTTON}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                name="left-arrow"
                            />
                        }
                    />
                    <FormElementWrapper
                        inputProps={{
                            width: "50px",
                            name: "search",
                            value: current,
                            onChange: onPageChange,
                        }}
                        size={SIZE_SMALL}
                        component={Input}
                    />
                    <b style={{ marginTop: "15px", marginLeft: "5px" }}>
                        {" "}
                        of {pages}
                    </b>

                    <ButtonStyled
                        size={SIZE_SMALL}
                        disabled={current === pages}
                        appearance={TERTIARY_BUTTON}
                        onClick={next}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                name="right-arrow"
                            />
                        }
                    />

                    <ButtonStyled
                        className="btn"
                        size={SIZE_SMALL}
                        appearance={TERTIARY_BUTTON}
                        onClick={onZoomout}
                        disabled={scale === 0.25}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                name="search-zoom-out"
                                src={Glyph16ZoomOut}
                            />
                        }
                    />

                    <b style={{ marginTop: "15px", marginLeft: "5px" }}>
                        {scale * 100} %
                    </b>

                    <ButtonStyled
                        size={SIZE_SMALL}
                        appearance={TERTIARY_BUTTON}
                        onClick={onZoom}
                        disabled={scale === 4}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                src={Glyph16ZoomIn}
                            />
                        }
                    />
                </div>
                <hr />
                <div>
                    <Document onLoadSuccess={onDocLoaded} file={src}>
                        <Page scale={scale} pageNumber={current} />
                    </Document>
                </div>
            </>
        </ShouldRender>
    );
};

export default DocumentViewer2;
