import { ButtonAppearanceType } from "@mds/mds-reactjs-library";

export interface Users {
    assignedTo: string;
    type: number;
}

export interface DropDownButtonObject {
    dropdownLabel: string;
    onClick: () => void;
}
export interface ButtonConfig {
    label: string;
    appearance?: ButtonAppearanceType;
    onClick?: () => void;
    disabled?: boolean;
    tooltip?: string;
    type?: string;
    dropdownListItem?: DropDownButtonObject[];
    users?: Users[];
    isVisible?: boolean;
}

export interface DocumentMeta {
    filetype?: string;
    lastModified?: Date;
    pages?: number;
    isScanned?: boolean;
}

export interface DocumentDetailEntity {
    id: number;
    name: string;
    size: number;
    status: number;
    createdDate: string;
    error: string;
    elapsed?: number;
    metadata?: DocumentMeta;
    currentStep?: number;
}

export interface ExtractedDocumentDetailEntity {
    id: number;
    documentId: number;
    projectId: number;
    name: string;
    language: string;
    confidence: number;
    type: number;
    createdDate: string;
    updatedDate: string;
    inputFileName: string;
    projLanguage: string;
    hasTable: boolean;
}

export enum DocumentStatus {
    Pending = 1,
    "In Progress",
    Completed,
    "Partially Completed",
    Failed,
    Terminated,
}

export const DEFAULT_DATE_FORMAT = "MM-DD-YYYY";

export type LanguageCodes = "en" | "fr" | "pt" | "es" | "de" | "ar" | "la";

export enum Language {
    en = "English",
    fr = "French",
    pt = "Portuguese",
    es = "Spanish",
    de = "German",
    la = "Latin",
}

export enum SortDirection {
    ASC = "asc",
    DESC = "desc",
}

export interface SortEntity {
    sort: string;
    sortDir: SortDirection | "";
}

export interface FilterEntity {
    [key: string]: number[];
}

export interface ColumnConfig {
    label: string;
    align?: string;
    dataKey: string;
    flexGrow?: boolean;
    CellRenderer?: any;
    HeaderRenderer?: any;
    width?: number;
}

export interface HeaderMetaData {
    label: string;
    dataKey: string;
    value: any;
    rowData: any;
}

export interface ProjectMetaData {
    uploadThreshold: number;
    uploadCount: number;
}
export interface Summary {
    status: number;
    count: number;
}
export interface ProjectSummary {
    summary: Summary[];
    langMismatchDocCount: number;
    lowConfidenceDocCount: number;
    docsWithErrors: number;
    projectMetaData: ProjectMetaData;
}

export interface ProjectState {
    language: string;
    projectName: string;
    outputFileName: string;
    inputFileName: string;
    projectId: number;
    totalExtractedFiles: number;
    documentId: number;
    hasTable: boolean;
    users: [];
    extractedData: ExtractedDocumentDetailEntity[];
    projectSummary: ProjectSummary;
    documentType?: string;
}

export enum ProjectStatus {
    Active = 1,
    Closed,
}

export enum ProcessType {
    OCR = 1,
    EntityExtraction = 2,
}

export enum UserType {
    Owner = 1,
    User,
    Admin,
    SuperAdmin,
}

export enum DocumentType {
    INVOICE = "invoice",
    CONTRACT = "contract",
    OTHERS = "others",
}

export interface RowEntity {
    chargeCode: string;
    comments: string;
    createdDate: string;
    description: string;
    documentType: string;
    edName: string;
    emName: string;
    id: number;
    language: string;
    name: string;
    practice: string;
    status: string;
    createdBy: string;
    users: Users[];
    uploadThreshold: number;
    allScanned: boolean;
    highlightKPILabels: boolean;
}

export interface TableEntity {
    rowData: RowEntity;
}
