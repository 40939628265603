import React, { useCallback } from "react";

import {
    Checkbox,
    Icon,
    Pagination,
    SIZE_SMALL,
    Table,
    Tooltip,
    TYPE_OUTLINE,
} from "@mds/mds-reactjs-library";

import emptyBox from "../../../assets/emptyBox.svg";
import ShouldRender from "../../../components/ShouldRender";
import formatBytes, { defaultDateFormat } from "../../../utils/format";
import {
    ButtonConfig,
    DocumentDetailEntity,
    DocumentStatus,
    FilterEntity,
    SortEntity,
} from "../../../utils/types";
import ActionItems from "../ActionItems";
import { DocumentListColumnConfig } from "../helper";
import styles from "./DocumentList.module.scss";

interface Props {
    documents: DocumentDetailEntity[];
    uploadBtnConfig: ButtonConfig;
    selectedPage: number;
    totalPages: number;
    limit: number;
    onChange: (pageNumber: number) => void;
    onSelectChange: (id: number | "all") => void;
    selectedDocs: number[];
    sortData: SortEntity;
    filterData: FilterEntity;
    onFilterChange: (field: string, value: string | number) => void;
    onSortClick: (label: string, direction: string) => void;
    searchQuery: string;
    clearFilter: () => void;
}

const DocumentList = ({
    documents,
    uploadBtnConfig,
    selectedPage,
    totalPages,
    limit,
    onChange,
    onSelectChange,
    selectedDocs,
    sortData,
    filterData,
    onFilterChange,
    onSortClick,
    searchQuery,
    clearFilter,
}: Props) => {
    const rows =
        documents?.map(
            (
                {
                    name,
                    createdDate,
                    size,
                    status,
                    id,
                    error,
                    elapsed,
                    metadata,
                    currentStep,
                },
                index: number,
            ) => ({
                id,
                serialNo: (selectedPage - 1) * limit + index + 1,
                name: (
                    <Tooltip content={name} dark={undefined} placement="top">
                        <div className={styles.tableField}>{name}</div>
                    </Tooltip>
                ),
                createdDate: defaultDateFormat(createdDate),
                size: formatBytes(size),
                extractionStatus: DocumentStatus[status],
                error,
                elapsed: elapsed ? `${elapsed} s` : "",
                metadata,
                status,
                currentStep,
            }),
        ) || [];

    const CheckboxCellRenderer = useCallback(
        (args: { rowData: { id: number } }) => {
            const { rowData } = args;

            const { id } = rowData;

            return (
                <Checkbox
                    value={id}
                    onChange={onSelectChange}
                    checked={selectedDocs.includes(id)}
                />
            );
        },
        [selectedDocs, onSelectChange],
    );

    const isAllChecked =
        selectedDocs.length &&
        rows.every((item) => selectedDocs.includes(item.id));

    const HeaderCheckboxCellRenderer = useCallback(
        () => (
            <Checkbox
                value="all"
                checked={isAllChecked}
                onChange={() => {
                    onSelectChange("all");
                }}
            />
        ),
        [selectedDocs, onSelectChange],
    );

    const showTable =
        !!searchQuery.length || !!filterData?.status?.length || !!rows.length;

    return (
        <ShouldRender
            condition={showTable}
            fallback={
                <div className={styles.noResult}>
                    <Icon size={12} type={TYPE_OUTLINE} src={emptyBox} />
                    <p className={styles.info}>No documents uploaded</p>
                    <ActionItems config={[uploadBtnConfig]} />
                </div>
            }
        >
            <>
                <Table
                    dark={false}
                    height="auto"
                    rowHeight={50}
                    columns={DocumentListColumnConfig(
                        sortData,
                        onSortClick,
                        filterData,
                        onFilterChange,
                        searchQuery,
                        HeaderCheckboxCellRenderer,
                        CheckboxCellRenderer,
                        clearFilter,
                    )}
                    rows={rows}
                />
                <ShouldRender condition={!rows.length}>
                    <div className={styles.noData}>No results found</div>
                </ShouldRender>
                <ShouldRender condition={totalPages > 1}>
                    <div className={styles.pagination}>
                        <Pagination
                            size={SIZE_SMALL}
                            selected={selectedPage}
                            total={totalPages}
                            onChange={onChange}
                        />
                    </div>
                </ShouldRender>
            </>
        </ShouldRender>
    );
};

export default DocumentList;
