import React, { useEffect, useState } from "react";

import Outline24CInfo from "@mds/mds-icons/icons/svg/outline-24-c-info.svg";
import {
    FormElementWrapper,
    SIZE_SMALL,
    SIZE_MEDIUM,
    Input,
    Button,
    PRIMARY_BUTTON,
    Grid,
    Tooltip,
    Toggle,
} from "@mds/mds-reactjs-library";

import ErrorMessage from "../../../components/ErrorMessage";
import ShouldRender from "../../../components/ShouldRender";
import useApi from "../../../utils/useApi";
import SearchResults from "../SearchResults";
import styles from "./Search.module.scss";

function Search({
    projectId,
    projectName,
}: {
    projectId: number;
    projectName: string;
}) {
    const [searchText, setSearchText] = useState("");
    const [selectedPage, setSelectedPage] = useState(1);
    const [advancedSearch, setAdvancedSearch] = useState(false);
    const [limit] = useState(10);
    const [response, callAPI] = useApi({
        url: `/api/project/${projectId}/documents/search/page/${selectedPage}/limit/${limit}?query=${searchText}&basic=${!advancedSearch}`,
        method: "get",
        data: null,
    });

    const { err, data } = response;

    useEffect(() => {
        if (searchText) callAPI();
    }, [selectedPage]);

    const onSearch = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (searchText) {
            setSelectedPage(1);
            callAPI();
        }
    };

    const onSearchTextChange = (evt: React.ChangeEvent<HTMLInputElement>) => (
        <Tooltip dark={undefined}>{setSearchText(evt.target.value)}</Tooltip>
    );

    const onPageChange = (page: number) => {
        setSelectedPage(page);
    };

    const toggleProps: any = {
        checked: advancedSearch,
        label: "Smart search",
        onChange: () => setAdvancedSearch(!advancedSearch),
    };

    return (
        <div className={styles.container}>
            <form onSubmit={onSearch}>
                <Grid container>
                    <Grid item span={3} className={styles.searchInput}>
                        <Tooltip
                            content={
                                <ul>
                                    <li className={styles.toolTip}>
                                        Regex should be in format
                                        /&lt;Regex&gt;/
                                    </li>
                                    <li className={styles.toolTip}>
                                        This feature doesn&apos;t validate regex
                                        syntax
                                    </li>
                                </ul>
                            }
                            trigger="mouseenter"
                            dark={undefined}
                            maxWidth={400}
                        >
                            <div>
                                <FormElementWrapper
                                    inputProps={{
                                        name: "Search",
                                        placeholder: advancedSearch
                                            ? "Enter word/phrase/regex"
                                            : "Enter word/phrase",
                                        onChange: onSearchTextChange,
                                        autoFocus: true,
                                    }}
                                    size={SIZE_MEDIUM}
                                    component={Input}
                                />
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item span={2}>
                        <Button
                            disabled={!searchText}
                            type="submit"
                            className={styles.searchButton}
                            size={SIZE_SMALL}
                            appearance={PRIMARY_BUTTON}
                        >
                            Search
                        </Button>
                    </Grid>
                    <Grid item span={10}>
                        <div className={styles.toggle}>
                            <Toggle {...toggleProps} />
                            <div>
                                <Tooltip
                                    content="To enable fuzzy matching use smart search"
                                    dark
                                >
                                    <img
                                        width={16}
                                        height={16}
                                        alt="info"
                                        src={Outline24CInfo}
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </Grid>
                    <Grid item span={12}>
                        <hr />
                    </Grid>
                    <Grid item span={12}>
                        <ShouldRender condition={!!err}>
                            <div className={styles.error}>
                                <ErrorMessage />
                            </div>
                        </ShouldRender>
                    </Grid>
                    <Grid item span={12}>
                        <ShouldRender condition={!!data}>
                            <SearchResults
                                advancedSearch={advancedSearch}
                                limit={limit}
                                page={selectedPage}
                                onChange={onPageChange}
                                results={data}
                                projectId={projectId}
                                projectName={projectName}
                                searchText={searchText}
                            />
                        </ShouldRender>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}

export default Search;
