import React from "react";

import ErrorMessage from "../ErrorMessage";
import styles from "./ShouldRender.module.scss";

interface Props {
    condition: boolean;
    children: JSX.Element;
    fallback?: JSX.Element | null;
    hasError?: boolean;
    errorMessage?: string;
}

const getChildren = (
    children: JSX.Element,
    hasError?: boolean,
    errorMessage?: string,
) => {
    if (hasError) {
        return (
            <div className={styles.error}>
                <ErrorMessage message={errorMessage} />
            </div>
        );
    }
    return children;
};

const ShouldRender: React.FC<Props> = ({
    condition,
    children,
    fallback,
    hasError,
    errorMessage,
}) =>
    condition
        ? getChildren(children, hasError, errorMessage)
        : (fallback as JSX.Element);

ShouldRender.defaultProps = {
    fallback: null,
    hasError: false,
    errorMessage: undefined,
};

export default ShouldRender;
