import React from "react";

import { CircularProgress, SIZE_EXTRA_LARGE } from "@mds/mds-reactjs-library";
import "./Loader.scss";

interface Props {
    progress?: number;
    showProgressInPercents?: boolean;
}
const Loader: React.FC<Props> = ({ progress, showProgressInPercents }) => (
    <>
        <div className="loader-cover" />
        <div className="loader">
            <CircularProgress
                progress={progress}
                indeterminate={!showProgressInPercents}
                showProgressInPercents={showProgressInPercents}
                size={SIZE_EXTRA_LARGE}
            />
        </div>
    </>
);

Loader.defaultProps = {
    progress: 100,
    showProgressInPercents: false,
};
export default Loader;
