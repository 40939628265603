import React from "react";

import utils from "./utils";

function escapeRegExp(text) {
    return text.replace(/[.*+?^${}()\\]/g, "\\$&");
}

export default function highlightPattern(text, patterns) {
    const values = patterns.map((pattern) => pattern.value);
    const tokens = values.join("|");
    const sanitizedString = escapeRegExp(tokens);
    const regex = new RegExp(`${sanitizedString}`, "gi");

    const splitText = text.split(regex);

    if (splitText.length <= 1) {
        return text;
    }

    const matches = text.match(regex) || [];

    const getEntityType = (value) => {
        const entity = patterns.find(
            (pattern) => pattern.value.toLowerCase() === value.toLowerCase(),
        );
        return entity?.key || "";
    };

    const getBackgroundColor = (value) => {
        const type = getEntityType(value);
        return utils.getColorFromString(type);
    };

    const res = splitText.reduce(
        (arr, element, index) =>
            matches[index]
                ? [
                      ...arr,
                      element,
                      <mark
                          key={arr}
                          style={{
                              whiteSpace: "pre",
                              color: "transparent",
                              backgroundColor: getBackgroundColor(
                                  matches[index],
                              ),
                          }}
                      >
                          {matches[index]}
                      </mark>,
                  ]
                : [...arr, element],
        [],
    );

    return res;
}
