import React from "react";

import { Icon, TYPE_GLYPH } from "@mds/mds-reactjs-library";

import styles from "./Chip.module.scss";

interface Props {
    label: string;
    onRemove: () => void;
}

const Chip = ({ label, onRemove }: Props) => (
    <div className={styles.container}>
        {label}
        <button type="button" className={styles.remove} onClick={onRemove}>
            <Icon size={12} type={TYPE_GLYPH} name="e-remove" />
        </button>
    </div>
);

export default Chip;
