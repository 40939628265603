import { HIDE_LOADER, SHOW_LOADER, SHOW_NOTIFICATION } from "./appConstants";

export interface NotificationEntity {
    message: string;
    closeBtn: boolean;
    type: string;
}
export interface ApplicationState {
    notifications: NotificationEntity[];
    showLoader: boolean;
}

export interface ApplicationAction {
    type: string;
    payload?: NotificationEntity[];
}

const initialState: ApplicationState = {
    notifications: [],
    showLoader: false,
};

const reducer = (state: ApplicationState, action: ApplicationAction) => {
    const { type, payload = [] } = action;
    switch (type) {
        case SHOW_NOTIFICATION:
            return {
                ...state,
                notifications: payload,
            };

        case SHOW_LOADER:
            return {
                ...state,
                showLoader: true,
            };

        case HIDE_LOADER:
            return {
                ...state,
                showLoader: false,
            };

        default:
            return state;
    }
};

export { initialState, reducer };
