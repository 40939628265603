import React from "react";

import {
    Button,
    ButtonAppearanceType,
    DropdownButton,
    DropdownItem,
    SECONDARY_BUTTON,
    Tooltip,
} from "@mds/mds-reactjs-library";

import ShouldRender from "../../../components/ShouldRender";
import { ButtonConfig, DropDownButtonObject } from "../../../utils/types";
import styles from "./ActionItems.module.scss";

interface Props {
    config: ButtonConfig[];
}
interface DropDownBtnProps {
    label: string;
    dropdownListItem?: DropDownButtonObject[];
    appearance?: string;
    isVisible?: boolean;
}

const RenderWithTooltip = (
    label: string,
    onClick?: () => void,
    appearance?: ButtonAppearanceType,
    tooltip?: string,
    disabled?: boolean,
) => (
    <Tooltip content={tooltip} dark={undefined} maxWidth={250}>
        <Button onClick={onClick} appearance={appearance} disabled={disabled}>
            {label}
        </Button>
    </Tooltip>
);

const RenderWithoutTooltip = (
    label: string,
    onClick?: () => void | undefined,
    appearance?: ButtonAppearanceType,
    disabled?: boolean,
) => (
    <Button onClick={onClick} appearance={appearance} disabled={disabled}>
        {label}
    </Button>
);

const RenderDropDownButtonList = ({
    label,
    dropdownListItem,
    appearance,
    isVisible,
}: DropDownBtnProps) => (
    <ShouldRender condition={!!isVisible}>
        <DropdownButton label={label} appearance={appearance}>
            {dropdownListItem?.map(({ dropdownLabel, onClick }) => (
                <DropdownItem onClick={() => onClick()}>
                    {dropdownLabel}
                </DropdownItem>
            ))}
        </DropdownButton>
    </ShouldRender>
);

RenderDropDownButtonList.defaultProps = {
    isVisible: true,
    appearance: SECONDARY_BUTTON,
    dropdownListItem: [{}],
};

const ActionItems: React.FC<Props> = ({ config }) => (
    <div className={styles.container}>
        {config.map(
            ({
                label,
                appearance,
                onClick,
                tooltip,
                disabled,
                dropdownListItem,
                type,
                isVisible,
            }) => (
                <div key={label} className={styles.btnWrapper}>
                    <ShouldRender condition={!!tooltip && !type}>
                        {RenderWithTooltip(
                            label,
                            onClick,
                            appearance,
                            tooltip,
                            disabled,
                        )}
                    </ShouldRender>

                    <ShouldRender condition={!tooltip && !type}>
                        {RenderWithoutTooltip(
                            label,
                            onClick,
                            appearance,
                            disabled,
                        )}
                    </ShouldRender>

                    <ShouldRender condition={!tooltip && !!type}>
                        {RenderDropDownButtonList({
                            label,
                            dropdownListItem,
                            appearance,
                            isVisible,
                        })}
                    </ShouldRender>
                </div>
            ),
        )}
    </div>
);

export default ActionItems;
