import React, { useEffect, useRef, useState } from "react";

import { Tooltip } from "@mds/mds-reactjs-library";

import ShouldRender from "../ShouldRender";
import styles from "./CellTextRenderer.module.scss";

export interface CellTextRendererProps {
    cellData: string;
    value: string;
}

const CellTextRenderer: React.FC<CellTextRendererProps> = ({
    cellData,
    value,
}) => {
    const text = cellData || value;
    const ref = useRef<HTMLDivElement | null>(null);
    const [isOverflown, setIsOverflown] = useState(false);
    useEffect(() => {
        const element = ref.current;
        if (element) {
            setIsOverflown(element?.scrollWidth > element?.clientWidth);
        }
    }, []);

    return (
        <div className={styles.container}>
            <ShouldRender
                condition={isOverflown}
                fallback={
                    <div ref={ref} className={styles.content}>
                        {text}
                    </div>
                }
            >
                <Tooltip content={text} dark={undefined}>
                    <div ref={ref} className={styles.content}>
                        {text}
                    </div>
                </Tooltip>
            </ShouldRender>
        </div>
    );
};

export default CellTextRenderer;
