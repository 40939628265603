import React, { useContext } from "react";

import {
    Button,
    ERROR_ALERT_CONTEXTUAL,
    Grid,
    Icon,
    Pagination,
    PRIMARY_BUTTON,
    SIZE_SMALL,
    Table,
    TYPE_OUTLINE,
} from "@mds/mds-reactjs-library";
import { useNavigate } from "react-router-dom";

import { ApplicationContext } from "../../../App";
import Download from "../../../assets/Download.svg";
import CellTextRenderer, {
    CellTextRendererProps,
} from "../../../components/CellTextRenderer";
import ShouldRender from "../../../components/ShouldRender";
import { HIDE_LOADER, SHOW_LOADER } from "../../../data/appConstants";
import callDownloadAPI, { downloadAttachment } from "../../../utils/fileUtils";
import styles from "./SearchResults.module.scss";

interface ContentProps {
    value: string;
}

interface SearchResult {
    name: string;
    highlights: string;
    pages: string;
}

interface SearchProps {
    advancedSearch: boolean;
    page: number;
    limit: number;
    results: {
        data: SearchResult[];
        metadata: {
            totalPages: number;
            totalRecords: number;
        };
    };
    projectId: number;
    projectName: string;
    searchText: string;
    onChange: (page: number) => void;
}

const HTMLRenderer = ({ value }: ContentProps) => (
    <div className={styles.htmlContent}>
        <span dangerouslySetInnerHTML={{ __html: value }} />
        <span> ...</span>
    </div>
);

const SearchInfo = ({
    totalRecords,
    projectId,
    projectName,
    searchText,
    advancedSearch,
}: {
    totalRecords: number;
    projectId: number;
    projectName: string;
    searchText: string;
    advancedSearch: boolean;
}) => {
    const { dispatch } = useContext(ApplicationContext);

    const downloadSearchResults = async () => {
        try {
            dispatch({ type: SHOW_LOADER });
            const res = await callDownloadAPI(
                `/api/project/${projectId}/documents/search/download?query=${searchText}&basic=${!advancedSearch}`,
            );
            const filename = `${new Date()
                .toISOString()
                .slice(0, 10)}_${projectName}_SearchResults.csv`;
            downloadAttachment(res, filename);
            dispatch({ type: HIDE_LOADER });
        } catch (err) {
            dispatch({ type: HIDE_LOADER });
            dispatch({
                type: "SHOW_NOTIFICATION",
                payload: [
                    {
                        message: "Something went wrong, please try again.",
                        type: ERROR_ALERT_CONTEXTUAL,
                    },
                ],
            });
        }
    };

    return (
        <Grid container>
            <Grid item span={4} className={styles.info}>
                <span className={styles.caption}>Results</span>
                <span className={styles.resultCount}>
                    ( {totalRecords} Document(s) Found )
                </span>
            </Grid>

            <ShouldRender condition={!!totalRecords}>
                <Grid item span={1} offset={6} className="search-download">
                    <Button
                        onClick={downloadSearchResults}
                        size={SIZE_SMALL}
                        appearance={PRIMARY_BUTTON}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_OUTLINE}
                                src={Download}
                            />
                        }
                    >
                        Report
                    </Button>
                </Grid>
            </ShouldRender>
        </Grid>
    );
};

export const renderCell = (data: CellTextRendererProps) => (
    <CellTextRenderer {...data} />
);

function SearchResults({
    results,
    page,
    limit,
    projectId,
    projectName,
    searchText,
    advancedSearch,
    onChange,
}: SearchProps) {
    const rows =
        results?.data?.map((item: SearchResult, index: number) => {
            const { name: documentName, highlights, pages } = item;
            return {
                serialNo: (page - 1) * limit + index + 1,
                documentName,
                highlights,
                pages,
            };
        }) || [];

    const columns = [
        {
            dataKey: "serialNo",
            label: "S. no",
            flexGrow: true,
            width: 75,
        },
        {
            dataKey: "documentName",
            label: "Document name",
            flexGrow: true,
            width: 400,
            cellRenderer: renderCell,
        },
        {
            dataKey: "pages",
            label: "Page no.",
            flexGrow: true,
            width: 400,
            cellRenderer: renderCell,
        },
        {
            dataKey: "highlights",
            textWrapping: true,
            label: "Contents",
            width: 800,
            flexGrow: true,
            CellRenderer: HTMLRenderer,
        },
    ];

    const navigate = useNavigate();

    const onRowSelect = (data: any) => {
        navigate("search/detail", {
            state: {
                ...data.rowData,
                projectId,
                projectName,
            },
        });
    };

    return (
        <>
            <ShouldRender condition={!!results?.data}>
                <SearchInfo
                    advancedSearch={advancedSearch}
                    projectId={projectId}
                    projectName={projectName}
                    totalRecords={results?.metadata?.totalRecords}
                    searchText={searchText}
                />
            </ShouldRender>
            <ShouldRender condition={results?.metadata?.totalRecords > 0}>
                <div className={styles.container}>
                    <Table
                        dark={false}
                        striped
                        rowHeight={45}
                        rows={rows}
                        columns={columns}
                        height="auto"
                        onRowClick={onRowSelect}
                    />
                </div>
            </ShouldRender>
            <ShouldRender condition={results?.metadata?.totalPages > 1}>
                <div className={styles.pagination}>
                    <Pagination
                        size={SIZE_SMALL}
                        selected={page}
                        total={results?.metadata?.totalPages}
                        onChange={onChange}
                    />
                </div>
            </ShouldRender>
        </>
    );
}

export default SearchResults;
