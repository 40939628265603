import { UserType } from "./types";

enum UserRole {
    User = "user",
    Owner = "owner",
    Admin = "admin",
    SuperAdmin = "super-admin",
}

const isSuperAdmin = (): boolean => {
    const roles = JSON.parse(sessionStorage.getItem("roles") || "[]");
    return (
        roles
            .map((role: string) => role.toLowerCase())
            .indexOf(UserRole.SuperAdmin) > -1
    );
};

const isAdmin = (project: any): boolean => {
    const email = sessionStorage.getItem("email") || "";
    return !!project.users?.find(
        (user: any) =>
            user.assignedTo === email && user.type === UserType.Admin,
    );
};

const isOwner = (project: any): boolean => {
    const email = sessionStorage.getItem("email") || "";
    return !!project.users.find(
        (user: any) =>
            user.assignedTo === email && user.type === UserType.Owner,
    );
};

export default { isSuperAdmin, isAdmin, isOwner };
