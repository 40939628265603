import moment from "moment";

import { DEFAULT_DATE_FORMAT } from "./types";

export interface Cell {
    text: string;
}
export interface Column {
    type: string;
    children: Cell[];
}
export interface Row {
    type: string;
    children: Column[];
}

export interface Table {
    type: string;
    children: Row[];
}

export interface ExcelJson {
    name: string;
    data: string[][];
}

export default function formatBytes(bytes: number, decimals = 2) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Bytes";
    const ind = Math.floor(Math.log(bytes) / Math.log(1024));
    if (ind === 0) return `${bytes} ${sizes[ind]}`;
    return `${(bytes / 1024 ** ind).toFixed(decimals)} ${sizes[ind]}`;
}

export const defaultDateFormat = (date: string) =>
    moment(date).format(DEFAULT_DATE_FORMAT);

export const isEmail = (val: string) =>
    /[\w\d\\.-]+@[\w\d\\.-]+\.[\w\d\\.-]+/.test(val);

export const isValid = (val: string, list: string[]) => {
    let error = "";
    if (!isEmail(val)) {
        error = `${val} is not a valid email address.`;
    }
    if (list.includes(val)) {
        error = `${val} has already been added.`;
    }

    return error;
};
export const parseJwt = (token: string | null) => {
    try {
        const base64Url = token ? token.split(".")[1] : "";
        const base64 =
            base64Url && base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload =
            base64 &&
            decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(
                        (c) =>
                            `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`,
                    )
                    .join(""),
            );
        if (jsonPayload) {
            return JSON.parse(jsonPayload);
        }
        return "";
    } catch (e) {
        console.error(`could not parse jwt: ${e}`);
        return undefined;
    }
};

const transformTable = (data: string[][]): Row[] =>
    data.reduce((acc, row) => {
        const rowData = [];
        for (let i = 0; i < row?.length; i += 1) {
            rowData.push({
                type: "table-cell",
                children: [{ text: row[i] || " " }],
            });
        }

        const children = rowData?.length
            ? rowData
            : [{ type: "table-cell", children: [{ text: " " }] }];
        return [
            ...acc,
            {
                type: "table-row",
                children,
            },
        ];
    }, [] as Row[]);

export const JsonToSlate = (
    json: { name: string; data: string[][] }[],
): Table[] =>
    json.reduce(
        (acc, { data }) => [
            ...acc,
            {
                type: "table",
                children: transformTable(data),
            },
        ],
        [] as Table[],
    );
