import React from "react";

import { ThemeProvider } from "@mds/mds-reactjs-library";
import { AuthFlow, Mid } from "@mid/sdk";
import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { OIDC } from "./appConfig";
import ErrorBoundary from "./components/ErrorBoundary";
import Loader from "./components/Loader";
import reportWebVitals from "./reportWebVitals";
import { parseJwt } from "./utils/format";

const customModalTheme = {
    fonts: {
        primaryLight: "McKinsey Sans",
        primaryMedium: "McKinsey Sans",
        primaryRegular: "McKinsey Sans",
        display: "McKinsey Sans",
        mono: "McKinsey Sans",
    },
    avatar: {
        size: {
            sm: "2.75rem",
        },
    },
    modal: {
        coverBackground: "transparent",
        content: {
            margin: 0,
        },
    },
    table: {
        width: "100%",
        lightHeaderBgColor: "#F0F0F0",
        evenRowBgColor: "#FAFAFA",
        rowBorderBottom: "#DEDEDE",
        evenRowBorderBottom: "#DEDEDE",
        lightHeaderTextColor: "#000",
    },
    button: {
        border: {
            tertiary: "transparent",
        },
        size: {
            height: {
                md: "2.25rem",
            },
        },
    },
    pagination: {
        regular: {
            selectedBackgroundColor: "#2251FF",
        },
    },
};

const midConfig = ({
    fm,
    flow,
}: {
    path?: string;
    fm?: string;
    flow?: AuthFlow;
}) => ({
    redirectUrl: `${OIDC.baseUrl}/auth/callback`,
    logoutRedirectUrl: OIDC.logoutUrl,
    loginInfo: {
        appId: OIDC.clientId,
        oidcConfigUrl: OIDC.oidcUrl,
        authDriver: "mid",
        fm,
        flow,
    },
    landingFn: async () => {
        window.location.href = "/";
    },
});

const mid = new Mid(
    midConfig({
        flow: "auth_code",
    }),
);

async function authenticate(): Promise<null> {
    return new Promise((resolve, reject) => {
        try {
            mid.isAuthed().then(async (isAuthed) => {
                if (!isAuthed) {
                    try {
                        await mid.login();
                        resolve(null);
                    } catch (err) {
                        // log in failed
                        reject(err);
                    }
                } else {
                    // already logged in
                    resolve(null);
                }
            });
        } catch (err) {
            reject(err);
        }
    });
}

async function loadApplication() {
    if (window.location.pathname.includes("logout")) {
        await mid.logout();
    } else {
        ReactDOM.render(<Loader />, document.getElementById("root"));
        try {
            await authenticate();
            const {
                name,
                email,
                "mid-groups": roles,
            } = await parseJwt(mid.accessToken());
            sessionStorage.setItem("name", name);
            sessionStorage.setItem("email", email.toLowerCase());
            sessionStorage.setItem("roles", JSON.stringify(roles));
        } catch (err) {
            console.log(err);
            throw err;
        }

        ReactDOM.render(
            <BrowserRouter>
                <ErrorBoundary>
                    <ThemeProvider customTheme={customModalTheme}>
                        <App />
                    </ThemeProvider>
                </ErrorBoundary>
            </BrowserRouter>,
            document.getElementById("root"),
        );
    }
}
window.onload = loadApplication;
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
