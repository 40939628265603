import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";

import About from "./pages/About/About";
import Analysis from "./pages/Analysis";
import NotFound from "./pages/NotFound";
import Project from "./pages/Project";
import ExtractedFile from "./pages/Project/ExtractedDocumentList/ExtractedFile";
import Projects from "./pages/Projects";

const AppRoutes = () => (
    <Routes>
        <Route index element={<Navigate to="/projects" />} />
        <Route path="projects" element={<Projects />} />
        <Route path="projects/:id/documents/*" element={<Project />} />
        <Route
            path="projects/:id/documents/processed/:name"
            element={<ExtractedFile />}
        />
        <Route path="projects/:id/analysis/*" element={<Analysis />} />
        <Route path="/about" element={<About />} />
        <Route path="auth/*" element={<Navigate to="/projects" />} />
        <Route path="*" element={<NotFound />} />
    </Routes>
);

export default AppRoutes;
