import React, { FC } from "react";

import { Table, Grid } from "@mds/mds-reactjs-library";

import ShouldRender from "../../../../components/ShouldRender";
import { renderCell } from "../../SearchResults";
import { QnAResponse } from "../type";
import styles from "./QADetails.module.scss";

const columns = [
    {
        dataKey: "serialNo",
        label: "S. no",
        flexGrow: true,
        width: 75,
    },
    {
        dataKey: "excerpt",
        label: "Excerpt",
        flexGrow: true,
        width: 400,
        cellRenderer: renderCell,
    },

    {
        dataKey: "name",
        label: "Document Name",
        flexGrow: true,
        width: 200,
    },
];

interface Props {
    data: QnAResponse;
    error: any;
}
const QADetails: FC<Props> = ({ data, error }) => {
    const row =
        data?.answers?.map(({ answer, meta }, ind: number) => ({
            serialNo: ind + 1,
            excerpt: answer,
            name: meta.name,
        })) || [];
    return (
        <ShouldRender condition={!!data || !!error} hasError={!!error}>
            <div className={styles.container}>
                <Grid item span={4} className={styles.info}>
                    {`Results  (${
                        data?.answers?.length || 0
                    } Document(s) Found)`}
                </Grid>
                <Table
                    dark={false}
                    striped
                    rowHeight={40}
                    rows={row}
                    columns={columns}
                    height="auto"
                />
            </div>
        </ShouldRender>
    );
};

export default QADetails;
