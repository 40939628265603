const ALLOWED_FILE_TYPE = ".pdf,.jpeg,.jpg,.png";
const MAX_FILE_SIZE = 209715200; // 200MB
const MAX_FILE_LIMIT = 10000;
const THRESHOLD = 50.0;
const ALLOWED_EXTRACTION_FILE_TYPE = ".zip";
const MAX_EXTRACT_MODEL_FILE_LIMIT = 1;

const OIDC = {
    clientId:
        window?.dociq?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID,
    baseUrl:
        window?.dociq?.REACT_APP_REDIRECT_BASE_URL ||
        process.env.REACT_APP_REDIRECT_BASE_URL,
    oidcUrl:
        window?.dociq?.REACT_APP_OIDC_CONFIG_URL ||
        process.env.REACT_APP_OIDC_CONFIG_URL,
    logoutUrl:
        window?.dociq?.REACT_APP_LOGOUT_URL || process.env.REACT_APP_LOGOUT_URL,
};

export const defaultEntities = [
    ["", "0", "0", "ORG"],
    ["", "0", "0", "CARDINALS"],
    ["", "0", "0", "DATE"],
    ["", "0", "0", "EVENT"],
    ["", "0", "0", "FAC"],
    ["", "0", "0", "GPE"],
    ["", "0", "0", "LANGUAGE"],
    ["", "0", "0", "LAW"],
    ["", "0", "0", "LOC"],
    ["", "0", "0", "MONEY"],
    ["", "0", "0", "NORP"],
    ["", "0", "0", "ORDINAL"],
    ["", "0", "0", "PERCENT"],
    ["", "0", "0", "PERSON"],
    ["", "0", "0", "PRODUCT"],
    ["", "0", "0", "QUANTITY"],
    ["", "0", "0", "TIME"],
    ["", "0", "0", "WORK_OF_ART"],
];

export {
    ALLOWED_FILE_TYPE,
    MAX_FILE_SIZE,
    MAX_FILE_LIMIT,
    THRESHOLD,
    OIDC,
    ALLOWED_EXTRACTION_FILE_TYPE,
    MAX_EXTRACT_MODEL_FILE_LIMIT,
};
