import React from "react";

import { PDFRenderer } from "../../pages/Project/ExtractedDocumentList/ExtractedFile";
import styles from "./Editor.module.scss";

interface editorProps {
    type: string;
    pdf: any;
    outputFile: any;
}

const SlateEditor = ({
    outputFile,
    type: pdfType,
    pdf: pdfData,
}: editorProps) => (
    <>
        <div className={styles.labels}>
            <div className={styles.original}>Original File </div>
            <div className={styles.extracted}> Extracted Text </div>
        </div>
        <div className={styles.docContainer}>
            <PDFRenderer type={pdfType} pdf={pdfData} />
            <PDFRenderer type="application/pdf" pdf={outputFile} />
        </div>
    </>
);

export default SlateEditor;
