import React, { useEffect, useMemo, useState } from "react";

import styled from "@emotion/styled";
import Glyph16ZoomIn from "@mds/mds-icons/icons/svg/glyph-16-zoom-in.svg";
import Glyph16ZoomOut from "@mds/mds-icons/icons/svg/glyph-16-zoom-out.svg";
import Outline24CAdd from "@mds/mds-icons/icons/svg/outline-24-c-add.svg";
import Outline24CDelete from "@mds/mds-icons/icons/svg/outline-24-c-delete.svg";
import {
    Button,
    Icon,
    SIZE_SMALL,
    TERTIARY_BUTTON,
    TYPE_GLYPH,
    TYPE_OUTLINE,
} from "@mds/mds-reactjs-library";
import classNames from "classnames";
import DOMPurify from "dompurify";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import PDFViewer from "../../../components/PDFViewer";
import ShouldRender from "../../../components/ShouldRender";
import { removeExtension } from "../../../utils/fileUtils";
import textHighlighter from "../../../utils/textHighlighter";
import useApi from "../../../utils/useApi";
import utils from "../../../utils/utils";
import { EntityRenderer, groupData } from "../Entities";
import styles from "./DocumentAnalysis.module.scss";

const ButtonStyled = styled(Button)`
    margin: 10px;
    width: 32px;
    height: 32px;
    min-height: 32px !important;
    min-width: 32px !important;
`;

const getOutputDocumentName = (documentName: string) =>
    `${removeExtension(documentName)}.pdf`;

const labelWithCount = (label: string, count: number) =>
    `${label} - ${count} Entities`;
const DocumentAnalysis = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { id: projectId, documentId } = params;
    const location = useLocation();
    const { state } = location;
    const { documentName } = state as { documentName: string };
    const [page, setPage] = useState(1);
    const [limit] = useState(1);
    const outputDocumentName = getOutputDocumentName(documentName);
    const [entityData, callToApi] = useApi({
        url: `/api/project/${projectId}/documents/entities/${documentId}/page/${page}/limit/${limit}`,
        method: "get",
    });

    const [outputFile, fetchOutputFile] = useApi({
        url: `/api/project/${projectId}/files/download/type/rendered_files/${outputDocumentName}`,
        method: "get",
        data: null,
        responseType: "blob",
    });

    const [outputPDF, setOutputPDF] = useState({});

    const [expand, setExpand] = useState(true);
    const [scale, setScale] = useState(1.0);

    const text = useMemo(() => {
        const item = entityData.data?.data[0] || {};
        let final = "";
        const groupedData = groupData(item.entities || []);
        const keys = Object.keys(groupedData);
        const backgroundColor: { [key: string]: string } = keys.sort().reduce(
            (acc, entity) => ({
                ...acc,
                [entity]: utils.getColorFromString(entity),
            }),
            {},
        );
        if (item.text as string) {
            let i = 0;
            let startIndx = 0;
            const str = item.text;
            while (item.entities && i < item.entities?.length) {
                const [word, start, end, ent] = item.entities[i];
                const prev = str.slice(startIndx, start);
                const highlighted = str.slice(start, end + 1);

                const bgColor = backgroundColor[ent];
                final = `${
                    final + prev
                }<span style='background-color: ${bgColor};line-height:1.5; padding: 0 8px; margin:0 5px; border-radius: 8px; opacity: 0.8;'>${highlighted}</span>`;

                startIndx = end + 1;
                i += 1;
            }
            if (i < str.length) {
                final += str.slice(startIndx);
            }
        }

        return final.replace(/\\n/g, "<br> ");
    }, [entityData]);

    useEffect(() => {
        fetchOutputFile();
    }, []);

    useEffect(() => {
        callToApi();
    }, [page, limit]);

    useEffect(() => {
        if (outputFile.data) {
            (async () => {
                const fileData = await outputFile.data.arrayBuffer();
                setOutputPDF(fileData);
            })();
        }
    }, [outputFile]);

    const next = () => {
        setPage(page + 1);
    };

    const prev = () => {
        setPage(page - 1);
    };

    const onZoom = () => {
        if (scale < 4) setScale(scale + 0.25);
    };

    const onZoomout = () => {
        if (scale > 0.25) setScale(scale - 0.25);
    };

    return (
        <ShouldRender condition={!!entityData.data}>
            <div className={styles.container}>
                <div
                    className={styles.backToSummary}
                    onClick={() => navigate(-1)}
                >
                    <Icon
                        fill="#2251FF"
                        size={12}
                        type={TYPE_GLYPH}
                        name="arrow-left"
                    />
                    <span>Back to Summary</span>
                </div>
                <ShouldRender condition={!!entityData.data?.data[0].entities}>
                    <div className={styles.entities}>
                        <div className={styles.entitySet}>
                            <div
                                data-testid="expand"
                                className={styles.expand}
                                onClick={() => {
                                    setExpand(!expand);
                                }}
                            >
                                <Icon
                                    type={TYPE_OUTLINE}
                                    src={
                                        expand
                                            ? Outline24CDelete
                                            : Outline24CAdd
                                    }
                                />
                            </div>
                            <div
                                className={classNames({
                                    [styles.hideEntities]: !expand,
                                })}
                            >
                                <EntityRenderer
                                    value={
                                        entityData.data?.data[0].entities || []
                                    }
                                    showAll
                                    showColors
                                />
                            </div>
                        </div>
                    </div>
                </ShouldRender>
                <div className={styles.documentName}>
                    {labelWithCount(
                        documentName,
                        entityData.data?.data[0].entities?.length,
                    )}
                </div>
                <div className={`pagination ${styles.documentTools}`}>
                    <ButtonStyled
                        size={SIZE_SMALL}
                        disabled={page === 1}
                        onClick={prev}
                        appearance={TERTIARY_BUTTON}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                name="left-arrow"
                            />
                        }
                    />

                    <b style={{ marginTop: "15px", marginLeft: "5px" }}>
                        {page} of {entityData?.data?.metadata?.totalPages}
                    </b>

                    <ButtonStyled
                        size={SIZE_SMALL}
                        disabled={
                            page === entityData?.data?.metadata?.totalPages
                        }
                        onClick={next}
                        appearance={TERTIARY_BUTTON}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                name="right-arrow"
                            />
                        }
                    />

                    <ButtonStyled
                        className="btn"
                        size={SIZE_SMALL}
                        appearance={TERTIARY_BUTTON}
                        onClick={onZoomout}
                        disabled={scale === 0.25}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                name="search-zoom-out"
                                src={Glyph16ZoomOut}
                            />
                        }
                    />

                    <b style={{ marginTop: "15px", marginLeft: "5px" }}>
                        {scale * 100} %
                    </b>

                    <ButtonStyled
                        size={SIZE_SMALL}
                        appearance={TERTIARY_BUTTON}
                        onClick={onZoom}
                        disabled={scale === 4}
                        startIcon={
                            <Icon
                                size={16}
                                type={TYPE_GLYPH}
                                src={Glyph16ZoomIn}
                            />
                        }
                    />
                </div>
                <hr />
                <ShouldRender condition={!!outputPDF}>
                    <PDFViewer
                        page={page}
                        scale={scale}
                        src={outputPDF}
                        customRenderer={textHighlighter}
                        entities={entityData.data?.data[0]?.entities}
                    />
                </ShouldRender>
            </div>
        </ShouldRender>
    );
};

export default DocumentAnalysis;
