import React, { useCallback } from "react";

import Outline16Preview from "@mds/mds-icons/icons/svg/outline-16-preview.svg";
import {
    Icon,
    Table,
    Pagination,
    SIZE_SMALL,
    TYPE_OUTLINE,
    Tooltip,
    Checkbox,
} from "@mds/mds-reactjs-library";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";

import { THRESHOLD } from "../../../appConfig";
import BlankIcon from "../../../assets/Empty.svg";
import LanguageError from "../../../assets/LanguageError.svg";
import TableIcon from "../../../assets/Table.svg";
import ShouldRender from "../../../components/ShouldRender";
import {
    ExtractedDocumentDetailEntity,
    FilterEntity,
    SortEntity,
} from "../../../utils/types";
import { ExtractedDocumentListColumnConfig } from "../helper";
import styles from "./ExtractedDocumentList.module.scss";

interface Props {
    documents: ExtractedDocumentDetailEntity[];
    selectedPage: number;
    totalPages: number;
    limit: number;
    onChange: (page: number) => void;
    sortData: SortEntity;
    filterData: FilterEntity;
    onFilterChange: (field: string, value: string | number) => void;
    onSortClick: (label: string, direction: string) => void;
    searchQuery: string;
    onSelectChange: (id: number | "all") => void;
    selectedDocs: number[];
    clearFilter: () => void;
}

const BlankIconElem = () => (
    <Icon type={TYPE_OUTLINE} size={8} src={BlankIcon} />
);

export const ErrorMessageRenderer = (message: string, icon: string) => (
    <Tooltip content={message} dark={undefined}>
        <div>
            <Icon size={8} type={TYPE_OUTLINE} src={icon} />
        </div>
    </Tooltip>
);

const ExtractedDocumentList = ({
    documents,
    selectedPage,
    totalPages,
    limit,
    onChange,
    sortData,
    onSortClick,
    filterData,
    onFilterChange,
    searchQuery,
    onSelectChange,
    selectedDocs,
    clearFilter,
}: Props) => {
    const navigate = useNavigate();
    const { state } = useLocation();

    const { documentType } = state;

    const rows = documents.map(
        (
            {
                inputFileName,
                name,
                confidence,
                language,
                projLanguage,
                hasTable,
                documentId,
            },
            index: number,
        ) => ({
            documentId,
            serialNo: (selectedPage - 1) * limit + index + 1,
            name: (
                <Tooltip
                    content={inputFileName}
                    dark={undefined}
                    placement="top"
                >
                    <div className={styles.tableField}>{inputFileName}</div>
                </Tooltip>
            ),
            confidence: (
                <div
                    className={classNames({
                        [styles.confidence]: confidence < THRESHOLD,
                    })}
                >
                    {confidence}%
                </div>
            ),
            extractedData: (
                <>
                    <Tooltip
                        content="This is an indication that table exists"
                        dark={undefined}
                    >
                        <div
                            className={classNames(styles.tableIcon, {
                                [styles.hideTableIcon]: !hasTable,
                            })}
                        >
                            <ShouldRender
                                condition={hasTable}
                                fallback={<BlankIconElem />}
                            >
                                <Icon
                                    size={8}
                                    type={TYPE_OUTLINE}
                                    src={TableIcon}
                                />
                            </ShouldRender>
                        </div>
                    </Tooltip>

                    <div
                        className={styles.icon}
                        onClick={() => {
                            navigate(`${name}`, {
                                state: {
                                    ...(state as object),
                                    outputFileName: name,
                                    inputFileName,
                                    documentId,
                                    hasTable,
                                },
                            });
                        }}
                    >
                        <Icon
                            size={8}
                            type={TYPE_OUTLINE}
                            src={Outline16Preview}
                        />
                    </div>
                </>
            ),
            errors: (
                <ShouldRender
                    condition={
                        language !== projLanguage || confidence < THRESHOLD
                    }
                >
                    {ErrorMessageRenderer(
                        language !== projLanguage
                            ? "Language mismatch"
                            : "Low Confidence",
                        LanguageError,
                    )}
                </ShouldRender>
            ),
        }),
    );

    const CheckboxCellRenderer = useCallback(
        (args: { rowData: { documentId: number } }) => {
            const { rowData } = args;

            const { documentId } = rowData;

            return (
                <Tooltip
                    dark={undefined}
                    content="Select to Delete"
                    maxWidth={250}
                    placement="right"
                >
                    <span>
                        <Checkbox
                            value={documentId}
                            onChange={onSelectChange}
                            checked={selectedDocs.includes(documentId)}
                        />
                    </span>
                </Tooltip>
            );
        },
        [selectedDocs, onSelectChange],
    );

    const isAllChecked =
        selectedDocs.length &&
        documents.every((item) => selectedDocs.includes(item.documentId));

    const HeaderCheckboxCellRenderer = useCallback(
        () => (
            <Tooltip
                dark={undefined}
                content="Select all to Delete"
                maxWidth={250}
                placement="right"
            >
                <span>
                    <Checkbox
                        value="all"
                        checked={isAllChecked}
                        onChange={() => {
                            onSelectChange("all");
                        }}
                    />
                </span>
            </Tooltip>
        ),
        [selectedDocs, onSelectChange],
    );
    return (
        <>
            <Table
                dark={false}
                height="auto"
                rowHeight={40}
                columns={ExtractedDocumentListColumnConfig(
                    sortData,
                    onSortClick,
                    filterData,
                    onFilterChange,
                    searchQuery,
                    HeaderCheckboxCellRenderer,
                    CheckboxCellRenderer,
                    clearFilter,
                    documentType,
                )}
                rows={rows}
            />
            <ShouldRender condition={!documents.length}>
                <div className={styles.noResults}>No results found</div>
            </ShouldRender>
            <ShouldRender condition={!!totalPages && totalPages > 1}>
                <div className={styles.pagination}>
                    <Pagination
                        size={SIZE_SMALL}
                        selected={selectedPage}
                        total={totalPages}
                        onChange={onChange}
                    />
                </div>
            </ShouldRender>
        </>
    );
};

export default ExtractedDocumentList;
